const mockupState = {
  loading: true,
  clienti: [],
  settori: [],
  tecnici: [],
  richieste: [],
  data: [],
  stato: '',
  urgenza: '',
  show: 'hidden',
  infoValue: {},
  showInfoBox: false,
  content: []
}

const mockupEdit = {
  loading: true,
  clienti: [],
  settori: [],
  tecnici: [],
  installazioni: [],
  stato: '',
  titolo: '',
  urgenza: '',
  cliente: '',
  settore: '',
  tecnico: '',
  descrizione: '',
  installazione: '',
  _id: ''
}

const mockupResoconti = {
  loading: true,
  _id: '',
  resoconti: [],
  tecnici: [],
  clienti: [],
  utenti: [],
  richieste: []
}

const columnRichieste = [
  { id: 'cliente', label: 'Cliente' },
  { id: 'settore', label: 'Settore' },
  { id: 'tecnico', label: 'Tecnico' },
  { id: 'urgenza', label: 'Urgenza' },
  { id: 'dataOra', label: 'Apertura' },
  { id: 'chiusuraDataOra', label: 'Chiusura' },
  { id: 'stato', label: 'Stato' },
  { id: 'modifica', label: 'Modifica' },
  { id: 'info', label: 'Info' },
  { id: 'resoconti', label: 'Resoconti' },
  { id: 'esporta', label: 'Esporta' }
]

const columnResoconti = [
  { id: 'tecnico', label: 'Tecnico' },
  { id: 'dataInizio', label: 'Inizio' },
  { id: 'dataFine', label: 'Fine' },
  { id: 'descrizione', label: 'Descrizione' },
  { id: 'modifica', label: 'Modifica' }
]

export { mockupState, mockupEdit, columnRichieste, mockupResoconti, columnResoconti }
