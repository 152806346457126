import swal from 'sweetalert'
import agree from '../../Assets/swallerIcons/agree.svg'
import error from '../../Assets/swallerIcons/error.svg'
import question from '../../Assets/swallerIcons/question.svg'
import info from '../../Assets/swallerIcons/info.svg'

/**
 * @author Tecnel MC
 * @name swaller
 * @description API for swallers
 * @example
 * swaller.type('text to display to the user')
 * return true or false => answer of the user
 */

const swaller = {}

swaller.question = async (text) => {
  try {
    const response = await swal({
      // title: 'Sei Sicuro?',
      // text,
      title: text,
      content: {
        element: 'img',
        attributes: {
          src: question,
          style: 'width: 300px'
        }
      },
      buttons: true,
      dangerMode: true
    })
    return response
  } catch (err) {}
}

swaller.error = (text) => {
  try {
    swal({
      // title: 'Errore',
      // text,
      title: text,
      content: {
        element: 'img',
        attributes: {
          src: error,
          style: 'width: 300px'
        }
      },
      timer: 2000,
      buttons: false,
      closeOnEsc: false,
      closeOnClickOutside: false
    })
  } catch (err) {}
}

swaller.info = async (text) => {
  try {
    swal({
      // title: 'Informazioni',
      // text,
      title: text,
      content: {
        element: 'img',
        attributes: {
          src: info,
          style: 'width: 300px'
        }
      },
      timer: 2000,
      buttons: false,
      closeOnEsc: false,
      closeOnClickOutside: false
    })
  } catch (err) {}
}

swaller.success = (text) => {
  try {
    swal({
      // title: 'Success',
      // text,
      title: text,
      content: {
        element: 'img',
        attributes: {
          src: agree,
          style: 'width: 300px'
        }
      },
      timer: 2000,
      buttons: false,
      closeOnEsc: false,
      closeOnClickOutside: false
    })
  } catch (err) {}
}

swaller.warning = (text) => {
  try {
    swal({
      // title: 'Attenzione',
      // text,
      title: text,
      content: {
        element: 'img',
        attributes: {
          src: error,
          style: 'width: 300px'
        }
      },
      timer: 2000,
      buttons: false,
      closeOnEsc: false,
      closeOnClickOutside: false
    })
  } catch (err) {}
}

export default swaller
