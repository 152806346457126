import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Modal, ModalTitle, ModalContent } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api } from '../../Services'
import dashboard from '../Home/dashboard'
import { mockupUtenti } from './MockupUtenti'

export default class ModificaUtente extends Component {
  constructor (props) {
    super(props)
    this.state = {
      nome: '',
      cognome: '',
      codiceFiscale: '',
      email: '',
      telefono: '',
      indirizzo: '',
      citta: '',
      cap: '',
      nazione: '',
      azienda: '',
      role: '',
      abilitato: false,
      sezioni: [],
      show: 'hidden'
    }
  }

  componentDidMount () {
    const { _id, nome, cognome, email, telefono, indirizzo, citta, cap, nazione, codiceFiscale, azienda, abilitato, sezioni } = this.props.location.state
    this.setState({ _id, nome, cognome, email, telefono, indirizzo, citta, cap, nazione, codiceFiscale, azienda, abilitato, sezioni })
  }

  async aggiornaUtente () {
    const { _id, nome, cognome, email, telefono, indirizzo, citta, cap, nazione, codiceFiscale, sezioni, abilitato } = this.state
    if (!nome || !cognome || !email || !telefono || !indirizzo || !citta || !cap || !nazione || !codiceFiscale) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(email)) {
        swaller.error('Inserire un indirizzo email valido')
      } else {
        swaller.error('Compilare i campi obbligatori e riprovare!')
      }
    } else {
      swaller.info('Salvataggio in corso...')
      const res = await Api.patch(`/users/${_id}`, {
        nome,
        cognome,
        email,
        telefono,
        indirizzo,
        sezioni,
        citta,
        cap,
        nazione,
        codiceFiscale,
        abilitato
      })
      if (res.ok) {
        swaller.success('Utente aggiornato correttamente!')
        setTimeout(() => this.props.navigate('/utenti'), 2000)
      } else {
        swaller.error('Errore durante la modifica utente, prego riprovare!')
      }
    }
  }

  aggiungiSezione (nomeSezione) {
    const { sezioni } = this.state
    const nuoveSezioni = sezioni.includes(nomeSezione)
      ? sezioni.filter(sezione => sezione !== nomeSezione)
      : [...sezioni, nomeSezione]
    this.setState({ sezioni: nuoveSezioni })
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/utenti')
    }
  }

  render () {
    const { sezioni, show } = this.state

    return (
      <>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}>
                  <FaIcons.FaArrowCircleLeft /> Back
                </Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='warning' outline='true' onClick={() => this.setState({ show: 'visible' })}>Privilegi</Button>
            <Button type='success' outline='true' onClick={() => this.aggiornaUtente()}>Salva Utente</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form>
            <ContentStyle.FormRow height='45%'>
              <ContentStyle.FormColumn width='45%'>
                {mockupUtenti.slice(0, Math.ceil(mockupUtenti.length / 2)).map((field) => (
                  <ContentStyle.Row width='65%' key={field.key}>
                    <ContentStyle.Label>{field.label}:</ContentStyle.Label>
                    <ContentStyle.Text
                      type={field.type}
                      placeholder={field.label.toLowerCase()}
                      value={this.state[field.key]}
                      onChange={(e) => this.setState({ [field.key]: e.target.value })}
                    />
                  </ContentStyle.Row>
                ))}
              </ContentStyle.FormColumn>
              <ContentStyle.FormColumn width='45%'>
                {mockupUtenti.slice(Math.ceil(mockupUtenti.length / 2), mockupUtenti.length).map((field) => (
                  <ContentStyle.Row width='50%' key={field.key}>
                    <ContentStyle.Label>{field.label}:</ContentStyle.Label>
                    <ContentStyle.Text
                      type={field.type}
                      placeholder={field.label.toLowerCase()}
                      value={this.state[field.key]}
                      onChange={(e) => this.setState({ [field.key]: e.target.value })}
                    />
                  </ContentStyle.Row>
                ))}
              </ContentStyle.FormColumn>
            </ContentStyle.FormRow>
          </ContentStyle.Form>
        </ContentStyle.Content>
        <Modal visible={show}>
          <ModalTitle width='100%' direction='row' justify='space-between' paddingleft='50px' paddingright='30px'>Informazioni<FaIcons.FaCheck style={{ cursor: 'pointer' }} color='green' size={24} onClick={() => this.setState({ show: 'hidden' })} /></ModalTitle>
          <ModalContent width='40vw' height='40vh' align='center' direction='row'>
            <ContentStyle.FormColumn justify='flex-start'>
              {dashboard.filter(sezione => sezione.enabled === true).slice(0, dashboard.length / 2 - 1).map((sezione) =>
                <ContentStyle.FormRow justify='flex-start' gap='8px' key={sezione.title}>
                  <ContentStyle.SwitchContainer>
                    <ContentStyle.HiddenCheckbox type='checkbox' checked={sezioni.includes(sezione.title)} onChange={() => this.aggiungiSezione(sezione.title)} />
                    <ContentStyle.RoundSlider />
                  </ContentStyle.SwitchContainer>
                  {sezione.title}
                </ContentStyle.FormRow>
              )}
            </ContentStyle.FormColumn>
            <ContentStyle.FormColumn justify='flex-start'>
              {dashboard.filter(sezione => sezione.enabled === true).slice(dashboard.length / 2 - 1, dashboard.length).map((sezione) =>
                <ContentStyle.FormRow justify='flex-start' gap='8px' key={sezione.title}>
                  <ContentStyle.SwitchContainer>
                    <ContentStyle.HiddenCheckbox type='checkbox' checked={sezioni.includes(sezione.title)} onChange={() => this.aggiungiSezione(sezione.title)} />
                    <ContentStyle.RoundSlider />
                  </ContentStyle.SwitchContainer>
                  {sezione.title}
                </ContentStyle.FormRow>
              )}
            </ContentStyle.FormColumn>
          </ModalContent>
        </Modal>
      </>
    )
  }
}
