import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import Loader from '../../Library/Tools/Loader'
import { variabili } from './MapVariabili'

export default class Variabili extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      azienda: '',
      produttori: [],
      settori: [],
      tipologie: [],
      formValues: {
        tipologia: '',
        settore: '',
        produttore: ''
      }
    }
  }

  async componentDidMount () {
    try {
      this.setState({ loading: true })
      const { azienda } = LocalStorage.get('user')
      const settori = await Api.get('/settori', { azienda })
      const tipologie = await Api.get('/tipologie', { azienda })
      const produttori = await Api.get('/produttori', { azienda })
      if (settori.ok && tipologie.ok && produttori.ok) {
        this.setState({ settori: settori.data, tipologie: tipologie.data, produttori: produttori.data, azienda, loading: false })
      } else {
        swaller.error('Errore nel recupero dei dati')
      }
    } catch (error) {
      swaller.error('Non è stato possibile recuperare i dati')
    }
  }

  async addItem (item, name) {
    const { azienda, formValues } = this.state
    const itemName = formValues[name]
    try {
      let res
      let settoreId
      if (item === 'settori') {
        res = await Api.post(`/${item}`, { [name]: itemName, azienda })
        if (res.ok) {
          settoreId = res.data._id
          await Promise.all([
            Api.post('/ricavi', { settore: settoreId, tipo: 'Articoli', ricarico: 0, azienda }),
            Api.post('/ricavi', { settore: settoreId, tipo: 'Servizi', ricarico: 0, azienda })
          ])
        }
      } else {
        res = await Api.post(`/${item}`, { [name]: itemName, azienda })
      }
      if (res.ok) {
        const newItem = { _id: res.data._id, [name]: itemName }
        this.setState({ [item]: [...this.state[item], newItem] })
        swaller.success('Salvataggio eseguito con successo!')
      } else {
        swaller.error('Errore durante la creazione, prego riprovare!')
      }
    } catch (error) {
      swaller.error('Errore durante la creazione, prego riprovare!')
    }
    this.setState({ formValues: { ...formValues, [name]: '' } })
  }

  render () {
    const { loading, formValues } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form flexdirection='row'>
            {variabili.map((item) => (
              <ContentStyle.FormColumn width='30%' key={item.id} gap='40px'>
                <ContentStyle.Title>Aggiungi un elemento a {item.title}:</ContentStyle.Title>
                <ContentStyle.FormRow justify='space-around'>
                  <ContentStyle.TextVariabili type='text' value={formValues[item.name]} placeholder='Nuovo' onChange={(e) => this.setState({ formValues: { ...formValues, [item.name]: e.target.value } })} />
                  <Button type='success' outline='true' onClick={() => this.addItem(item.id, item.name)}><FaIcons.FaCheckCircle /> Aggiungi</Button>
                </ContentStyle.FormRow>
                <ContentStyle.Select onChange={(e) => this.setState({ [item.id]: e.target.value })}>
                  <option value=''>Elementi esistenti</option>
                  {this.state[item.id].map((e, i) => {
                    return (
                      <option disabled value={e._id} key={i}>
                        {e.settore || e.tipologia || e.produttore}
                      </option>
                    )
                  })}
                </ContentStyle.Select>
              </ContentStyle.FormColumn>
            ))}
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
