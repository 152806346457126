import styled from 'styled-components'

export const Table = styled.table`
  width: ${({ width }) => width || '99%'};
  margin: 0 auto;
  border-collapse: collapse;
  background: transparent;
`

export const Thead = styled.thead`
  padding: 8px;
  height: 60px;
  background: #1877f2;
  color: white;
  border-radius: 24px;
`

export const Trow = styled.tr`
  background: transparent;
  height: 60px;
  border-bottom: 2px solid rgba(123, 137, 140, 0.2);
  &:hover {
    background: lightgrey;
  }
  &:last-child {
    border-bottom: none;
  }
`

export const Tcol = styled.td`
  padding: 8px;
  text-align: center;
  background: transparent;
`
export const Tbody = styled.tbody`
`
