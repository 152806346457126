import React, { Component } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import GlobalStyle from './Utils/GlobalStyles.js'
import WithNavigate from './Utils/WithNavigate'
import * as Pages from './Pages'
import { Api } from './Services'
import { Container } from './Components/Layout.js'
import NavbarObj from './Library/Components/NavbarObj.jsx'
import { day } from './Library/Tools/Utilities.js'

export default class App extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  async componentDidMount () {
    await Api.check()
  }

  render () {
    return (
      <>
        <BrowserRouter>
          <GlobalStyle />
          <Container theme={day}>
            <WithNavigate Component={NavbarObj} />
            <Routes>
              <Route path='/' exact element={<WithNavigate Component={Pages.Login} />} />
              <Route path='/firstlogin' exact element={<WithNavigate Component={Pages.FirstLogin} />} />
              <Route path='/home' exact element={<WithNavigate Component={Pages.Home} />} />
              <Route path='/aziende' exact element={<WithNavigate Component={Pages.Aziende} />} />
              <Route path='/preventivi' exact element={<WithNavigate Component={Pages.Preventivi} />} />
              <Route path='/preventivi/nuovo' exact element={<WithNavigate Component={Pages.NuovoPreventivo} />} />
              <Route path='/fatture' exact element={<WithNavigate Component={Pages.Fatture} />} />
              <Route path='/fatture/nuovo' exact element={<WithNavigate Component={Pages.NuovaFattura} />} />
              <Route path='/fatture/modifica' exact element={<WithNavigate Component={Pages.ModificaFattura} />} />
              <Route path='/settaggi' exact element={<WithNavigate Component={Pages.Settaggi} />} />
              <Route path='/installazioni' exact element={<WithNavigate Component={Pages.Installazioni} />} />
              <Route path='/installazioni/nuova' exact element={<WithNavigate Component={Pages.NuovaInstallazione} />} />
              <Route path='/installazioni/modifica' exact element={<WithNavigate Component={Pages.ModificaInstallazione} />} />
              <Route path='/utenti' exact element={<WithNavigate Component={Pages.Utenti} />} />
              <Route path='/utenti/nuovo' exact element={<WithNavigate Component={Pages.NuovoUtente} />} />
              <Route path='/aziende/nuovo' exact element={<WithNavigate Component={Pages.NuovaAzienda} />} />
              <Route path='/clienti/nuovo' exact element={<WithNavigate Component={Pages.NuovoCliente} />} />
              <Route path='/clienti' exact element={<WithNavigate Component={Pages.Clienti} />} />
              <Route path='/magazzino' exact element={<WithNavigate Component={Pages.Magazzino} />} />
              <Route path='/resoconti' exact element={<WithNavigate Component={Pages.Resoconti} />} />
              <Route path='/resoconti/modifica' exact element={<WithNavigate Component={Pages.ModificaResoconto} />} />
              <Route path='/resoconti/nuovo' exact element={<WithNavigate Component={Pages.AggiungiResoconto} />} />
              <Route path='/richieste' exact element={<WithNavigate Component={Pages.Richieste} />} />
              <Route path='/richieste/resoconti' exact element={<WithNavigate Component={Pages.VisualizzaResoconti} />} />
              <Route path='/preventivi/resoconti' exact element={<WithNavigate Component={Pages.VisualizzaResocontiPreventivi} />} />
              <Route path='/richieste/resoconto/nuovo' exact element={<WithNavigate Component={Pages.AggiungiResoconto} />} />
              <Route path='/preventivi/resoconto/nuovo' exact element={<WithNavigate Component={Pages.AggiungiResoconto} />} />
              <Route path='/ordini' exact element={<WithNavigate Component={Pages.Ordini} />} />
              <Route path='/carrello' exact element={<WithNavigate Component={Pages.Carrello} />} />
              <Route path='/ordini/modificacarrello' exact element={<WithNavigate Component={Pages.ModificaCarrello} />} />
              <Route path='/utenti/modifica' exact element={<WithNavigate Component={Pages.ModificaUtente} />} />
              <Route path='/aziende/modifica' exact element={<WithNavigate Component={Pages.ModificaAzienda} />} />
              <Route path='/clienti/modifica' exact element={<WithNavigate Component={Pages.ModificaCliente} />} />
              <Route path='/preventivi/modifica' exact element={<WithNavigate Component={Pages.ModificaPreventivo} />} />
              <Route path='/richieste/nuovo' exact element={<WithNavigate Component={Pages.NuovaRichiesta} />} />
              <Route path='/richieste/modifica' exact element={<WithNavigate Component={Pages.ModificaRichiesta} />} />
              <Route path='/variabili' exact element={<WithNavigate Component={Pages.Variabili} />} />
              <Route path='/firstotp' exact element={<WithNavigate Component={Pages.FirstOtp} />} />
              <Route path='/resetpassword' exact element={<WithNavigate Component={Pages.ResetPassword} />} />
              <Route path='/servizi' exact element={<WithNavigate Component={Pages.Servizi} />} />
              <Route path='/servizi/nuovo' exact element={<WithNavigate Component={Pages.NuovoServizio} />} />
              <Route path='/servizi/modifica' exact element={<WithNavigate Component={Pages.ModificaServizio} />} />
              <Route path='/fornitori' exact element={<WithNavigate Component={Pages.Fornitori} />} />
              <Route path='/fornitori/nuovo' exact element={<WithNavigate Component={Pages.NuovoFornitore} />} />
              <Route path='/fornitori/modifica' exact element={<WithNavigate Component={Pages.ModificaFornitori} />} />
              <Route path='/articoli' exact element={<WithNavigate Component={Pages.Articoli} />} />
              <Route path='/articoli/nuovo' exact element={<WithNavigate Component={Pages.NuovoArticolo} />} />
              <Route path='/articoli/modifica' exact element={<WithNavigate Component={Pages.ModificaArticolo} />} />
              <Route path='/ricavi' exact element={<WithNavigate Component={Pages.Ricavi} />} />
              <Route path='/ricavi/nuovo' exact element={<WithNavigate Component={Pages.NuovoRicavo} />} />
              <Route path='/ricavi/modifica' exact element={<WithNavigate Component={Pages.ModificaRicavo} />} />
              <Route path='/analisi' exact element={<WithNavigate Component={Pages.Analisi} />} />
              <Route path='/costi' exact element={<WithNavigate Component={Pages.Costi} />} />
              <Route path='/costi/nuovo' exact element={<WithNavigate Component={Pages.NuovoCosto} />} />
              <Route path='/costi/modifica' exact element={<WithNavigate Component={Pages.ModificaCosto} />} />
              <Route path='/rimborsi' exact element={<WithNavigate Component={Pages.Rimborsi} />} />
              <Route path='/rimborsi/nuovo' exact element={<WithNavigate Component={Pages.NuovoRimborso} />} />
              <Route path='/rimborsi/modifica' exact element={<WithNavigate Component={Pages.ModificaRimborso} />} />
              <Route path='/scatole' exact element={<WithNavigate Component={Pages.Scatole} />} />
              <Route path='/scatole/modifica' exact element={<WithNavigate Component={Pages.ModificaScatola} />} />
              <Route path='/progetti' exact element={<WithNavigate Component={Pages.Progetti} />} />
              <Route path='/progetti/nuovo' exact element={<WithNavigate Component={Pages.NuovoProgetto} />} />
              <Route path='/progetti/modifica' exact element={<WithNavigate Component={Pages.ModificaProgetto} />} />
              <Route path='/canoni' exact element={<WithNavigate Component={Pages.Canoni} />} />
              <Route path='/canoni/nuovo' exact element={<WithNavigate Component={Pages.AggiungiCanoni} />} />
              <Route path='/canoni/modifica' exact element={<WithNavigate Component={Pages.ModificaCanoni} />} />
            </Routes>
          </Container>
        </BrowserRouter>
      </>
    )
  }
}
