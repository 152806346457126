const columnResocontoCarrello = [
  {
    id: 'tipo',
    label: 'Tipo'
  },
  {
    id: 'referenza',
    label: 'Referenza'
  },
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'fornitore',
    label: 'Fornitore'
  },
  {
    id: 'produttore',
    label: 'Produttore'
  },
  {
    id: 'quantita',
    label: 'Quantità'
  },
  {
    id: 'info',
    label: 'Info'
  },
  {
    id: 'elimina',
    label: 'Elimina'
  }
]

const columnAggiuntaCarrello = [
  {
    id: 'referenza',
    label: 'Referenza'
  },
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'fornitore',
    label: 'Fornitore'
  },
  {
    id: 'produttore',
    label: 'Produttore'
  },
  {
    id: 'settore',
    label: 'Settore'
  },
  {
    id: 'aggiungi',
    label: 'Aggiungi'
  },
  {
    id: 'info',
    label: 'Info'
  }
]

const columnAggiuntaServizi = [
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'descrizione',
    label: 'Descrizione'
  },
  {
    id: 'costo',
    label: 'Costo'
  },
  {
    id: 'aggiungi',
    label: 'Aggiungi'
  }
]

const columnResoconto = [
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'descrizione',
    label: 'Descrizione'
  },
  {
    id: 'costo',
    label: 'Costo'
  },
  {
    id: 'quantita',
    label: 'Quantità'
  },
  {
    id: 'rimuovi',
    label: 'Rimuovi'
  }
]

export { columnResocontoCarrello, columnAggiuntaCarrello, columnAggiuntaServizi, columnResoconto }
