import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'

export default class ModificaProgetto extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      clienti: [],
      settori: [],
      selectedSettore: '',
      selectedCliente: '',
      tipo: '0', // 1: Esterno, 0: Interno
      titolo: ''
    }
  }

  async componentDidMount () {
    const { ...data } = this.props.location.state
    const { azienda } = LocalStorage.get('user')
    const clienti = await Api.get('/users', { azienda, role: 'cliente' })
    const settori = await Api.get('/settori', { azienda })
    this.setState({ data, clienti: clienti.data, settori: settori.data })
  }

  async aggiornaProgetto () {
    try {
      const { data, titolo, tipo, cliente, settore } = this.state
      const { _id } = data
      if (!titolo || tipo.length === 0 || cliente.length === 0 || settore.length === 0) {
        swaller.error('Completa i campi obbligatori')
      }
      swaller.info('Salvataggio in corso...')
      let res
      data.tipo === '0'
        ? res = await Api.patch(`/progetti/${_id}`, { titolo: data.titolo, tipo: data.tipo, clienti: [], settori: data.settori })
        : res = await Api.patch(`/progetti/${_id}`, { titolo: data.titolo, tipo: data.tipo, clienti: data.clienti, settori: [] })
      if (res.ok) {
        swaller.success('Progetto salvata correttamente!')
        setTimeout(() => this.props.navigate('/progetti'), 2000)
      } else {
        swaller.error('Errore durante la creazione del progetto, prego riprovare!')
      }
    } catch (error) {
      swaller.error('Errore! Non è stato possibile aggiornare i dati. Riprovare')
    }
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  aggiungiSettore (settore) {
    const { data } = this.state
    if (settore === '') {
      swaller.info('Non si può aggiungere il settore di default', 1500)
    } else if (!this.state.data.settori.includes(settore)) {
      this.setState(({ data: { ...data, settori: [...data.settori, settore] } }))
    }
  }

  aggiungiCliente (cliente) {
    const { data } = this.state
    if (cliente === '') {
      swaller.info('Non si può aggiungere il cliente di default', 1500)
    } else if (!this.state.data.clienti.includes(cliente)) {
      this.setState(({ data: { ...data, clienti: [...data.clienti, cliente] } }))
    }
  }

  rimuoviSettore (settore) {
    const newData = { ...this.state.data }
    const index = newData.settori.indexOf(settore)
    if (index !== -1) {
      newData.settori.splice(index, 1)
      this.setState({ data: newData })
    }
  }

  rimuoviCliente (cliente) {
    const newData = { ...this.state.data }
    const index = newData.clienti.indexOf(cliente)
    if (index !== -1) {
      newData.clienti.splice(index, 1)
      this.setState({ data: newData })
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/progetti')
    }
  }

  render () {
    const { data, clienti, settori, selectedCliente, selectedSettore } = this.state

    return (
      <>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}>
                  <FaIcons.FaArrowCircleLeft /> Back
                </Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.aggiornaProgetto()}>Aggiorna progetto</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content top='0px'>
          <ContentStyle.Form>
            <ContentStyle.FormColumn gap='30px'>
              <ContentStyle.Label>Titolo: </ContentStyle.Label>
              <ContentStyle.Text width='200px' type='text' placeholder='titolo' value={data.titolo} onChange={(e) => this.setState({ data: { ...this.state.data, titolo: e.target.value } })} />
              <ContentStyle.Label>Tipologia progetto: </ContentStyle.Label>
              <ContentStyle.Select style={{ width: '200px' }} value={data.tipo} onChange={(e) => this.setState({ data: { ...this.state.data, tipo: e.target.value } })}>
                <option value='0'>Interno</option>
                <option value='1'>Esterno</option>
              </ContentStyle.Select>
              {data.tipo === '1'
                ? (
                  <>
                    <ContentStyle.Label>Clienti: </ContentStyle.Label>
                    <ContentStyle.Select value={selectedCliente} onChange={(e) => this.aggiungiCliente(e.target.value)}>
                      <option value=''>...</option>
                      {clienti.filter(item => !data.clienti.some(clienteItem => clienteItem === item._id)).map((item, index) =>
                        <option value={item._id} key={index}>{this.getCliente(item._id)}</option>)}
                    </ContentStyle.Select>
                    <ContentStyle.RowItem gap='20px'>
                      {clienti.length > 0 && (
                        <>
                          {data.clienti.map((cliente, index) =>
                            <div key={index}>
                              <i>{this.getCliente(cliente)}</i>
                              <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer', marginLeft: '10px' }} onClick={() => this.rimuoviCliente(cliente)} />
                            </div>
                          )}
                        </>)}
                      {clienti.length === 0 && <i>Nessun cliente selezionato</i>}
                    </ContentStyle.RowItem>
                  </>)
                : (
                  <>
                    <ContentStyle.Label>Settore: </ContentStyle.Label>
                    <ContentStyle.Select value={selectedSettore} onChange={(e) => this.aggiungiSettore(e.target.value)}>
                      <option value=''>...</option>
                      {settori.filter(item => !data.settori.some(settoreItem => settoreItem === item._id)).map((item, index) =>
                        <option key={index} value={item._id}>{item.settore}</option>)}
                    </ContentStyle.Select>
                    <ContentStyle.RowItem gap='20px'>
                      {settori.length > 0 && (
                        <>
                          {data.settori.map((settore, index) =>
                            <div key={index}>
                              <i>{this.getSettore(settore)}</i>
                              <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer', marginLeft: '10px' }} onClick={() => this.rimuoviSettore(settore)} />
                            </div>
                          )}
                        </>
                      )}
                      {settori.length === 0 && <i>Nessun settore selezionato</i>}
                    </ContentStyle.RowItem>
                  </>)}
            </ContentStyle.FormColumn>
          </ContentStyle.Form>
        </ContentStyle.Content>
      </>
    )
  }
}
