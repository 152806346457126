import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, Logout, BoxInfo, BoxContent, BoxRow } from '../../Components'
import { Button, CloseButton } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnAggiuntaCarrello, columnResocontoCarrello } from '../Resoconti/MockupResoconti'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { BoxAggiuntaMateriale } from '../../Components/ModalInfo'
import Loader from '../../Library/Tools/Loader'

export default class ModificaScatola extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      carrello: [],
      articoli: [],
      articoliScatola: [],
      magazzino: [],
      content: [],
      data: [],
      infoValue: {},
      showInfoBox: false,
      showBoxAggiuntaMateriale: false,
      tipologiaCarrello: '...',
      azienda: '',
      _id: '',
      settore: [],
      produttore: [],
      fornitore: [],
      nScatola: 0,
      clienti: [],
      cliente: '',
      tecnici: [],
      tecnico: ''
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const { _id, articoliScatola, nScatola, tecnico, cliente } = this.props.location.state
    this.setState({ azienda, _id, content: articoliScatola, tipologiaCarrello: 'creazione scatola', nScatola, tecnico, cliente }, async () => await this.loadData())
  }

  async loadData () {
    const { azienda } = this.state
    const articoli = await Api.get('/articoli', { azienda })
    const magazzino = await Api.get('/magazzino', { azienda })
    const produttori = await Api.get('/produttori', { azienda })
    const settori = await Api.get('/settori', { azienda })
    const fornitori = await Api.get('/fornitori', { azienda })
    const clienti = await Api.get('/users', { azienda, role: 'cliente' })
    const tecnici = await Api.get('/users', { azienda, role: 'utente' })
    produttori.ok && settori.ok && fornitori.ok && articoli.ok && magazzino.ok && tecnici.ok && clienti.ok
      ? this.setState({ articoli: articoli.data, data: articoli.data, magazzino: magazzino.data, produttore: produttori.data, settore: settori.data, fornitore: fornitori.data, clienti: clienti.data, tecnici: tecnici.data }, () => {
        this.ricreaCarrello()
        this.setState({ loading: false })
      })
      : swaller.error('Non è stato possibile recuperare i dati')
  }

  ricreaCarrello () {
    const { content } = this.state
    const carrello = []
    content.forEach(item => {
      const obj = {
        _id: item.item,
        settore: this.getData(item.item, 'articoli', 'settore'),
        referenza: this.getData(item.item, 'articoli', 'referenza'),
        titolo: this.getData(item.item, 'articoli', 'titolo'),
        fornitore: this.getData(item.item, 'articoli', 'fornitore'),
        produttore: this.getData(item.item, 'articoli', 'produttore'),
        qta: item.qta,
        descrizione: this.getData(item.item, 'articoli', 'descrizione')
      }
      carrello.push(obj)
    })
    this.setState({ carrello })
  }

  async generaCodice () {
    const { azienda } = this.state
    const res = await Api.get('/ordini', { azienda })
    let codice = 0
    codice = res.ok
      ? res.data.length + 1
      : new Date().unix()
    return codice
  }

  filter (search, value) {
    const { data } = this.state
    search === ''
      ? this.setState({ articoli: data })
      : this.setState({ articoli: data.filter(item => item[value].toLowerCase().includes(search.toLowerCase())) })
  }

  async confermaCarrello () {
    const { carrello, magazzino, azienda, _id, cliente, tecnico, nScatola } = this.state
    const error = carrello.some(item => item.qta <= 0)
    if (!error) {
      if (cliente === '' && tecnico === '') {
        swaller.error('Selezionare un cliente o un tecnico a cui asseganre la scatola!')
      } else {
        for (const item of carrello) {
          const obj = magazzino.filter((e) => e.articolo === item._id)
          obj[0].qtaStock = obj[0].qtaStock - item.qta
          await Api.patch(`/magazzino/${obj[0]._id}`, obj[0])
        }
        swaller.success('Magazzino aggiornato correttamente!')
        const articoliScatola = carrello.map(item => ({ item: item._id, qta: item.qta }))
        const scatola = {
          cliente,
          tecnico,
          articoliScatola,
          dataOraCreazione: new Date().toISOString(),
          creatore: _id,
          azienda
        }
        if (articoliScatola.length > 0) {
          const res = await Api.patch(`/scatole/${_id}`, scatola)
          if (res.ok) {
            swaller.success(`Scatola N. ${nScatola} salvata correttamente!`)
            setTimeout(() => this.props.navigate('/scatole'), 2000)
          } else {
            swaller.error('Errore durante il salvataggio della scatola!')
          }
        } else {
          swaller.error('Carrello vuoto')
        }
        setTimeout(() => this.props.navigate('/scatole'), 2000)
      }
    } else {
      swaller.warning('Alcuni articoli hanno quantità 0')
    }
  }

  getInfo (_id, value, field) {
    const items = this.state[value]
    let text = ''
    text = items.find(item => item._id === _id)
    if (field) {
      return text ? text[field] : ''
    } else {
      return text ? text[value] : ''
    }
  }

  getData (_id, value, field) {
    const items = this.state[value]
    const item = items.find(item => item._id === _id)
    if (item) {
      return field ? item[field] : item[value]
    }
    return ''
  }

  addCarrello (item) {
    const { carrello } = this.state
    const check = carrello.some(element => element._id === item._id)
    if (check) {
      swaller.warning('Articolo già aggiunto')
    } else {
      item.qta = 0
      carrello.push(item)
      this.setState({ carrello })
    }
  }

  async removeCarrello (index) {
    const { carrello } = this.state
    const res = await swaller.question('Sicuro di voler eliminare ?')
    if (res) {
      carrello.splice(index, 1)
      this.setState({ carrello })
    }
  }

  handleQuantity (index, qta) {
    const { carrello } = this.state
    carrello[index].qta = qta >= 0
      ? Number(qta)
      : 0
    this.setState({ carrello })
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/scatole')
    }
  }

  render () {
    const { loading, carrello, articoli, showInfoBox, infoValue, tipologiaCarrello, showBoxAggiuntaMateriale, clienti, tecnici, cliente, tecnico } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Logout>
              {articoli.length > 0 && <Button type='info' outline='true' onClick={() => this.setState({ showBoxAggiuntaMateriale: true })}><FaIcons.FaPlusCircle />Aggiungi articolo</Button>}
              <Button type='success' disabled={carrello.length === 0} outline='true' onClick={() => this.confermaCarrello()}><FaIcons.FaCheckCircle />Conferma {tipologiaCarrello}</Button>
            </Logout>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content direction='column'>
          <ContentStyle.Row center='center'>
            <ContentStyle.Title>Assegnatario scatola:</ContentStyle.Title>
            <ContentStyle.Box>
              <ContentStyle.Label>Cliente: </ContentStyle.Label>
              <ContentStyle.Select value={cliente} onChange={(e) => e.target.value != null && this.setState({ cliente: e.target.value })}>
                <option value=''>...</option>
                {clienti && clienti.length > 0 && clienti.map((item, i) => {
                  return (
                    <option value={item._id} key={i}>
                      {item.isPrivate === '1' ? `${item.nome} ${item.cognome}` : item.intestazione}
                    </option>
                  )
                })}
              </ContentStyle.Select>
              <ContentStyle.Label>Tecnico: </ContentStyle.Label>
              <ContentStyle.Select value={tecnico} onChange={(e) => e.target.value != null && this.setState({ tecnico: e.target.value })}>
                <option value=''>...</option>
                {tecnici && tecnici.length > 0 && tecnici.map((item, i) => {
                  return (
                    <option value={item._id} key={i}>
                      {`${item.nome} ${item.cognome}`}
                    </option>
                  )
                })}
              </ContentStyle.Select>
            </ContentStyle.Box>
          </ContentStyle.Row>
          <ContentStyle.Table>
            {carrello.length > 0 &&
              <Table>
                <Thead>
                  <Trow>
                    {columnResocontoCarrello.map((colonne, i) => {
                      return (
                        <Tcol key={i}>
                          <b>{colonne.label}</b>
                        </Tcol>
                      )
                    })}
                  </Trow>
                </Thead>
                <Tbody>
                  {carrello.map((item, i) => {
                    return (
                      <Trow key={i}>
                        <Tcol>{this.getInfo(item.settore, 'settore')}</Tcol>
                        <Tcol>{item.referenza}</Tcol>
                        <Tcol>{item.titolo}</Tcol>
                        <Tcol>{this.getInfo(item.fornitore, 'fornitore')}</Tcol>
                        <Tcol>{this.getInfo(item.produttore, 'produttore')}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <input style={{ width: 50 }} type='number' value={item.qta} onChange={(e) => this.handleQuantity(i, e.target.value)} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: item })} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.removeCarrello(i)} />
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>}
            {carrello.length === 0 && <i style={{ margin: 'auto' }}>Nessun prodotto aggiunto</i>}
          </ContentStyle.Table>
          <BoxInfo showInfoBox={showInfoBox}>
            <BoxContent>
              <Toolbar>
                <CloseButton>
                  <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: false, infoValue: {} })} />
                </CloseButton>
              </Toolbar>
              <ContentStyle.Title>Descrizione:</ContentStyle.Title>
              <hr />
              <BoxRow>Referenza: {infoValue.referenza}</BoxRow>
              <BoxRow>Titolo: {infoValue.titolo}</BoxRow>
              <BoxRow>Produttore: {this.getInfo(infoValue.produttore, 'produttore')}</BoxRow>
              <BoxRow>Fornitore: {this.getInfo(infoValue.fornitore, 'fornitore')}</BoxRow>
              <BoxRow>Settore: {this.getInfo(infoValue.settore, 'settore')}</BoxRow>
              <ContentStyle.Row style={{ display: 'flex', alignItems: 'center', gap: 10 }}>Descrizione: <ContentStyle.Textarea disabled value={infoValue.descrizione} /></ContentStyle.Row>
            </BoxContent>
          </BoxInfo>
          <BoxAggiuntaMateriale show={showBoxAggiuntaMateriale}>
            <Toolbar paddingleft='30px' paddingright='30px'>
              <ToolbarItem>
                <h3>Selezionare gli articoli</h3>
                <ContentStyle.Search type='text' style={{ marginLeft: 10 }} placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'referenza')} />
              </ToolbarItem>
              <ToolbarItem isend='true'>
                <FaIcons.FaCheckSquare size={36} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.setState({ showBoxAggiuntaMateriale: false, search: '' })} />
              </ToolbarItem>
            </Toolbar>
            <br />
            <ContentStyle.Table>
              <Table>
                <Thead>
                  <Trow>
                    {columnAggiuntaCarrello.map((colonne, i) => {
                      return (
                        <Tcol key={i}>
                          <b>{colonne.label}</b>
                        </Tcol>
                      )
                    })}
                  </Trow>
                </Thead>
                <Tbody>
                  {articoli.map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{item.referenza}</Tcol>
                      <Tcol>{item.titolo}</Tcol>
                      <Tcol>{this.getInfo(item.fornitore, 'fornitore')}</Tcol>
                      <Tcol>{this.getInfo(item.produttore, 'produttore')}</Tcol>
                      <Tcol>{this.getInfo(item.settore, 'settore')}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        {carrello.filter((e) => e._id === item._id).length === 0 && <FaIcons.FaPlusCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrello(item)} />}
                        {carrello.filter((e) => e._id === item._id).length > 0 && <FaIcons.FaCheckCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrello(item)} />}
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: item })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
            </ContentStyle.Table>
          </BoxAggiuntaMateriale>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
