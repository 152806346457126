import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, Modal, ModalIcon, ModalTitle, ModalContent, ModalLabel } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'
import { columnInstallazioni } from './MockupInstallazioni'

export default class Installazioni extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      installazioni: [],
      show: 'hidden',
      settori: [],
      data: [],
      azienda: '',
      descrizione: '',
      clienti: [],
      infoValue: {}
    }
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      this.setState({ azienda })
      const installazioni = await Api.get('/installazioni', { azienda })
      const settori = await Api.get('/settori', { azienda })
      const clienti = await Api.get('/users', { azienda, role: 'cliente' })
      if (installazioni.ok && settori.ok && clienti.ok) {
        this.setState({ installazioni: installazioni.data, data: installazioni.data, settori: settori.data, clienti: clienti.data, loading: false })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante il recupero dei dati')
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageInstallazioni = data.slice(startIndex, endIndex)
    return currentPageInstallazioni
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  filter (search) {
    const { installazioni } = this.state
    search === ''
      ? this.setState({ data: installazioni })
      : this.setState({ data: installazioni.filter(item => this.getCliente(item.cliente).toLowerCase().includes(search.toLowerCase())) })
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  render () {
    const { data, loading, installazioni, show, infoValue, clienti, settori, currentPage, itemPerPage } = this.state
    const totalPages = Math.ceil(installazioni.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value)} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/installazioni/nuova')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuova installazione</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnInstallazioni.map((colonna) =>
                    <Tcol key={colonna.id}>
                      <b>{colonna.label}</b>
                    </Tcol>
                  )}
                </Thead>
                <Tbody>
                  {installazioni.length > 0 && clienti.length > 0 && settori.length > 0 && this.showData().map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{item.codiceInstallazione}</Tcol>
                      <Tcol>{this.getCliente(item.cliente)}</Tcol>
                      <Tcol>{item.descrizione}</Tcol>
                      <Tcol>{item.settori && item.settori.map((item, i) =>
                        <p key={i}>
                          {this.getSettore(item)}
                        </p>
                      )}
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/installazioni/modifica', { state: item, replace: false })} />
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ show: 'visible', infoValue: item })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessuna installazione presente</i>}
        </ContentStyle.Content>
        <Modal visible={show}>
          <ModalIcon><FaIcons.FaTimes size={36} onClick={() => this.setState({ show: 'hidden', infoValue: {} })} /></ModalIcon>
          <ModalTitle>Informazioni</ModalTitle>
          <ModalContent>
            <ModalLabel>Codice installazione: {infoValue && infoValue.codiceInstallazione}</ModalLabel>
            <ModalLabel>Cliente: {this.getCliente(infoValue.cliente)}</ModalLabel>
            <ModalLabel>
              <ContentStyle.Label>Settori coinvolti: </ContentStyle.Label>
              {infoValue && infoValue.settori && infoValue.settori.length > 0 && infoValue.settori.map((item, i) =>
                <i key={i}>{this.getSettore(item)}<br /></i>
              )}
            </ModalLabel>
            <ModalLabel>Descrizione: <ContentStyle.Textarea disabled value={infoValue && infoValue.descrizione} /></ModalLabel>
          </ModalContent>
        </Modal>
      </Loader>
    )
  }
}
