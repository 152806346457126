import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnDettaglioPreventivi, columnPreventivi } from '../TitoliColonne'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { BoxAggiuntaMateriale } from '../../Components/ModalInfo'
// import { PdfDownload } from '../../Library/Tools/Pdf'
// import { PdfPreventivi } from '../../Pdf'
import { Config } from '../../Global'
import Loader from '../../Library/Tools/Loader'

export default class Preventivi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      preventivi: [],
      content: [],
      data: [],
      preventivo: [],
      showOrder: false,
      azienda: '',
      utenti: [],
      showQrCode: false,
      renderPdf: false,
      allStatus: ''
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const preventivi = await Api.get('/preventivi', { azienda })
    const utenti = await Api.get('/users', { azienda })
    preventivi.ok && utenti.ok
      ? this.setState({ azienda, preventivi: preventivi.data, data: preventivi.data, utenti: utenti.data, loading: false })
      : swaller.error('Non è stato possibile recuperare i dati')
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPagePreventivi = data.slice(startIndex, endIndex)
    return currentPagePreventivi
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  filter (search, value) {
    const { preventivi } = this.state
    search === ''
      ? this.setState({ data: preventivi })
      : this.setState({ data: preventivi.filter(item => this.getUtente(item[value]).toLowerCase().includes(search.toLowerCase())) })
  }

  async changeStatus (id, stato) {
    const response = await swaller.question('Vuoi modificare lo stato del preventivo?')
    if (response) {
      swaller.info('Modifica stato preventivo in corso')
      let resPreventivo = ''
      if (stato === 1) {
        resPreventivo = await Api.patch(`/preventivi/${id}`, {
          stato,
          accettato: true,
          dataOraAccettazione: new Date().toISOString()
        })
      } else {
        resPreventivo = await Api.patch(`/preventivi/${id}`, { stato })
      }
      if (resPreventivo.ok) {
        swaller.success('Preventivo modificato con successo')
      }
    }
  }

  getInfo (_id, value, field) {
    const items = this.state[value]
    let text = ''
    text = items.find(item => item._id === _id)
    if (field) {
      return text ? text[field] : ''
    } else {
      return text ? text[value] : ''
    }
  }

  getUser (_id) {
    const { utenti } = this.state
    const foundUser = utenti.find(user => _id === user._id)
    return foundUser ? (foundUser.isPrivate === '0' ? foundUser.intestazione : `${foundUser.nome} ${foundUser.cognome}`) : ''
  }

  // pdfExport (item, index) {
  //   const data = { ...item }
  //   data.produttore = this.getInfo(item.articoli[index].produttore, 'produttore')
  //   data.settore = this.getInfo(item.articoli[index].settore, 'settore')
  //   data.fornitore = this.getInfo(item.articoli[index].fornitore, 'fornitore')
  //   data.cliente = this.getUser(item.cliente)
  //   return data
  // }

  async filterStatus (value) {
    // TODO: problema con filtro
    const { azienda } = this.state
    this.setState({ loading: true })
    let preventivi = []
    if (value === '') {
      preventivi = await Api.get('/preventivi', { azienda })
    } else {
      preventivi = await Api.get('/preventivi', { azienda, stato: value })
    }
    if (preventivi.ok) {
      this.setState({ preventivi: preventivi.data, data: preventivi.data, allStatus: value }, () => {
        this.setState({ loading: false })
      })
    } else {
      swaller.error('Impossibile caricare i preventivi!')
    }
  }

  render () {
    const { data, currentPage, itemPerPage, loading, preventivi, preventivo, showOrder, allStatus } = this.state
    const totalPages = Math.ceil(preventivi.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'cliente')} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/preventivi/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuovo preventivo</span> </Button2>
            <ContentStyle.Select value={allStatus} onChange={(e) => this.filterStatus(e.target.value)}>
              <option value=''>Tutti</option>
              <option value='0'>Nuovo</option>
              <option value='1'>Assegnato</option>
              <option value='2'>Sospeso</option>
              <option value='3'>Chiuso</option>
              <option value='4'>Fatturato</option>
            </ContentStyle.Select>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnPreventivi.map((colonne, i) => {
                    return (
                      <Tcol key={i}>
                        <b>{colonne.label}</b>
                      </Tcol>
                    )
                  })}
                </Thead>
                <Tbody>
                  {this.showData().map((preventivo, i) => {
                    return (
                      <Trow key={i}>
                        <Tcol>{preventivo.numero}</Tcol>
                        <Tcol>{this.getUser(preventivo.cliente)}</Tcol>
                        <Tcol>{preventivo.sezioni.length}</Tcol>
                        <Tcol>{Config.format(preventivo.totale)}</Tcol>
                        <Tcol>{Config.format(preventivo.variazione)}</Tcol>
                        <Tcol>{new Date(preventivo.dataOraCreazione).toLocaleString('it-IT')} - {this.getUser(preventivo.creatore)}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <ContentStyle.Select value={preventivo.stato} onChange={(e) => this.changeStatus(preventivo._id, e.target.value)}>
                            <option value='0'>Nuovo</option>
                            <option value='1'>Assegnato</option>
                            <option value='2'>Sospeso</option>
                            <option value='3'>Chiuso</option>
                            <option value='4'>Fatturato</option>
                          </ContentStyle.Select>
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/preventivi/modifica', { state: preventivo, replace: false })} />
                          {/* onClick={() => this.setState({ preventivo: preventivo.articoli, showOrder: true })} */}
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaListOl size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/preventivi/resoconti', { state: preventivo, replace: false })} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          PDF
                          {/* {PdfDownload(PdfPreventivi(this.pdfExport(preventivo, i)), `Preventivo_${preventivo.nPreventivo}`)} */}
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun preventivo presente</i>}
          <BoxAggiuntaMateriale show={showOrder}>
            <Toolbar>
              <ToolbarItem>
                <h3>Elenco articoli</h3>
              </ToolbarItem>
              <ToolbarItem isend='true'>
                <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showOrder: false })} />
              </ToolbarItem>
            </Toolbar>
            <br />
            <ContentStyle.Table>
              <Table>
                <Thead>
                  <Trow>
                    {columnDettaglioPreventivi.map((colonne, i) => {
                      return (
                        <Tcol key={i}>
                          <b>{colonne.label}</b>
                        </Tcol>
                      )
                    })}
                  </Trow>
                </Thead>
                <Tbody>
                  {preventivo.length > 0 && preventivo.map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{item.titolo}</Tcol>
                      <Tcol>{item.qta}</Tcol>
                      <Tcol>{this.getInfo(item.produttore, 'produttore')}</Tcol>
                      <Tcol>{this.getInfo(item.fornitore, 'fornitore')}</Tcol>
                      <Tcol>{this.getInfo(item.settore, 'settore')}</Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
            </ContentStyle.Table>
          </BoxAggiuntaMateriale>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
