import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, BoxContent, BoxRow, Modal } from '../../Components'
import { Button, Button2, CloseButton, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnCosti } from './MockupCosti'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { Config } from '../../Global'
import Loader from '../../Library/Tools/Loader'

export default class Costi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      data: [],
      costi: [],
      show: 'hidden',
      infoValue: {},
      azienda: '',
      descrizione: ''
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const res = await Api.get('/costi', { azienda })
    if (res.ok) {
      this.setState({ costi: res.data, data: res.data, azienda, loading: false })
    } else {
      swaller.error('Non è stato possibile recuperare i costi')
    }
  }

  getCosto (_id) {
    const { costi } = this.state
    const foundCosto = costi.find(costo => _id === costo._id)
    return foundCosto ? foundCosto.titolo : ''
  }

  filter (search) {
    const { costi } = this.state
    search === ''
      ? this.setState({ data: costi })
      : this.setState({ data: costi.filter(item => this.getCosto(item._id).toLowerCase().includes(search.toLowerCase())) })
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageCosti = data.slice(startIndex, endIndex)
    return currentPageCosti
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  async handleKeyDown (event) {
    if (event.keyCode === 13) {
      this.searchName()
    }
  }

  render () {
    const { data, currentPage, itemPerPage, loading, costi, show, infoValue } = this.state
    const totalPages = Math.ceil(costi.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem gap='10px' isend='true'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onKeyDown={e => this.handleKeyDown(e.target.value)} onChange={(e) => this.filter(e.target.value)} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/costi/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuovo costo</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnCosti.map((colonne) =>
                    <Tcol key={colonne.id}>
                      <b>{colonne.label}</b>
                    </Tcol>
                  )}
                </Thead>
                <Tbody>
                  {this.showData().map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{item.titolo}</Tcol>
                      <Tcol>{Config.format(item.costo)}</Tcol>
                      <Tcol>{item.descrizione}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>{item.isAnnual ? 'Annuale' : 'Mensile'}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/costi/modifica', { state: item, replace: false })} />
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ show: 'visible', infoValue: item })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun costo presente</i>}
          <Modal visible={show}>
            <BoxContent>
              <Toolbar>
                <ToolbarItem>
                  <CloseButton>
                    <FaIcons.FaTimes size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ show: 'hidden', infoValue: {} })} />
                  </CloseButton>
                </ToolbarItem>
              </Toolbar>
              <ContentStyle.Title>Informazioni:</ContentStyle.Title>
              <BoxRow>Titolo: {infoValue.titolo}</BoxRow>
              <BoxRow>Costo: {infoValue.costo}</BoxRow>
              <BoxRow>Periodicità: {infoValue.isAnnual ? 'Annuale' : 'Mensile'}</BoxRow>
              <BoxRow>Descrizione: {infoValue.descrizione}</BoxRow>
            </BoxContent>
          </Modal>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
