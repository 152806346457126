import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Table, Thead, Tbody, Tcol, Trow, BoxInfo, BoxContent, BoxRow } from '../../Components'
import { Button, Button2, CloseButton, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnFatture } from '../TitoliColonne'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'
import { Config } from '../../Global'

export default class Fatture extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      fatture: [],
      data: [],
      showInfoBox: false,
      infoValue: {},
      clienti: [],
      azienda: '',
      descrizione: '',
      preventivi: [],
      richieste: [],
      ricavi: [],
      tecnici: [],
      stato: '',
      filtro: false
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const clienti = await Api.get('/users', { azienda, role: 'cliente' })
    const fatture = await Api.get('/fatture', { azienda })
    const preventivi = await Api.get('/preventivi', { azienda })
    const richieste = await Api.get('/richieste', { azienda })
    const ricavi = await Api.get('/ricavi', { azienda })
    const tecnici = await Api.get('/users', { azienda, role: 'utente' })
    clienti.ok && fatture.ok && preventivi.ok && richieste.ok && ricavi.ok && tecnici.ok
      ? this.setState({ azienda, clienti: clienti.data, fatture: fatture.data, data: fatture.data, preventivi: preventivi.data, richieste: richieste.data, ricavi: ricavi.data, tecnici: tecnici.data, loading: false })
      : swaller.error('Non è stato possibile recuperare i dati')
  }

  showData () {
    const { data, itemPerPage, currentPage, filtro } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    let currentPageFatture
    filtro
      ? currentPageFatture = data.filter(item => item.saldata === 'true').slice(startIndex, endIndex)
      : currentPageFatture = data.filter(item => item.saldata === 'false').slice(startIndex, endIndex)
    return currentPageFatture
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  getTecnico (_id) {
    const { tecnici } = this.state
    const tecnico = tecnici.find(tecnico => tecnico._id === _id)
    return tecnico ? `${tecnico.nome} ${tecnico.cognome}` : ''
  }

  async changeStatus (_id, status) {
    try {
      swaller.info('Aggiornamento in corso')
      const res = await Api.patch(`/fatture/${_id}`, { saldata: status === 'true' ? 'false' : 'true' })
      if (res.ok) {
        const updatedFatture = this.state.fatture.map(fattura => {
          if (fattura._id === _id) {
            fattura.saldata = status === 'true' ? 'false' : 'true'
          }
          return fattura
        })
        this.setState({ fatture: updatedFatture })
        swaller.success('Fattura aggiornata correttamente')
      } else {
        swaller.error('Errore durante l\'aggiornamento dello stato della fattura')
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante l\'aggiornamento dello stato della fattura')
    }
  }

  filter (search, value) {
    const { fatture, filtro } = this.state
    const filteredFatture = search === ''
      ? fatture
      : fatture.filter(item => this.getCliente(item[value]).toLowerCase().includes(search.toLowerCase()))
    const updatedFatture = filtro
      ? filteredFatture.filter(item => item.saldata === 'true')
      : filteredFatture.filter(item => item.saldata === 'false')
    this.setState({ data: updatedFatture })
  }

  render () {
    const { filtro, fatture, showInfoBox, infoValue, loading, currentPage, itemPerPage } = this.state
    const totalPages = Math.ceil(fatture.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft />Back</Button>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca cliente...' onChange={(e) => this.filter(e.target.value, 'cliente')} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/fatture/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuova fattura</span> </Button2>
            <Button2 type={filtro ? 'primary' : 'success'} outline='true' onClick={() => this.setState({ filtro: !filtro })}>
              {filtro
                ? <><IconWrapper><FaIcons.FaFilter size={20} /></IconWrapper><span>Da Saldare</span></>
                : <><IconWrapper><FaIcons.FaFilter size={20} /></IconWrapper><span>Saldate</span></>}
            </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnFatture.map((colonne) => {
                    return (
                      <Tcol key={colonne.id}>
                        <b>{colonne.label}</b>
                      </Tcol>
                    )
                  })}
                </Thead>
                <Tbody>
                  {fatture && this.showData().length > 0 && this.showData().map((item) => {
                    return (
                      <Trow key={item._id}>
                        <Tcol>{item.numero}</Tcol>
                        <Tcol>{this.getCliente(item.cliente)}</Tcol>
                        <Tcol>{Config.format(item.importo + item.extraMateriale + item.extraServizi)}</Tcol>
                        <Tcol>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button outline='true' width='60px' margin type={item.saldata === 'true' ? 'success' : 'danger'} onClick={async () => await this.changeStatus(item._id, item.saldata)}>{item.saldata === 'true' ? <FaIcons.FaCheck /> : <FaIcons.FaTimes />}</Button>
                          </div>
                        </Tcol>
                        <Tcol>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button width='60px' margin type='info' outline='true' onClick={() => this.props.navigate('/fatture/modifica', { state: item, replace: false })}><FaIcons.FaEdit size={24} /></Button>
                          </div>
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: { ...item } })} />
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {this.showData().length === 0 && <i>Nessuna fattura presente</i>}
          <BoxInfo showInfoBox={showInfoBox}>
            <BoxContent>
              <Toolbar>
                <ToolbarItem>
                  <CloseButton>
                    <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: false, infoValue: {} })} />
                  </CloseButton>
                </ToolbarItem>
              </Toolbar>
              <ContentStyle.Title>Informazioni:</ContentStyle.Title>
              <div style={{ width: '100%', overflow: 'auto' }}>
                <BoxRow>Numero fattura: {infoValue.numero}</BoxRow>
                <BoxRow>Cliente: {this.getCliente(infoValue.cliente)}</BoxRow>
                <BoxRow>Importo preventivato: {Config.format(infoValue.importo)}</BoxRow>
                <BoxRow>Materiale extra: {Config.format(infoValue.extraMateriale)}</BoxRow>
                <BoxRow>Servizi extra: {Config.format(infoValue.extraServizi)}</BoxRow>
                <BoxRow>Saldata: {infoValue.saldata ? 'Si' : 'No'}</BoxRow>
                <BoxRow>Dettaglio resoconti: </BoxRow>
                {/* dettaglio descrizione tecnico dei resoconti, valutare se tenere */}
                {infoValue.dettaglioResoconto && infoValue.dettaglioResoconto.map((item, i) => {
                  return (
                    <div key={i}>
                      <BoxRow>Tecnico: <i> {this.getTecnico(item.tecnico)}</i></BoxRow>
                      <BoxRow>Descrizione: <i> {item.descrizione}</i></BoxRow>
                      <hr />
                    </div>
                  )
                })}
              </div>
            </BoxContent>
          </BoxInfo>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
