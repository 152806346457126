import React, { Component } from 'react'
import { Button2, IconWrapper } from './Button'
import { Active, Breadcrumb, Delimiter, Navbar, Tools, Tree } from './Navbar'
import { ContentStyle } from '../../Components'
import { LocalStorage } from '../../Services'
import swaller from '../Tools/Swaller'
import { FaDoorOpen } from 'react-icons/fa'
import logo from '../../Assets/logo.svg'

export default class NavbarObj extends Component {
  constructor (props) {
    super(props)
    this.state = {
      navbar: false,
      text: '',
      role: '',
      pageName: '',
      subPageName: '',
      numberPages: 0,
      nome: '',
      lastLogin: ''
    }
  }

  async componentDidMount () {
    this.intervalCheck = setInterval(() => this.check(), 250)
  }

  componentWillUnmount () {
    clearInterval(this.intervalCheck)
  }

  check () {
    let username
    const lastLogin = LocalStorage.get('lastLogin')
    const user = LocalStorage.get('user')
    const { nome, cognome, intestazione } = user || {}
    !nome
      ? username = `${intestazione}`
      : username = `${nome} ${cognome}`
    // NAVBAR ENABLED
    const location = window.location.pathname
    if (location !== '/' && location !== '/firstlogin' && location !== '/firstotp' && location !== '/resetpassword') {
      // BREADCRUMB TEXT
      const page = window.location.pathname.split('/')[1]
      const subPage = window.location.pathname.split('/')[2]
      const pageName = page !== ''
        ? page[0].toUpperCase() + page.slice(1)
        : ''
      const subPageName = subPage && subPage[0].toUpperCase() + subPage.slice(1)
      let numberPages = 0
      if (pageName === 'Home') {
        numberPages = 0
      } else if (pageName !== 'Home' && !subPageName) {
        numberPages = 1
      } else {
        numberPages = 2
      }
      // SET STATE
      this.setState({ nome: username, lastLogin, pageName, subPageName, navbar: true, numberPages })
    } else {
      this.setState({ navbar: false })
    }
  }

  getActive () {
    const { pageName, subPageName, numberPages } = this.state
    if (numberPages === 1) {
      return (
        <>
          <Tree onClick={() => this.props.navigate('/home')}>Homepage</Tree>
          <Delimiter>/</Delimiter>
          <Active>{pageName}</Active>
        </>
      )
    } else if (numberPages === 2) {
      return (
        <>
          <Tree onClick={() => this.props.navigate('/home')}>Homepage</Tree>
          <Delimiter>/</Delimiter>
          <Tree onClick={() => this.props.navigate(`/${pageName.toLowerCase()}`)}>{pageName}</Tree>
          <Delimiter>/</Delimiter>
          <Active>{subPageName}</Active>
        </>
      )
    } else {
      return (
        <>
          <Tree color='true' onClick={() => this.props.navigate('/home')}>Homepage</Tree>
        </>
      )
    }
  }

  async logout () {
    const res = await swaller.question('Confermi di voler effettuare il Logout?')
    if (res) {
      LocalStorage.clear()
      this.props.navigate('/')
    }
  }

  render () {
    const { navbar, nome, lastLogin } = this.state

    const Saluto = ({ nome, lastLogin }) => {
      const ora = new Date().getHours()
      let saluto
      if (ora >= 6 && ora < 12) {
        saluto = 'Buongiorno'
      } else if (ora >= 12 && ora < 18) {
        saluto = 'Buon pomeriggio'
      } else {
        saluto = 'Buonasera'
      }
      const { formattedDate, formattedTime } = formatDateTime(lastLogin)
      return (
        <ContentStyle.SalutoContainer>
          <div style={{ fontSize: '20px' }}>{saluto}, <b>{nome} {saluto === 'Buonasera' ? '🌚' : '🌞'}</b></div>
          <div style={{ fontSize: '16px', color: '#555' }}>Ultimo Login: {formattedDate} - {formattedTime}</div>
        </ContentStyle.SalutoContainer>
      )
    }

    const formatDateTime = (dateTime) => {
      if (!dateTime) {
        return { formattedDate: '', formattedTime: '' }
      }
      const lastLoginDate = new Date(dateTime)
      if (isNaN(lastLoginDate.getTime())) {
        return { formattedDate: '', formattedTime: '' }
      }
      const formattedDate = lastLoginDate.toLocaleString('it-IT', { day: 'numeric', month: 'long', year: 'numeric' })
      const formattedTime = new Intl.DateTimeFormat('it-IT', { hour: 'numeric', minute: '2-digit' }).format(lastLoginDate)
      return { formattedDate, formattedTime }
    }

    return (
      <>
        {navbar &&
          <Navbar>
            <Breadcrumb width='30%'>
              {this.getActive()}
            </Breadcrumb>
            <img style={{ width: '30%', height: '10vh' }} src={logo} alt='logo' />
            <Tools width='30%'>
              <Saluto nome={nome} lastLogin={lastLogin} />
              <Button2 type='danger' outline='true' width='180px' onClick={() => this.logout()}><IconWrapper><FaDoorOpen /></IconWrapper><span>Logout</span> </Button2>
            </Tools>
          </Navbar>}
      </>
    )
  }
}
