import styled from 'styled-components'
import { palette } from '../Library/Tools/Utilities'

const ContentStyle = {}

ContentStyle.Content = styled.div`
  width: ${({ width }) => width || '96vw'};
  height: ${({ height }) => height || '80vh'};
  display: flex;
  flex-flow: wrap;
  flex-direction: ${({ direction }) => direction || 'column'};
  justify-content: ${({ center }) => center || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
  padding-top: ${({ top }) => top || '30px'};
  margin-bottom: ${({ bottom }) => bottom || '0px'};
  gap: ${({ gap }) => gap || '0px'};
  overflow: ${({ overflow }) => overflow || 'hidden'};
`

ContentStyle.Table = styled.div`
  width: 96%;
  display: flex;
  flex-direction: column;
`

ContentStyle.Row = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: ${({ center }) => center || 'space-between'};
  width: ${({ width }) => width || '100%'};
  gap: ${({ gap }) => gap || '0px'};
`

ContentStyle.RowItem = styled.div`
  width: ${({ width }) => width || '45%'};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'center'};
  gap: ${({ gap }) => gap || '0px'};
`

ContentStyle.Box = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

ContentStyle.BoxImmagine = styled.img`
  max-width: 256px;
  width: ${({ width }) => width || 'auto'};
  height: auto;
  object-fit: cover;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px #ABABAB;

  &:hover {
    cursor: ${({ cursor }) => cursor || 'default'};
    transform: scale(1.1);
    transition: transform 0.2s ease;
  }
`

ContentStyle.Label = styled.div`
  white-space: nowrap;
`

ContentStyle.Position = styled.div`
  position: relative;
`

ContentStyle.SelectionBox = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
`

ContentStyle.SelectionList = styled.div`
position: absolute;
top: 100%;
left: 0;
z-index: 100;
background: #fff;
border: 1px solid #ccc;
border-radius: 4px;
margin-top: 4px;
width: 100%;
`

ContentStyle.Text = styled.input`
  width: ${({ width }) => width || 'auto'};
  height: 60px;
  padding: 15px;
  border: 0;
  text-align: ${({ text }) => text || 'left'};
  border-bottom: 2px solid grey;
  background: transparent;

  &:hover{
    cursor: pointer;
    transform: scale(1.1);
    border-radius: 15px;
    border: 3px solid ${palette.danger};
    transition: 0.4s;
  }

  &:focus{
    cursor: text;
    border-bottom: 3px solid ${palette.danger};
    outline: none;
  }
`

ContentStyle.Textarea = styled.textarea`
  width: ${({ width }) => width || '100%'};
  padding: 10px;
`

ContentStyle.Search = styled.input`
  width: ${({ width }) => width || '60px'};
  height: 60px;
  border-radius: 15px;
  border: 1px solid grey;
  padding: 15px;
  transition: all 0.3s ease;

  &:hover, &:focus {
    width: 300px;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.3)
  }
`

ContentStyle.InputAllPage = styled.div`
  width: 95%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 25px;
  gap: 25px;
`

ContentStyle.Select = styled.select`
  min-width: 270px;
  max-width: 270px;
  height: 60px;
  padding: 15px;
  border: 0;
  border-bottom: 2px solid grey;
  background: transparent;

  &:hover{
    cursor: pointer;
    transform: scale(1.07);
    transition: 0.7s;
    border-bottom: 3px solid ${palette.danger};
  }

  &:focus{
    cursor: text;
    border-bottom: 3px solid ${palette.danger};
    outline: none;
  }
`

ContentStyle.TextVariabili = styled.input`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

ContentStyle.Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`

ContentStyle.Form = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  display: flex;
  flex-direction: ${({ flexdirection }) => flexdirection || 'column'};
  justify-content: ${({ justify }) => justify || 'space-around'};
`

ContentStyle.FormColumn = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify || 'center'};
  align-items: center;
  gap: ${({ gap }) => gap || '20px'};
`

ContentStyle.FormRow = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height};
  display: flex;
  flex-flow: row wrap;
  justify-content: ${({ justify }) => justify || 'space-around'};
  align-items: center;
  gap: ${({ gap }) => gap || '0px'};
`

ContentStyle.SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`

ContentStyle.HiddenCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

ContentStyle.Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ccc;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background: white;
  }

  ${ContentStyle.HiddenCheckbox}:checked + & {
    background-color: #2196F3;
  }

  ${ContentStyle.HiddenCheckbox}:checked + &:before {
    transform: translateX(26px);
  }
`

ContentStyle.RoundSlider = styled(ContentStyle.Slider)`
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
`

ContentStyle.Calendario = styled.input`
  padding: 10px;
  border-radius: 15px;
  font-size: 24px;
  outline: none;

  &:hover {
    cursor: pointer;
    border: 2px solid ${palette.primary}
  }
`

ContentStyle.PaginationContainer = styled.div`
  width: 96vw;
  margin-top: 20px;
  display: flex;
  justify-content: center; 
  align-items: center;
  gap: 50px;
`

ContentStyle.PageNumberContainer = styled.div`
  width: 60px;
  height: 60px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: lightgrey;
`

ContentStyle.SalutoContainer = styled.div`
  background: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`

export default ContentStyle
