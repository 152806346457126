const progettiEditInterno = [
  { key: 'titolo', label: 'Titolo', type: 'text' },
  { key: 'settori', label: 'Settori', type: '' },
  { key: 'resoconti', label: 'Resoconti', type: '' }
]

const progettiEditEsterno = [
  { key: 'titolo', label: 'Titolo', type: 'text' },
  { key: 'clienti', label: 'Clienti', type: '' },
  { key: 'settori', label: 'Settori', type: '' },
  { key: 'resoconti', label: 'Resoconti', type: '' }
]

const columnProgetti = [
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'tipo',
    label: 'Tipo'
  },
  {
    id: 'clienti',
    label: 'Clienti'
  },
  {
    id: 'settori',
    label: 'Settori'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  }
]

export { progettiEditInterno, progettiEditEsterno, columnProgetti }
