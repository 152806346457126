import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button } from '../../Library/Components'
import { columnDettaglioOrdini, columnOrdini } from '../TitoliColonne'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { BoxAggiuntaMateriale } from '../../Components/ModalInfo'
import { PdfDownload } from '../../Library/Tools/Pdf'
import { PdfOrdini } from '../../Pdf'
import Loader from '../../Library/Tools/Loader'
import { FaArrowCircleLeft, FaArrowLeft, FaArrowRight, FaEdit, FaFilter, FaInfo, FaShoppingCart, FaTimes } from 'react-icons/fa'

export default class Ordini extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      ordini: [],
      data: [],
      ordine: {},
      content: [],
      showOrder: false,
      showAll: false,
      azienda: '',
      settore: [],
      fornitore: [],
      produttore: [],
      utente: [],
      articoli: [],
      showQrCode: false,
      renderPdf: false
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const produttori = await Api.get('/produttori', { azienda })
    const settori = await Api.get('/settori', { azienda })
    const fornitori = await Api.get('/fornitori', { azienda })
    const utenti = await Api.get('/users', { azienda })
    const articoli = await Api.get('/articoli', { azienda })
    produttori.ok && settori.ok && fornitori.ok && utenti.ok && articoli.ok
      ? this.setState({ azienda, produttore: produttori.data, settore: settori.data, fornitore: fornitori.data, utente: utenti.data, articoli: articoli.data })
      : swaller.error('Non è stato possibile recuperare i dati')
    setTimeout(async () => await this.loadData(false), 100)
  }

  async loadData (showAll) {
    const { azienda } = this.state
    const ordini = showAll
      ? await Api.get('/ordini', { azienda, ordinato: true })
      : await Api.get('/ordini', { azienda, ordinato: false })
    ordini.ok
      ? this.setState({ data: ordini.data, ordini: ordini.data, showAll, loading: false })
      : swaller.error('Non è stato possibile recuperare i dati')
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageOrdini = data.slice(startIndex, endIndex)
    return currentPageOrdini
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  searchName (text) {
    const { ordini } = this.state
    text === ''
      ? this.setState({ data: ordini })
      : this.setState({ data: ordini.filter((ordine) => ordine.nOrdine.toString().includes(text)) })
  }

  async changeStatus (id, status) {
    const { showAll } = this.state
    const response = await swaller.question('Vuoi modificare lo stato dell ordine?')
    if (response) {
      swaller.info('Modifica stato ordine in corso')
      await Api.patch(`/ordini/${id}`, {
        ordinato: status,
        dataOraEvasione: status === 'true' ? new Date().toISOString() : '',
        ordinatoDa: status === 'true' ? LocalStorage.get('user')._id : ''
      })
      swaller.success('Ordine modificato con successo')
      await this.loadData(showAll)
    }
  }

  handleKeyDown (event) {
    if (event.keyCode === 13) {
      this.searchName()
    }
  }

  getInfo (_id, value, field) {
    const items = this.state[value]
    let text = ''
    text = items.find(item => item._id === _id)
    if (field) {
      return text ? text[field] : ''
    } else {
      return text ? text[value] : ''
    }
  }

  pdfExport (item) {
    const { articoli } = this.state // produttore, settore, fornitore
    const data = { ...item }
    data.produttori = this.getInfo(articoli.find(obj => obj._id === item.item).produttore, 'produttore')
    data.settore = this.getInfo(articoli.find(obj => obj._id === item.item).settore, 'settore')
    data.fornitore = this.getInfo(articoli.find(obj => obj._id === item.item).fornitore, 'fornitore')
    return data
  }

  render () {
    const { data, ordini, ordine, showOrder, showAll, articoli, loading, currentPage, itemPerPage } = this.state
    const totalPages = Math.ceil(ordini.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca per numero ordine' onChange={(e) => this.searchName(e.target.value)} />
            <Button type={showAll ? 'primary' : 'success'} outline='true' onClick={async () => await this.loadData(!showAll)}>
              {showAll
                ? <><FaFilter size={20} /> Ordini evasi</>
                : <><FaFilter size={20} /> Ordini da evadere</>}
            </Button>
            <Button type='primary' outline='true' onClick={() => this.props.navigate('/carrello', { state: 'creazione ordine', replace: false })}><FaShoppingCart />Carrello</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  <Trow>
                    {
                      columnOrdini.map((colonne, i) => {
                        return (
                          <Tcol key={i}>
                            <b>{colonne.label}</b>
                          </Tcol>
                        )
                      })
                    }
                  </Trow>
                </Thead>
                <Tbody>
                  {ordini.length > 0 && this.showData().map((ordine, i) => {
                    return (
                      <Trow key={i}>
                        <Tcol>{ordine.nOrdine}</Tcol>
                        <Tcol>{ordine.articoli ? ordine.articoli.length : 0}</Tcol>
                        <Tcol>{new Date(ordine.dataOraCreazione).toLocaleString('it-IT')}</Tcol>
                        <Tcol>{this.getInfo(ordine.creatore, 'utente', 'nome') ? this.getInfo(ordine.creatore, 'utente', 'nome') : ''}</Tcol>
                        <Tcol style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button type={ordine.ordinato === 'true' ? 'success' : 'danger'} onClick={async () => await this.changeStatus(ordine._id, ordine.ordinato === 'true' ? 'false' : 'true')}>{ordine.ordinato === 'true' ? 'SI' : 'NO'}</Button>
                        </Tcol>
                        <Tcol>{this.getInfo(ordine.ordinatoDa, 'utente', 'nome') ? this.getInfo(ordine.ordinatoDa, 'utente', 'nome') : '-'}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ ordine, showOrder: true })} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/ordini/modificacarrello', { state: ordine, replace: false })} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          {false && PdfDownload(PdfOrdini(this.pdfExport(ordine), `Ordine_${ordine.nOrdine}`))}
                          PDF
                        </Tcol>
                        <Tcol style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button type={ordine.manualOrder ? 'success' : 'warning'}>{ordine.manualOrder ? 'Manual' : 'Automatic'}</Button>
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun ordine presente</i>}
          <BoxAggiuntaMateriale show={showOrder}>
            <Toolbar>
              <ToolbarItem>
                <h3>Elenco articoli ordine N. {ordine.nOrdine}</h3>
              </ToolbarItem>
              <ToolbarItem isend='true'>
                <FaTimes size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showOrder: false })} />
              </ToolbarItem>
            </Toolbar>
            <br />
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnDettaglioOrdini.map((colonne, i) => {
                    return (
                      <Tcol key={i}>
                        <b>{colonne.label}</b>
                      </Tcol>
                    )
                  })}
                </Thead>
                <Tbody>
                  {ordine && ordine.articoli && ordine.articoli.map((articolo, i) =>
                    <Trow key={i}>
                      <Tcol>{articoli.find(obj => obj._id === articolo.item).titolo}</Tcol>
                      <Tcol>{articoli.find(obj => obj._id === articolo.item).referenza}</Tcol>
                      <Tcol>{articolo.qta}</Tcol>
                      <Tcol>{this.getInfo(articoli.find(obj => obj._id === articolo.item).produttore, 'produttore')}</Tcol>
                      <Tcol>{this.getInfo(articoli.find(obj => obj._id === articolo.item).fornitore, 'fornitore')}</Tcol>
                      <Tcol>{this.getInfo(articoli.find(obj => obj._id === articolo.item).settore, 'settore')}</Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
            </ContentStyle.Table>
          </BoxAggiuntaMateriale>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
