import styled from 'styled-components'

export const BoxInfo = styled.div`
  height: 80vh;
  width: 70vh;
  position: absolute;
  z-index: 999;
  top: 15vh;
  left: 32vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  border-radius: 20px;
  border: 2px solid grey;
  box-shadow: 0 0 20px grey;
  background-color: white;
  visibility: ${({ showInfoBox }) => showInfoBox ? 'visible' : 'hidden'};
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.25);
`

export const BoxQr = styled.div`
  height: 60vh;
  width: 70vh;
  position: absolute;
  z-index: 999;
  top: 15vh;
  left: 32vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  border-radius: 20px;
  border: 2px solid grey;
  box-shadow: 0 0 20px grey;
  background-color: white;
  visibility: ${({ showInfoBox }) => showInfoBox ? 'visible' : 'hidden'};
`

export const BoxAggiuntaMateriale = styled.div`
  height: 90vh;
  width: 90vw;
  position: absolute;
  z-index: 998;
  top: 5vh;
  left: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 2px solid grey;
  padding: 20px;
  box-shadow: 0 0 20px grey;
  background-color: white;
  visibility: ${({ show }) => show || 'hidden'};
`

export const BoxContent = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: -60px;
  gap: 20px;
`

export const BoxRow = styled.div`
  width: 90%;
  height: 6vh;
  display: flex;
  flex-direction: column;
  gap:10px;
`

export const BoxRowQr = styled.div`
  width: 90%;
  height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`
