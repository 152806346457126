import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import Loader from '../../Library/Tools/Loader'
import { mockupEdit } from './MockupRichieste'

export default class NuovaRichiesta extends Component {
  constructor (props) {
    super(props)
    this.state = mockupEdit
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    try {
      const clienti = await Api.get('/users', { azienda, role: 'cliente' })
      const settori = await Api.get('/settori', { azienda })
      const tecnici = await Api.get('/users', { azienda, role: 'utente' })
      const installazioni = await Api.get('/installazioni', { azienda })
      if (clienti.ok && settori.ok && tecnici.ok && installazioni.ok) {
        this.setState({ clienti: clienti.data, settori: settori.data, tecnici: tecnici.data, installazioni: installazioni.data, azienda, loading: false })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Impossibile caricare i dati. Riprovare')
    }
  }

  async salvaRichiesta () {
    const { titolo, cliente, settore, tecnico, urgenza, descrizione, azienda, installazione } = this.state
    const res = await Api.post('/richieste', {
      titolo,
      cliente,
      installazione,
      settore,
      tecnico,
      urgenza,
      descrizione,
      dataOra: new Date().toISOString(),
      stato: tecnico !== '...' ? '1' : '0',
      azienda
    })
    if (res.ok) {
      swaller.success('Richiesta salvata correttamente!')
      setTimeout(() => this.props.navigate('/richieste'), 2000)
    } else {
      swaller.error('Errore durante il savataggio della richiesta, prego riprovare!')
    }
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  getTecnico (_id) {
    const { tecnici } = this.state
    const tecnico = tecnici.find(tecnico => tecnico._id === _id)
    return tecnico ? `${tecnico.nome} ${tecnico.cognome}` : ''
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/richieste')
    }
  }

  render () {
    const { loading, titolo, cliente, urgenza, descrizione, clienti, tecnici, settori, installazioni } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.salvaRichiesta()}>Salva Richiesta</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form width='70%'>
            <ContentStyle.Row center='space-around'>
              <ContentStyle.RowItem gap='20px'>
                <ContentStyle.Label>Cliente: </ContentStyle.Label>
                <ContentStyle.Select value={cliente} onChange={(e) => this.setState({ cliente: e.target.value, installazione: '' })}>
                  <option value=''>...</option>
                  {clienti.length > 0 && clienti.map((item, i) =>
                    <option value={item._id} key={i}>
                      {this.getCliente(item._id)}
                    </option>
                  )}
                </ContentStyle.Select>
              </ContentStyle.RowItem>
              {cliente !== '' &&
                <ContentStyle.RowItem gap='20px'>
                  <ContentStyle.Label>Installazione: </ContentStyle.Label>
                  <ContentStyle.Select onChange={(e) => this.setState({ installazione: e.target.value })}>
                    <option value=''>...</option>
                    {installazioni.length > 0 && installazioni.filter(e => e.cliente === cliente).map((item, i) =>
                      <option value={item._id} key={i}>
                        {`${item.codiceInstallazione} - ${item.indirizzo}`}
                      </option>
                    )}
                  </ContentStyle.Select>
                </ContentStyle.RowItem>}
            </ContentStyle.Row>
            <ContentStyle.Row center='center' gap='20px'>
              <ContentStyle.Label>Settore: </ContentStyle.Label>
              <ContentStyle.Select onChange={(e) => this.setState({ settore: e.target.value })}>
                <option value=''>...</option>
                {settori.length > 0 && settori.map((item, i) =>
                  <option value={item._id} key={i}>
                    {item.settore}
                  </option>
                )}
              </ContentStyle.Select>
            </ContentStyle.Row>
            <ContentStyle.Row center='center' gap='20px'>
              <ContentStyle.Label>Tecnico: </ContentStyle.Label>
              <ContentStyle.Select onChange={(e) => this.setState({ tecnico: e.target.value })}>
                <option value=''>...</option>
                {tecnici.length > 0 && tecnici.map((item, i) =>
                  <option value={item._id} key={i}>
                    {item.nome} {item.cognome}
                  </option>
                )}
              </ContentStyle.Select>
            </ContentStyle.Row>
            <ContentStyle.Row center='center' gap='20px'>
              <ContentStyle.Label center='center'>Urgenza: </ContentStyle.Label>
              <ContentStyle.Select placeholder='urgenza' value={urgenza} onChange={(e) => this.setState({ urgenza: e.target.value })}>
                <option value=''>...</option>
                <option value='bassa'>Bassa</option>
                <option value='media'>Media</option>
                <option value='alta'>Alta</option>
              </ContentStyle.Select>
            </ContentStyle.Row>
            <ContentStyle.Row center='center' gap='20px'>
              <ContentStyle.Label>Titolo: </ContentStyle.Label>
              <ContentStyle.Text type='text' placeholder='Titolo richiesta' value={titolo} onChange={(e) => this.setState({ titolo: e.target.value })} />
            </ContentStyle.Row>
            <ContentStyle.Row center='center' gap='20px'>
              <ContentStyle.Label>Descrizione: </ContentStyle.Label>
              <ContentStyle.Text type='text' placeholder='Descrizione' value={descrizione} onChange={(e) => this.setState({ descrizione: e.target.value })} />
            </ContentStyle.Row>
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
