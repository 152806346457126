import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, BoxInfo, BoxContent, BoxRow } from '../../Components'
import { Button, CloseButton } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnResocontiPage } from '../TitoliColonne'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'
export default class Resoconti extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      richieste: [],
      data: [],
      preventivi: [],
      resoconti: [],
      content: [],
      showInfoBox: false,
      clienti: [],
      tecnici: [],
      azienda: '',
      utenti: [],
      defaultUser: ''
    }
  }

  async componentDidMount () {
    const { azienda, _id } = LocalStorage.get('user')
    this.setState({ azienda, defaultUser: _id }, async () => {
      await this.filterStatus(_id)
      await this.loadData()
    })
  }

  async loadData () {
    const { azienda } = this.state
    const tecnici = await Api.get('/users', { azienda, role: 'utente' })
    const clienti = await Api.get('/users', { azienda, role: 'cliente' })
    const utenti = await Api.get('/users', { azienda })
    if (tecnici.ok && clienti.ok && utenti.ok) {
      this.setState({ tecnici: tecnici.data, clienti: clienti.data, utenti: utenti.data, loading: false })
    } else {
      swaller.error('Non è stato possibile recuperare i dati')
    }
  }

  infoBox (stato, item) {
    this.setState({ showInfoBox: stato, content: item })
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  getTecnico (items) {
    const { tecnici } = this.state
    const tecniciFiltrati = tecnici.filter(tecnico => items.includes(tecnico._id))
    const nomiTecnici = tecniciFiltrati.map(tecnico => `${tecnico.nome} ${tecnico.cognome}`)
    return nomiTecnici.join(', ')
  }

  getUtente (_id) {
    const { utenti } = this.state
    const utente = utenti.find((utente) => utente._id === _id)
    return utente ? `${utente.nome} ${utente.cognome}` : ''
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageResoconti = data.slice(startIndex, endIndex)
    return currentPageResoconti
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  filter (search, value) {
    const { data } = this.state
    search === ''
      ? this.setState({ resoconti: data })
      : this.setState({ resoconti: data.filter(item => this.getCliente(item[value]).toLowerCase().includes(search.toLowerCase())) })
  }

  async filterStatus (value) {
    const { azienda } = this.state
    this.setState({ loading: true })
    const resoconti = value === ''
      ? await Api.get('/resoconti', { azienda })
      : await Api.get('/resoconti', { azienda, tecnico: value })
    resoconti.ok
      ? this.setState({ resoconti: resoconti.data, data: resoconti.data, defaultUser: value, loading: false })
      : swaller.error('Impossibile caricare i resoconti!')
  }

  render () {
    const { data, currentPage, itemPerPage, loading, resoconti, settori, showInfoBox, content, defaultUser, tecnici } = this.state
    const totalPages = Math.ceil(resoconti.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca per cliente...' onChange={(e) => this.filter(e.target.value, 'cliente')} />
            <ContentStyle.Select value={defaultUser} onChange={(e) => this.filterStatus(e.target.value)}>
              <option value=''>Tutti</option>
              {tecnici && tecnici.length > 0 && tecnici.map((item, i) => {
                return (
                  <option key={i} value={item._id}>{`${item.nome} ${item.cognome}`}</option>
                )
              })}
            </ContentStyle.Select>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnResocontiPage.map((colonne, i) => {
                    return (
                      <Tcol key={i}>
                        {colonne.label === 'Settore'
                          ? (
                            <ContentStyle.Select onChange={(e) => this.sectorFilter(e.target.value)}>
                              <option value='settore'>Tutti i settori</option>
                              {
                                settori && settori.length > 0 && settori.map((item, i) => {
                                  return (
                                    <option value={item._id} key={i}>
                                      {item.settore}
                                    </option>
                                  )
                                })
                              }
                            </ContentStyle.Select>
                            )
                          : <b>{colonne.label}</b>}
                      </Tcol>
                    )
                  })}
                </Thead>
                <Tbody>
                  {resoconti && resoconti.length > 0 && this.showData().map((item, i) => {
                    return (
                      <Trow key={i}>
                        <Tcol>{item.tipo}</Tcol>
                        <Tcol>{this.getTecnico(item.tecnico)}</Tcol>
                        <Tcol>{this.getCliente(item.cliente)}</Tcol>
                        <Tcol>{new Date(item.dataInizio).toLocaleString('it-IT')}</Tcol>
                        <Tcol>{new Date(item.dataFine).toLocaleString('it-IT')}</Tcol>
                        <Tcol>{item.descrizione}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/resoconti/modifica', { state: item, replace: false })} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.infoBox(true, item)} />
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun resoconto presente</i>}
          <BoxInfo showInfoBox={showInfoBox}>
            <BoxContent>
              <Toolbar>
                <ToolbarItem>
                  <CloseButton>
                    <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: false, infoValue: {} })} />
                  </CloseButton>
                </ToolbarItem>
              </Toolbar>
              <ContentStyle.Title>Descrizione:</ContentStyle.Title>
              <div style={{ width: '100%', overflow: 'auto' }}>
                <BoxRow>Tipologia: {content.tipo}</BoxRow>
                <BoxRow>Titolo: {content.titolo}</BoxRow>
                <BoxRow>Cliente: {this.getCliente(content.cliente)}</BoxRow>
                <BoxRow>Tecnico: {content.tecnico && this.getTecnico(content.tecnico)}</BoxRow>
                <ContentStyle.Row style={{ display: 'flex', alignItems: 'center', gap: 10 }}>Descrizione: <ContentStyle.Textarea disabled value={content.descrizione} /></ContentStyle.Row>
                <br />
                <BoxRow>Creato da: {this.getUtente(content.creatoDa)}</BoxRow>
              </div>
            </BoxContent>
          </BoxInfo>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
