import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import Loader from '../../Library/Tools/Loader'
import { mockupServizi } from './mockupServizi'

export default class NuovoServizio extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      costoOrario: '',
      dataOra: '',
      settori: [],
      azienda: ''
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    this.setState({ azienda })
    try {
      const settori = await Api.get('/settori', { azienda })
      if (settori.ok) {
        this.setState({ settori: settori.data, loading: false })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Errore durante il recupero dei dati')
    }
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  async salvaServizio () {
    const { azienda, titolo, costoOrario, settore, descrizione } = this.state
    if (!titolo || !settore || costoOrario === '' || isNaN(parseFloat(costoOrario))) {
      swaller.error('Compilare tutti i campi obbligatori e inserire un costo orario valido.')
    } else {
      try {
        const costoOrarioValue = parseFloat(costoOrario)
        if (costoOrarioValue < 0) {
          swaller.error('Il costo orario non può essere inferiore a 0, ricontrollare.')
          return
        }
        swaller.info('Salvataggio in corso...')
        const res = await Api.post('/servizi', {
          titolo,
          costoOrario: costoOrarioValue,
          settore,
          descrizione,
          dataOra: new Date().toISOString(),
          azienda
        })
        if (res.ok) {
          swaller.success('Servizio salvato correttamente!')
          setTimeout(() => this.props.navigate('/servizi'), 2000)
        } else {
          swaller.error('Errore durante la creazione del servizio, prego riprovare!')
        }
      } catch (error) {
        swaller.error('Errore durante la creazione del servizio, prego riprovare!')
      }
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/servizi')
    }
  }

  render () {
    const { loading, settori } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.salvaServizio()}>Crea servizio</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Title>Fornire le indicazioni seguenti: </ContentStyle.Title>
        <ContentStyle.Content>
          <ContentStyle.Form>
            <ContentStyle.Row center='center'>
              <ContentStyle.FormColumn width='auto'>
                {mockupServizi.map((field) => (
                  <ContentStyle.Row width='65%' key={field.key}>
                    <ContentStyle.Label>{field.label}:</ContentStyle.Label>
                    <ContentStyle.Text
                      type={field.type}
                      placeholder={field.label.toLowerCase()}
                      value={this.state[field.key]}
                      onChange={(e) => this.setState({ [field.key]: e.target.value })}
                    />
                  </ContentStyle.Row>
                ))}
                <ContentStyle.Row width='65%'>
                  <ContentStyle.Label>Settori:</ContentStyle.Label>
                  <ContentStyle.Select onChange={(e) => this.setState({ settore: e.target.value })}>
                    <option value=''>...</option>
                    {settori.length > 0 && settori.map((item, i) => (
                      <option value={item._id} key={i}>
                        {item.settore}
                      </option>
                    ))}
                  </ContentStyle.Select>
                </ContentStyle.Row>
              </ContentStyle.FormColumn>
            </ContentStyle.Row>
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
