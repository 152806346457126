import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { Config } from '../../Global'
import Loader from '../../Library/Tools/Loader'
import { columnServizi } from './mockupServizi'

export default class Servizi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      servizi: [],
      data: [],
      settori: []
    }
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      const serviziRes = await Api.get('/servizi', { azienda })
      if (!serviziRes.ok) {
        swaller.error('Non è stato possibile recuperare i servizi')
      } else {
        const settoriRes = await Api.get('/settori', { azienda })
        if (settoriRes.ok) {
          this.setState({ servizi: serviziRes.data, data: serviziRes.data, settori: settoriRes.data, loading: false })
        } else {
          swaller.error('Non è stato possibile recuperare i dati')
        }
      }
    } catch (error) {
      swaller.error('Errore durante il recupero dei dati:')
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageServizi = data.slice(startIndex, endIndex)
    return currentPageServizi
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  filter (search) {
    const { servizi } = this.state
    search === ''
      ? this.setState({ data: servizi })
      : this.setState({ data: servizi.filter(item => item.titolo.toLowerCase().includes(search.toLowerCase())) })
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  render () {
    const { currentPage, itemPerPage, loading, servizi, data } = this.state
    const totalPages = Math.ceil(servizi.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value)} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/servizi/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuovo Servizio</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnServizi.map((colonna) => {
                    return (
                      <Tcol key={colonna.id}>
                        <b>{colonna.label}</b>
                      </Tcol>
                    )
                  })}
                </Thead>
                <Tbody>
                  {servizi.length > 0 && this.showData().map((item) => (
                    <Trow key={item._id}>
                      <Tcol>{item.titolo}</Tcol>
                      <Tcol>{item.settore && this.getSettore(item.settore)}</Tcol>
                      <Tcol>{Config.format(item.costoOrario)}</Tcol>
                      <Tcol>{item.descrizione}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/servizi/modifica', { state: item, replace: false })} />
                      </Tcol>
                    </Trow>
                  ))}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun servizio presente</i>}
        </ContentStyle.Content>
      </Loader>
    )
  }
}
