const mockupFornitori = [
  { key: 'fornitore', label: 'Fornitore', type: 'text' },
  { key: 'pIva', label: 'Partita IVA', type: 'text' },
  { key: 'pec', label: 'Pec', type: 'text' },
  { key: 'codiceUnivoco', label: 'Codice univoco', type: 'text' },
  { key: 'email', label: 'Email', type: 'text' },
  { key: 'telefono', label: 'Telefono', type: 'text' },
  { key: 'indirizzo', label: 'Indirizzo', type: 'text' },
  { key: 'citta', label: 'Città', type: 'text' },
  { key: 'cap', label: 'CAP', type: 'text' },
  { key: 'nazione', label: 'Nazione', type: 'text' }
]

const formFornitori = [
  {
    id: 'fornitore',
    label: 'Fornitore'
  },
  {
    id: 'pIva',
    label: 'Partita IVA'
  },
  {
    id: 'pec',
    label: 'Pec'
  },
  {
    id: 'codiceUnivoco',
    label: 'Codice univoco'
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'telefono',
    label: 'Telefono'
  },
  {
    id: 'indirizzo',
    label: 'Indirizzo'
  },
  {
    id: 'citta',
    label: 'Città'
  },
  {
    id: 'cap',
    label: 'CAP'
  },
  {
    id: 'nazione',
    label: 'Nazione'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  }
]

export { mockupFornitori, formFornitori }
