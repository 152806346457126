import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { Config } from '../../Global'
import Loader from '../../Library/Tools/Loader'
import { columnCanoni } from './canoniMockup'

export default class Canoni extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      azienda: '',
      ricerca: '',
      loading: true,
      canoni: [],
      clienti: [],
      data: []
    }
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      const canoni = await Api.get('/canoni', { azienda })
      const clienti = await Api.get('/users', { azienda, role: 'cliente' })
      canoni.ok && clienti.ok
        ? this.setState({ azienda, canoni: canoni.data, data: canoni.data, clienti: clienti.data, loading: false })
        : swaller.error('Non è stato possibile recuperare i dati. Riprovare più tardi')
    } catch (error) {
      swaller.error('Non è stato possibile recuperare i dati. Riprovare più tardi')
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageCanoni = data.slice(startIndex, endIndex)
    return currentPageCanoni
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  searchName (nome) {
    const { canoni } = this.state
    nome === ''
      ? this.setState({ data: canoni })
      : this.setState({ data: canoni.filter(item => item.cliente.includes(nome)) })
  }

  render () {
    const { data, loading, canoni, ricerca, currentPage, itemPerPage } = this.state
    const totalPages = Math.ceil(canoni.length / itemPerPage)

    return (
      <>
        <Loader value={loading}>
          <Toolbar>
            <ToolbarItem>
              <Breadcrumb.Container>
                <Breadcrumb.Crumb>
                  <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
                </Breadcrumb.Crumb>
              </Breadcrumb.Container>
            </ToolbarItem>
            <ToolbarItem gap='20px' isend='true'>
              <ContentStyle.Search type='text' value={ricerca} placeholder='🔍 Ricerca...' onChange={(e) => this.searchName(this.getCliente(e.target.value))} />
              <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/canoni/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuovo Canone</span> </Button2>
            </ToolbarItem>
          </Toolbar>
          <ContentStyle.Content>
            {this.showData().length > 0 &&
              <ContentStyle.Table>
                <Table>
                  <Thead>
                    {columnCanoni.map((colonna) =>
                      <Tcol key={colonna.key}>
                        <b>{colonna.label}</b>
                      </Tcol>
                    )}
                  </Thead>
                  <Tbody>
                    {this.showData().map((item, i) =>
                      <Trow key={i}>
                        <Tcol>{this.getCliente(item.cliente)}</Tcol>
                        <Tcol>{item.descrizione}</Tcol>
                        <Tcol>{Config.format(item.importo)}</Tcol>
                        <Tcol>{item.canone}</Tcol>
                        <Tcol>{item.scadenza}</Tcol>
                        <Tcol><FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/canoni/modifica', { state: item, replace: false })} /></Tcol>
                      </Trow>
                    )}
                  </Tbody>
                </Table>
                <ContentStyle.PaginationContainer>
                  <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                  <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                  <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
                </ContentStyle.PaginationContainer>
              </ContentStyle.Table>}
            {data.length === 0 && <i>Non ci sono canoni</i>}
          </ContentStyle.Content>
        </Loader>
      </>
    )
  }
}
