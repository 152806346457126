import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'
import { mockupRicavi } from './MockupRicavi'

export default class Ricavi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 4,
      loading: true,
      ricavi: [],
      data: [],
      settori: [],
      settore: '',
      azienda: ''
    }
  }

  async componentDidMount () {
    const id = LocalStorage.get('_id')
    try {
      const user = await Api.get(`/users/${id}`)
      const { azienda } = user.data
      const settori = await Api.get('/settori', { azienda })
      const ricavi = await Api.get('/ricavi', { azienda })
      if (settori.data && ricavi.data) {
        this.setState({ settori: settori.data, ricavi: ricavi.data, data: ricavi.data, loading: false })
      }
    } catch (error) {
      swaller.error('Errore durante il caricamento dei dati')
      this.setState({ loading: false })
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageRicavi = data.slice(startIndex, endIndex)
    return currentPageRicavi
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  sectorFilter (settore) {
    const { ricavi } = this.state
    settore === 'settore'
      ? this.setState({ data: ricavi })
      : this.setState({ data: ricavi.filter(item => item.settore.includes(settore)) })
  }

  render () {
    const { data, currentPage, itemPerPage, loading, ricavi, settori } = this.state
    const totalPages = Math.ceil(ricavi.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <ContentStyle.Select onChange={(e) => this.sectorFilter(e.target.value)}>
              <option value='settore'>Tutti i settori</option>
              {settori.length > 0 && settori.map((item, j) =>
                <option value={item._id} key={j}>{item.settore}</option>)}
            </ContentStyle.Select>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {mockupRicavi.map((colonna, i) =>
                    <Tcol key={i}><b>{colonna}</b></Tcol>
                  )}
                </Thead>
                <Tbody>
                  {ricavi.length > 0 && this.showData().map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{this.getSettore(item.settore)}</Tcol>
                      <Tcol>{item.tipo}</Tcol>
                      <Tcol>{item.ricarico} %</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/ricavi/modifica', { state: item, replace: false })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun ricavo presente</i>}
        </ContentStyle.Content>
      </Loader>
    )
  }
}
