import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, Modal, ModalIcon, ModalTitle, ModalContent, ModalLabel } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import * as FaIcons from 'react-icons/fa'
import { columnClienti, mockupAzienda, mockupPrivato } from './MockupClienti'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'

export default class Clienti extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      clienti: [],
      data: [],
      content: [],
      azienda: '',
      categoria: '',
      show: 'hidden',
      infoValue: {}
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const clientiResponse = await Api.get('/users', { azienda, role: 'cliente' })
    if (clientiResponse.ok) this.setState({ clienti: clientiResponse.data, data: clientiResponse.data, loading: false })
    else swaller.error('Non è stato possibile recuperare i clienti')
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageClienti = data.slice(startIndex, endIndex)
    return currentPageClienti
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  filter (search) {
    const { clienti } = this.state
    search === ''
      ? this.setState({ data: clienti })
      : this.setState({ data: clienti.filter(item => this.getCliente(item._id).toLowerCase().includes(search.toLowerCase())) })
  }

  render () {
    const { data, currentPage, itemPerPage, loading, clienti, show, infoValue } = this.state
    const totalPages = Math.ceil(clienti.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value)} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/clienti/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuovo cliente</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnClienti.map((colonna) =>
                    <Tcol key={colonna.id}>
                      <b>{colonna.label}</b>
                    </Tcol>
                  )}
                </Thead>
                <Tbody>
                  {this.showData().map((cliente, i) =>
                    <Trow key={i}>
                      {cliente.isPrivate === '0'
                        ? <Tcol>{cliente.intestazione}</Tcol>
                        : <Tcol>{cliente.nome} {cliente.cognome}</Tcol>}
                      <Tcol>{cliente.email}</Tcol>
                      <Tcol>{cliente.telefono}</Tcol>
                      {cliente.isPrivate === '0'
                        ? <Tcol>Azienda</Tcol>
                        : <Tcol>Privato</Tcol>}
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ show: 'visible', infoValue: cliente })} />
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/clienti/modifica', { state: cliente, replace: false })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun cliente trovato</i>}
          <Modal visible={show}>
            <ModalIcon onClick={() => this.setState({ show: 'hidden' })}><FaIcons.FaTimes size={48} /></ModalIcon>
            <ModalTitle>Informazioni</ModalTitle>
            <ModalContent>
              {infoValue.isPrivate === '0'
                ? mockupAzienda.map((azienda) =>
                  <ModalLabel key={azienda.key}>
                    <b>{azienda.label.toUpperCase()}:</b> {infoValue[azienda.key]}
                  </ModalLabel>)
                : mockupPrivato.map((privato) =>
                  <ModalLabel key={privato.key}>
                    <b>{privato.label.toUpperCase()}:</b> {infoValue[privato.key]}
                  </ModalLabel>
                )}
            </ModalContent>
          </Modal>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
