import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api } from '../../Services'
import { mockupAzienda } from './MockupAzienda'

const DEFAULT_STATE = {
  intestazione: '',
  pIva: '',
  pec: '',
  codiceUnivoco: '',
  email: '',
  telefono: '',
  indirizzo: '',
  citta: '',
  cap: '',
  nazione: '',
  role: 'azienda',
  isFirstLogin: true,
  otp: false,
  abilitato: true,
  password: 'password', // default password,
  sezioni: ['Utenti', 'Settaggi']
}

export default class Aziende extends Component {
  constructor (props) {
    super(props)
    this.state = DEFAULT_STATE
  }

  async salvaAzienda () {
    const { intestazione, pIva, pec, codiceUnivoco, email, telefono, indirizzo, citta, cap, nazione, abilitato, role, isFirstLogin, otp, password, sezioni } = this.state
    if (!intestazione || !email || !pIva || !pec || !indirizzo || !citta || !cap) {
      swaller.error('Compilare i campi obbligatori e riprovare!')
    } else {
      swaller.info('Salvataggio in corso...')
      const res = await Api.post('/users', {
        intestazione,
        pIva,
        pec,
        codiceUnivoco,
        email,
        telefono,
        indirizzo,
        citta,
        cap,
        nazione,
        role,
        isFirstLogin,
        otp,
        abilitato,
        password,
        sezioni
      })
      if (res.ok) {
        swaller.success('Azienda salvata correttamente!')
        setTimeout(() => this.props.navigate('/aziende'))
      } else {
        swaller.error('Errore durante la creazione azienda, prego riprovare!')
      }
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/aziende')
    }
  }

  render () {
    const dimensioneParte = Math.ceil(mockupAzienda.length / 3)

    return (
      <>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.salvaAzienda()}><FaIcons.FaCheck />Salva Azienda</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form flexdirection='row' width='80%'>
            {Array.from({ length: 3 }, (_, indiceColonna) => (
              <ContentStyle.FormColumn key={indiceColonna}>
                {mockupAzienda.slice(indiceColonna * dimensioneParte, (indiceColonna + 1) * dimensioneParte).map((field) => (
                  <ContentStyle.Row width='50%' key={field.key}>
                    <ContentStyle.Label>{field.label}:</ContentStyle.Label>
                    <ContentStyle.Text
                      type={field.type}
                      placeholder={field.label.toLowerCase()}
                      value={this.state[field.key]}
                      onChange={(e) => this.setState({ [field.key]: e.target.value })}
                    />
                  </ContentStyle.Row>
                ))}
              </ContentStyle.FormColumn>
            ))}
          </ContentStyle.Form>
        </ContentStyle.Content>
      </>
    )
  }
}
