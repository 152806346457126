import React, { Component } from 'react'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import { LoginFormButton, Loginbox, Logo, LoginLabel } from '../../Components/Layout'
import { FaArrowLeft, FaUser } from 'react-icons/fa'
import logo from '../../Assets/tecnel.jpg'

export default class FirstLogin extends Component {
  constructor (props) {
    super(props)
    this.state = {
      current: '',
      newPassword: '',
      checkNewPassword: ''
    }
  }

  async resetPassword () {
    const { current, newPassword, checkNewPassword } = this.state
    const { email } = LocalStorage.get('user')
    const _id = LocalStorage.get('_id')
    const logres = await Api.login(email, current)
    if (!logres) {
      swaller.error('La password corrente non è corretta')
    } else if (newPassword === 'password') {
      swaller.error('La nuova password non può essere password')
    } else if (newPassword === checkNewPassword) {
      const res = await Api.patch(`/users/${_id}`, { password: newPassword, isFirstLogin: false })
      if (res.ok) {
        swaller.success('La password è stata modificata con successo')
        this.props.navigate('/firstotp')
      }
    } else {
      swaller.error('Le password inserite non corrispondono')
    }
  }

  async handleKeyDown (event) {
    if (event.keyCode === 13) {
      await this.resetPassword()
    }
  }

  render () {
    const { current, newPassword, checkNewPassword } = this.state
    return (
      <>
        <Loginbox>
          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><FaArrowLeft style={{ cursor: 'pointer' }} onClick={() => this.props.navigate('/')} /></div>
          <Logo src={logo} />
          <br />
          <div style={{ fontSize: '24px', fontWeight: 'bold' }}><FaUser /> Cambio Password</div>
          <LoginLabel type='password' placeholder='Password corrente' value={current} onChange={(e) => this.setState({ current: e.target.value })} />
          <LoginLabel type='password' placeholder='Nuova password' value={newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
          <LoginLabel type='password' placeholder='Ripeti nuova password' onKeyDown={e => this.handleKeyDown(e)} value={checkNewPassword} onChange={(e) => this.setState({ checkNewPassword: e.target.value })} />
          <LoginFormButton type='primary' outline='true' onClick={async () => await this.resetPassword()}>Salva</LoginFormButton>
        </Loginbox>
      </>
    )
  }
}
