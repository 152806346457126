const mockupRicavi = ['Settore', 'Tipo', 'Ricarico', 'Modifica']
const selectType = [
  {
    key: 'default',
    value: '',
    label: '',
    isDisabled: true
  },
  {
    key: 'servizi',
    value: 'servizi',
    label: 'Servizi',
    isDisabled: false
  },
  {
    key: 'articoli',
    value: 'articoli',
    label: 'Articoli',
    isDisabled: false
  }
]

export { mockupRicavi, selectType }
