import React, { Component } from 'react'
import { ContentStyle, LittleCard, LittleFooter, LittleToolbar, LittleWrapper, Toolbar, ToolbarItem } from '../../Components'
import { Api, LocalStorage } from '../../Services'
import { Button } from '../../Library/Components'
import dashboard from './dashboard'
import Loader from '../../Library/Tools/Loader'

export default class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      pages: [],
      data: []
    }
  }

  async componentDidMount () {
    const id = LocalStorage.get('_id')
    const res = await Api.get(`/users/${id}`)
    const user = res.data
    const pages = dashboard.filter(item => user.sezioni.includes(item.title)).sort((a, b) => a.title.localeCompare(b.title))
    this.setState({ pages, data: pages, loading: false })
  }

  searchElement (e) {
    const { pages } = this.state
    e === ''
      ? this.setState({ data: pages })
      : this.setState({ data: pages.filter(item => item.title.toLowerCase().includes(e)) })
  }

  render () {
    const { loading, data } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar isend='true'>
          <ToolbarItem>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.searchElement(e.target.value)} />
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content direction='row' center='center' background='transparent' overflow='auto'>
          {data.map((item, index) => {
            return (
              <LittleCard key={index} tipo={item.color}>
                <LittleWrapper>
                  <LittleToolbar tipo={item.color}>{item.icon}</LittleToolbar>
                  {item.title}
                </LittleWrapper>
                <LittleFooter>
                  <Button onClick={() => this.props.navigate(item.path)} type={item.color} outline='true'>Select</Button>
                </LittleFooter>
              </LittleCard>
            )
          })}
        </ContentStyle.Content>
      </Loader>
    )
  }
}
