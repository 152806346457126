import React, { Component } from 'react'
import { FaArrowLeft, FaLock, FaUser } from 'react-icons/fa'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import { Loginbox, LoginFormButton, LoginLabel, Logo } from '../../Components/Layout'
import logo from '../../Assets/tecnel.jpg'

export default class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      otp: false,
      codiceOtp: '',
      save: {}
    }
  }

  async login () {
    const { email, password } = this.state
    if (!email || !password) {
      swaller.error('Completare tutti i campi, poi premere login')
      return
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      swaller.error('Inserire un indirizzo email valido')
      return
    }
    try {
      const data = await Api.login(email, password)
      if (!data) {
        swaller.error('Le credenziali immesse sono errate')
        return
      }
      if (!data.user.abilitato) {
        swaller.error('Si prega di contattare l\'assistenza')
        return
      }
      const { user, jwt } = data
      if (user.isFirstLogin) {
        LocalStorage.set('user', user)
        LocalStorage.set('_id', user._id)
        this.props.navigate('/firstlogin')
      } else if (!user.otp) {
        LocalStorage.set('_id', user._id)
        this.props.navigate('/firstotp')
      } else {
        this.setState({ otp: true, save: { user, _id: user._id, jwt } })
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante il login')
    }
  }

  async checkOtp () {
    const { codiceOtp, save } = this.state
    try {
      const res = await Api.post('/otp', { otp: codiceOtp })
      if (res.ok) {
        const { success } = res.data
        if (success) {
          const { user, _id, jwt } = save
          LocalStorage.set('user', user)
          LocalStorage.set('_id', _id)
          LocalStorage.set('jwt', jwt)
          const { role, azienda } = user
          if (role === 'azienda') {
            const res = await Api.get(`users/${azienda || user.azienda}`)
            if (res.ok && res.data) {
              const { intestazione } = res.data
              LocalStorage.set('intestazione', intestazione)
            }
          }
          this.props.navigate('/home')
          let nome
          if (!user.intestazione) {
            nome = `${user.nome} ${user.cognome}`
          } else {
            nome = `${user.intestazione}`
          }
          swaller.success(`Buon lavoro ${nome}`)
          return
        }
        swaller.error('Codice OTP non valido')
      } else {
        swaller.error('Impossibile contattare il server')
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante la verifica dell\'OTP')
    }
  }

  handleKeyDown (event) {
    if (event.keyCode === 13) {
      if (this.state.otp) {
        this.checkOtp()
      } else {
        this.login()
      }
    }
  }

  render () {
    const { otp } = this.state

    return (
      <>
        {!otp && (
          <Loginbox>
            <Logo src={logo} />
            <br />
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}><FaUser /> Login</div>
            <LoginLabel type='text' placeholder='Email' onChange={(e) => this.setState({ email: e.target.value })} />
            <LoginLabel type='password' placeholder='Password' onKeyDown={(e) => this.handleKeyDown(e)} onChange={(e) => this.setState({ password: e.target.value })} />
            <LoginFormButton type='primary' outline='true' onClick={async () => await this.login()}>Accedi</LoginFormButton>
            <br />
            <div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.props.navigate('/resetpassword')}>Password dimenticata?</div>
          </Loginbox>
        )}
        {otp && (
          <Loginbox>
            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><FaArrowLeft style={{ cursor: 'pointer' }} onClick={() => this.setState({ otp: false })} /></div>
            <Logo src={logo} />
            <br />
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}><FaLock /> Google Authenticator</div>
            <LoginLabel text='center' type='text' placeholder='Codice a 6 cifre' onKeyDown={(e) => this.handleKeyDown(e)} onChange={(e) => this.setState({ codiceOtp: e.target.value })} />
            <LoginFormButton type='primary' outline='true' onClick={async () => await this.checkOtp()}>Conferma</LoginFormButton>
          </Loginbox>
        )}
      </>
    )
  }
}
