import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnResoconti } from '../Richieste/MockupRichieste'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'
export default class VisualizzaResocontiPreventivi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      preventivi: [],
      resoconti: [],
      clienti: [],
      tecnici: [],
      azienda: '',
      utenti: []

    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const { _id } = this.props.location.state
    this.setState({ azienda, _id }, async () => {
      await this.getResoconti(_id)
      await this.loadData(_id)
    })
  }

  async getResoconti (idPreventivo) {
    const { azienda } = this.state
    const res = await Api.get('/resoconti', { azienda, referenza: idPreventivo })
    if (!res.ok) {
      swaller.error('Non è stato possibile recuperare i resoconti')
    } else {
      this.setState({ resoconti: res.data, data: res.data })
    }
  }

  async loadData (idPreventivo) {
    const { azienda } = this.state
    const tecnici = await Api.get('/users', { azienda, role: 'utente' })
    const clienti = await Api.get('/users', { azienda, role: 'cliente' })
    const utenti = await Api.get('/users', { azienda })
    const preventivi = await Api.get('/preventivi', { _id: idPreventivo })
    if (tecnici.ok && clienti.ok && utenti.ok && preventivi.ok) {
      this.setState({ tecnici: tecnici.data, clienti: clienti.data, utenti: utenti.data, preventivi: preventivi.data[0] }, () => {
        this.setState({ loading: false })
      })
    } else {
      swaller.error('Non è stato possibile recuperare i dati')
    }
  }

  getTecnico (_id) {
    const { tecnici } = this.state
    const tecnico = tecnici.find(tecnico => tecnico._id === _id)
    return tecnico ? `${tecnico.nome} ${tecnico.cognome}` : ''
  }

  getPreventivo (_id) {
    const { preventivi } = this.state
    let text = ''
    preventivi.forEach(preventivo => {
      if (_id === preventivo._id) {
        text = preventivo.nPreventivo
      }
    })
    return text
  }

  getUtente (_id) {
    const { utenti } = this.state
    const utente = utenti.find((utente) => utente._id === _id)
    return utente ? `${utente.nome} ${utente.cognome}` : ''
  }

  aggiungiResoconto () {
    const { preventivi } = this.state
    this.props.navigate('/preventivi/resoconto/nuovo', { state: preventivi, replace: false })
  }

  render () {
    const { loading, resoconti, settori } = this.state
    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/preventivi')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='primary' outline='true' onClick={() => this.aggiungiResoconto()}><FaIcons.FaPlusCircle />Nuovo resoconto</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Table>
            <Table>
              <Thead>
                <Trow>
                  {columnResoconti.map((colonne, i) => {
                    return (
                      <Tcol key={i}>
                        {colonne.label === 'Settore'
                          ? (
                            <ContentStyle.Select onChange={(e) => this.sectorFilter(e.target.value)}>
                              <option value='settore'>Tutti i settori</option>
                              {
                                settori && settori.length > 0 && settori.map((item, i) => {
                                  return (
                                    <option value={item._id} key={i}>
                                      {item.settore}
                                    </option>
                                  )
                                })
                              }
                            </ContentStyle.Select>
                            )
                          : <b>{colonne.label}</b>}
                      </Tcol>
                    )
                  })}
                </Trow>
              </Thead>
              <Tbody>
                {resoconti && resoconti.length > 0 && resoconti.map((item, i) => {
                  return (
                    <Trow key={i}>
                      <Tcol>{this.getTecnico(item.tecnico)}</Tcol>
                      <Tcol>{new Date(item.dataInizio).toLocaleString('it-IT')}</Tcol>
                      <Tcol>{new Date(item.dataFine).toLocaleString('it-IT')}</Tcol>
                      <Tcol>{item.descrizione}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info }} onClick={() => this.props.navigate('/resoconti/modifica', { state: item, replace: false })} />
                      </Tcol>
                    </Trow>
                  )
                })}
              </Tbody>
            </Table>
          </ContentStyle.Table>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
