import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api } from '../../Services'
import { mockupAzienda } from './MockupAzienda'

export default class ModificaAzienda extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      _id: '',
      intestazione: '',
      pIva: '',
      pec: '',
      codiceUnivoco: '',
      email: '',
      telefono: '',
      indirizzo: '',
      citta: '',
      cap: '',
      nazione: '',
      contabilita: '',
      referente: ''
    }
  }

  componentDidMount () {
    const { _id, intestazione, pIva, pec, codiceUnivoco, email, telefono, indirizzo, citta, cap, nazione, referente, contabilita } = this.props.location.state
    this.setState({ _id, intestazione, pIva, pec, codiceUnivoco, email, telefono, indirizzo, citta, cap, nazione, referente, contabilita, loading: false })
  }

  async aggiornaAzienda () {
    const { _id, intestazione, pIva, pec, codiceUnivoco, email, telefono, indirizzo, citta, cap, nazione, referente, contabilita } = this.state
    if (!intestazione || !email || !pIva || !pec || !indirizzo || !citta || !cap) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(email)) {
        swaller.error('Inserire un indirizzo email valido')
      } else {
        swaller.error('Compilare i campi obbligatori e riprovare!')
      }
    } else {
      swaller.info('Salvataggio in corso...')
      const res = await Api.patch(`/users/${_id}`, {
        intestazione,
        pIva,
        pec,
        codiceUnivoco,
        email,
        telefono,
        indirizzo,
        citta,
        cap,
        nazione,
        referente,
        contabilita
      })
      if (res.ok) {
        swaller.success('Azienda aggiornato correttamente!')
        setTimeout(() => this.props.navigate('/aziende'), 2000)
      } else {
        swaller.error('Errore durante la modifica azienda, prego riprovare!')
      }
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/aziende')
    }
  }

  render () {
    const dimensioneParte = Math.ceil(mockupAzienda.length / 3)

    return (
      <>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.aggiornaAzienda()}><FaIcons.FaCheck />Aggiorna Azienda</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form flexdirection='row' width='80%'>
            {Array.from({ length: 3 }, (_, indiceColonna) => (
              <ContentStyle.FormColumn key={indiceColonna}>
                {mockupAzienda.slice(indiceColonna * dimensioneParte, (indiceColonna + 1) * dimensioneParte).map((field) => (
                  <ContentStyle.Row width='50%' key={field.key}>
                    <ContentStyle.Label><b>{field.label}:</b></ContentStyle.Label>
                    <ContentStyle.Text
                      type={field.type}
                      placeholder={field.label.toLowerCase()}
                      value={this.state[field.key]}
                      onChange={(e) => this.setState({ [field.key]: e.target.value })}
                    />
                  </ContentStyle.Row>
                ))}
              </ContentStyle.FormColumn>
            ))}
          </ContentStyle.Form>
        </ContentStyle.Content>
      </>
    )
  }
}
