const columnArticoli = [
  {
    id: 'referenza',
    label: 'Referenza'
  },
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'prezzoAcquisto',
    label: 'Prezzo di acquisto'
  },
  {
    id: 'produttore',
    label: 'Produttore'
  },
  {
    id: 'settore',
    label: 'Settore'
  },
  {
    id: 'qtaMinStock',
    label: 'Qta min stock'
  },
  {
    id: 'deprecated',
    label: 'Deprecated'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'info',
    label: 'Info'
  }
]

const mockupArticoli = [
  { key: 'referenza', label: 'Referenza', type: 'text' },
  { key: 'titolo', label: 'Titolo', type: 'text' },
  { key: 'prezzoAcquisto', label: 'Prezzo di acquisto (€)', type: 'text' },
  { key: 'qtaMinStock', label: 'Quantità minima stock', type: 'text' },
  { key: 'descrizione', label: 'Descrizione', type: 'text' }
]

export { columnArticoli, mockupArticoli }
