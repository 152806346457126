import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, BoxInfo, BoxContent, BoxRow } from '../../Components'
import { Button, CloseButton } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnAggiuntaCarrello, columnResocontoCarrello } from '../Resoconti/MockupResoconti'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { BoxAggiuntaMateriale } from '../../Components/ModalInfo'
import Loader from '../../Library/Tools/Loader'

export default class Carrello extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      carrello: [],
      articoli: [],
      magazzino: [],
      content: [],
      data: [],
      infoValue: {},
      showInfoBox: false,
      showBoxAggiuntaMateriale: false,
      tipologiaCarrello: '...',
      azienda: '',
      _id: '',
      settore: [],
      produttore: [],
      fornitore: [],
      clienti: [],
      cliente: '',
      tecnici: [],
      tecnico: ''
    }
  }

  async componentDidMount () {
    const { azienda, _id } = LocalStorage.get('user')
    const tipologiaCarrello = this.props.location.state
    this.setState({ azienda, tipologiaCarrello, _id }, async () => await this.loadData())
  }

  async loadData () {
    const { azienda } = this.state
    const articoli = await Api.get('/articoli', { azienda })
    const magazzino = await Api.get('/magazzino', { azienda })
    const produttori = await Api.get('/produttori', { azienda })
    const settori = await Api.get('/settori', { azienda })
    const fornitori = await Api.get('/fornitori', { azienda })
    const clienti = await Api.get('/users', { azienda, role: 'cliente' })
    const tecnici = await Api.get('/users', { azienda, role: 'utente' })
    produttori.ok && settori.ok && fornitori.ok && articoli.ok && magazzino.ok && tecnici.ok && clienti.ok
      ? this.setState({ articoli: articoli.data, data: articoli.data, magazzino: magazzino.data, produttore: produttori.data, settore: settori.data, fornitore: fornitori.data, clienti: clienti.data, tecnici: tecnici.data }, () => this.setState({ loading: false }))
      : swaller.error('Non è stato possibile recuperare i dati')
  }

  async generaCodice (value) {
    const { azienda } = this.state
    const res = await Api.get(`/${value}`, { azienda })
    let codice = 0
    codice = res.ok
      ? res.data.length + 1
      : new Date().unix()
    return codice
  }

  filter (search, value) {
    const { data } = this.state
    search === ''
      ? this.setState({ articoli: data })
      : this.setState({ articoli: data.filter(item => item[value].toLowerCase().includes(search.toLowerCase())) })
  }

  async confermaCarrello () {
    const { tipologiaCarrello, carrello, magazzino, azienda, _id, cliente, tecnico } = this.state
    const error = carrello.some(item => item.qta <= 0)
    if (!error) {
      switch (tipologiaCarrello) {
        case 'creazione scatola': {
          if (cliente === '' && tecnico === '') {
            swaller.error('Selezionare un cliente o un tecnico a cui asseganre la scatola!')
          } else {
            for (const item of carrello) {
              const obj = magazzino.filter((e) => e.articolo === item._id)
              obj[0].qtaStock = obj[0].qtaStock - item.qta
              await Api.patch(`/magazzino/${obj[0]._id}`, obj[0])
            }
            swaller.success('Magazzino aggiornato correttamente!')
            const nScatola = await this.generaCodice('scatole')
            const articoliScatola = carrello.map(item => ({ item: item._id, qta: item.qta }))
            const scatola = {
              cliente,
              tecnico,
              nScatola,
              articoliScatola,
              dataOraCreazione: new Date().toISOString(),
              creatore: _id,
              azienda
            }
            if (articoliScatola.length > 0) {
              const res = await Api.post('/scatole', scatola)
              if (res.ok) {
                swaller.success(`Scatola N. ${nScatola} salvata correttamente!`)
                setTimeout(() => this.props.navigate('/scatole'), 2000)
              } else {
                swaller.error('Errore durante il salvataggio della scatola!')
              }
            } else {
              swaller.error('Carrello vuoto')
            }
            setTimeout(() => this.props.navigate('/magazzino'), 2000)
          }
          break
        }
        case 'consegna materiale':
          for (const item of carrello) {
            const obj = magazzino.filter((e) => e.articolo === item._id)
            if (obj.length > 0) {
              obj[0].qtaStock = obj[0].qtaStock + item.qta
              await Api.patch(`/magazzino/${obj[0]._id}`, obj[0])
            } else {
              const magItem = {
                articolo: item._id,
                qtaStock: item.qta,
                azienda
              }
              await Api.post('/magazzino', magItem)
            }
          }
          swaller.success('Magazzino aggiornato correttamente!')
          setTimeout(() => this.props.navigate('/magazzino'), 2000)
          break
        case 'creazione ordine': {
          const nOrdine = await this.generaCodice('ordini')
          const articoli = carrello.map(item => ({ item: item._id, qta: item.qta }))
          const ordine = {
            nOrdine,
            articoli,
            dataOraCreazione: new Date().toISOString(),
            ordinato: 'false',
            manualOrder: 'true',
            creatore: _id,
            azienda
          }
          if (articoli.length > 0) {
            const res = await Api.post('/ordini', ordine)
            if (res.ok) {
              swaller.success(`Ordine N. ${nOrdine} salvato correttamente!`)
              setTimeout(() => this.props.navigate('/ordini'), 2000)
            } else {
              swaller.error('Errore durante il salvataggio dell\'ordine!')
            }
          } else {
            swaller.error('Carrello vuoto')
          }
          break
        }
        default:
          break
      }
    } else {
      swaller.warning('Alcuni articoli hanno quantità 0')
    }
  }

  getInfo (_id, value) {
    const items = this.state[value]
    const selectedItem = items.find(item => item._id === _id)
    return selectedItem ? selectedItem[value] : ''
  }

  addCarrello (item) {
    const { carrello } = this.state
    const check = carrello.includes(item)
    if (check) {
      swaller.warning('Articolo già aggiunto')
    } else {
      item.qta = 0
      carrello.push(item)
      this.setState({ carrello })
    }
  }

  async removeCarrello (index) {
    const { carrello } = this.state
    const res = await swaller.question('Sicuro di voler eliminare ?')
    if (res) {
      carrello.splice(index, 1)
      this.setState({ carrello })
    }
  }

  handleQuantity (index, qta) {
    const { carrello, tipologiaCarrello, magazzino } = this.state
    const isValidQuantity = qta >= 0
    switch (tipologiaCarrello) {
      case 'creazione scatola':
        carrello[index].qta = isValidQuantity && qta <= magazzino[index].qtaStock
          ? Number(qta)
          : 0
        break
      case 'consegna materiale':
        carrello[index].qta = isValidQuantity ? Number(qta) : 0
        break
      default:
        break
    }
    this.setState({ carrello })
  }

  async returnBack () {
    const { tipologiaCarrello } = this.state
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      tipologiaCarrello === 'creazione ordine'
        ? this.props.navigate('/ordini')
        : this.props.navigate('/magazzino')
    }
  }

  render () {
    const { loading, carrello, articoli, showInfoBox, infoValue, tipologiaCarrello, showBoxAggiuntaMateriale, clienti, tecnici, magazzino } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.returnBack()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' disabled={carrello.length === 0} outline='true' onClick={() => this.confermaCarrello()}><FaIcons.FaCheckCircle />Conferma {tipologiaCarrello}</Button>
            {articoli.length > 0 && <Button type='info' outline='true' onClick={() => this.setState({ showBoxAggiuntaMateriale: true })}><FaIcons.FaPlusCircle />Aggiungi articolo</Button>}
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {tipologiaCarrello === 'creazione scatola' &&
            <ContentStyle.Row style={{ justifyContent: 'center' }}>
              <ContentStyle.Title>Assegnatario scatola:</ContentStyle.Title>
              <ContentStyle.Box>
                <ContentStyle.Label>Cliente: </ContentStyle.Label>
                <ContentStyle.Select onChange={(e) => this.setState({ cliente: e.target.value })}>
                  <option value=''>...</option>
                  {clienti && clienti.length > 0 && clienti.map((item, i) => {
                    return (
                      <option value={item._id} key={i}>
                        {item.isPrivate === '1' ? `${item.nome} ${item.cognome}` : item.intestazione}
                      </option>
                    )
                  })}
                </ContentStyle.Select>
                <ContentStyle.Label>Tecnico: </ContentStyle.Label>
                <ContentStyle.Select onChange={(e) => this.setState({ tecnico: e.target.value })}>
                  <option value=''>...</option>
                  {tecnici && tecnici.length > 0 && tecnici.map((item, i) => {
                    return (
                      <option value={item._id} key={i}>
                        {`${item.nome} ${item.cognome}`}
                      </option>
                    )
                  })}
                </ContentStyle.Select>
              </ContentStyle.Box>
            </ContentStyle.Row>}
          <hr />
          <ContentStyle.Table>
            {carrello.length > 0 &&
              <Table>
                <Thead>
                  {columnResocontoCarrello.map((colonne, i) => {
                    return (
                      <Tcol key={i}>
                        <b>{colonne.label}</b>
                      </Tcol>
                    )
                  })}
                </Thead>
                <Tbody>
                  {carrello.map((item) => {
                    return (
                      <Trow key={item._id}>
                        <Tcol>{this.getInfo(item.settore, 'settore')}</Tcol>
                        <Tcol>{item.referenza}</Tcol>
                        <Tcol>{item.titolo}</Tcol>
                        <Tcol>{this.getInfo(item.fornitore, 'fornitore')}</Tcol>
                        <Tcol>{this.getInfo(item.produttore, 'produttore')}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <input style={{ width: 50 }} type='number' value={item.qta} onChange={(e) => this.handleQuantity(item._id, e.target.value)} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: item })} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.removeCarrello(item._id)} />
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>}
            {carrello.length === 0 && <div style={{ display: 'flex', justifyContent: 'center' }}><i>Nessun prodotto aggiunto</i></div>}
          </ContentStyle.Table>
          <BoxInfo showInfoBox={showInfoBox}>
            <BoxContent>
              <Toolbar>
                <CloseButton>
                  <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: false, infoValue: {} })} />
                </CloseButton>
              </Toolbar>
              <ContentStyle.Title>Descrizione:</ContentStyle.Title>
              <hr />
              <BoxRow>Referenza: {infoValue.referenza}</BoxRow>
              <BoxRow>Titolo: {infoValue.titolo}</BoxRow>
              <BoxRow>Produttore: {this.getInfo(infoValue.produttore, 'produttore')}</BoxRow>
              <BoxRow>Fornitore: {this.getInfo(infoValue.fornitore, 'fornitore')}</BoxRow>
              <BoxRow>Quantità stock: {infoValue.qtaStock}</BoxRow>
              <BoxRow>Quantità minima stock: {infoValue.qtaMinStock}</BoxRow>
              <BoxRow>Settore: {this.getInfo(infoValue.settore, 'settore')}</BoxRow>
              <ContentStyle.Row style={{ display: 'flex', alignItems: 'center', gap: 10 }}>Descrizione: <ContentStyle.Textarea disabled value={infoValue.descrizione} /></ContentStyle.Row>
              <BoxRow>Immagine: {infoValue.immagine}</BoxRow>
              <BoxRow>Ultima modifica: {new Date(infoValue.dataOra).toLocaleString('it-IT')}</BoxRow>
            </BoxContent>
          </BoxInfo>
          <BoxAggiuntaMateriale show={showBoxAggiuntaMateriale}>
            <Toolbar paddingleft='30px' paddingright='30px'>
              <ToolbarItem>
                <h3>Selezionare gli articoli</h3>
                <ContentStyle.Search type='text' style={{ marginLeft: 10 }} placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'referenza')} />
              </ToolbarItem>
              <ToolbarItem isend='true'>
                <FaIcons.FaCheckSquare size={36} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.setState({ showBoxAggiuntaMateriale: false, search: '' })} />
              </ToolbarItem>
            </Toolbar>
            <br />
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnAggiuntaCarrello.map((colonne, i) => {
                    return (
                      <Tcol key={i}>
                        <b>{colonne.label}</b>
                      </Tcol>
                    )
                  })}
                </Thead>
                <Tbody>
                  {articoli.map((item, i) => (tipologiaCarrello === 'creazione scatola' ? magazzino.filter((e) => e.articolo === item._id)[0].qtaStock > 0 : true) &&
                    <Trow key={i}>
                      <Tcol>{item.referenza}</Tcol>
                      <Tcol>{item.titolo}</Tcol>
                      <Tcol>{this.getInfo(item.fornitore, 'fornitore')}</Tcol>
                      <Tcol>{this.getInfo(item.produttore, 'produttore')}</Tcol>
                      <Tcol>{this.getInfo(item.settore, 'settore')}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        {carrello.filter((e) => e._id === item._id).length === 0 && <FaIcons.FaPlusCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrello(item)} />}
                        {carrello.filter((e) => e._id === item._id).length > 0 && <FaIcons.FaCheckCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrello(item)} />}
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: item })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
            </ContentStyle.Table>
          </BoxAggiuntaMateriale>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
