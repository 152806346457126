import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { Button } from '../../Library/Components'
import Loader from '../../Library/Tools/Loader'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import { Config } from '../../Global'
import LineChart from './LineChart'
import { FaArrowCircleLeft } from 'react-icons/fa'
export default class Analisi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      azienda: '',
      magazzino: [],
      valoreMagazzino: 0,
      preventivi: [],
      preventiviChiusi: [],
      preventiviFatturati: [],
      richieste: [],
      richiesteChiuse: [],
      fatture: [],
      fattureSaldate: [],
      fattureDaSaldare: [],
      anno: '2023'
    }
  }

  async componentDidMount () {
    const { anno } = this.state
    const { azienda } = LocalStorage.get('user')
    this.setState({ azienda }, async () => {
      anno !== '' && await this.loadData(anno)
    })
  }

  async loadData (anno) {
    const { azienda } = this.state
    // 2023-01-01T00:00:00.488Z
    // 2023-12-31T00:00:00.488Z
    // const inizio = new Date(`${anno}-01-01`).toISOString()
    // const fine = new Date(`${anno}-12-31`).toISOString()

    const preventivi = await Api.get('/preventivi', { azienda })
    const preventiviChiusi = await Api.get('/preventivi', { azienda, stato: '3' })
    const preventiviFatturati = await Api.get('/preventivi', { azienda, stato: '4' })

    // FILTRO DATA const richieste = await Api.get('/richieste', { azienda, dataOra: { $gt: '2023-01-01T00:00:00.488Z', $lt: '2023-12-31T00:00:00.488Z' } })
    const richieste = await Api.get('/richieste', { azienda })
    const richiesteChiuse = await Api.get('/richieste', { azienda, stato: '3' })

    const magazzino = await Api.get('/magazzino', { azienda })
    const articoli = await Api.get('/articoli', { azienda })

    const fatture = await Api.get('/fatture', { azienda })
    const fattureSaldate = await Api.get('/fatture', { azienda, saldata: 'true' })
    const fattureDaSaldare = await Api.get('/fatture', { azienda, saldata: 'false' })

    if (preventivi.ok && preventiviChiusi.ok && richieste.ok && richiesteChiuse.ok && magazzino.ok && fatture.ok && articoli.ok && fattureDaSaldare.ok && fattureSaldate.ok) {
      const valoreMagazzino = this.getValoreMagazzino(magazzino.data, articoli.data)
      this.setState(
        {
          preventivi: preventivi.data,
          preventiviChiusi: preventiviChiusi.data,
          preventiviFatturati: preventiviFatturati.data,
          richieste: richieste.data,
          richiesteChiuse: richiesteChiuse.data,
          magazzino: magazzino.data,
          fatture: fatture.data,
          fattureSaldate: fattureSaldate.data,
          fattureDaSaldare: fattureDaSaldare.data,
          articoli: articoli.data,
          valoreMagazzino
        }, () => {
          this.setState({ loading: false })
        })
    } else {
      swaller.error('Non è stato possibile recuperare i dati')
    }
  }

  getValoreMagazzino (magazzino, articoli) {
    let valore = 0
    magazzino.forEach(element => {
      const id = element.articolo
      const stock = element.qtaStock
      const data = articoli.find(item => item._id === id)
      valore += data.prezzoAcquisto * stock
    })
    return valore
  }

  render () {
    const { loading, anno, preventivi, preventiviChiusi, preventiviFatturati, richieste, richiesteChiuse, magazzino, valoreMagazzino, fatture, fattureDaSaldare, fattureSaldate } = this.state
    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <ContentStyle.Label>ANNO: </ContentStyle.Label>
            <ContentStyle.Select value={anno} onChange={(e) => e.target.value != null && this.setState({ anno: e.target.value })}>
              <option value=''>...</option>
              <option value='2021'>2021</option>
              <option value='2022'>2022</option>
              <option value='2023'>2023</option>
            </ContentStyle.Select>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content overflow='scroll'>
          {anno !== ''
            ? (
              <ContentStyle.InputAllPage>
                <ContentStyle.Row>
                  <ContentStyle.Label>Totale elementi magazzino: {magazzino.length}</ContentStyle.Label>
                </ContentStyle.Row>
                <ContentStyle.Row>
                  <ContentStyle.Label>Valore magazzino: {Config.format(valoreMagazzino)}</ContentStyle.Label>
                </ContentStyle.Row>
                <hr />
                <ContentStyle.Row>
                  Totale preventivi: {preventivi.length}
                </ContentStyle.Row>
                <ContentStyle.Row>
                  Preventivi chiusi: {preventiviChiusi.length}
                </ContentStyle.Row>
                <ContentStyle.Row>
                  Preventivi fatturati: {preventiviFatturati.length}
                </ContentStyle.Row>
                <hr />
                <ContentStyle.Row>
                  Totale richieste: {richieste.length}
                </ContentStyle.Row>
                <ContentStyle.Row>
                  Richieste chiuse: {richiesteChiuse.length}
                </ContentStyle.Row>
                <hr />
                <ContentStyle.Row>
                  Fatture emesse: {fatture.length}
                </ContentStyle.Row>
                <ContentStyle.Row>
                  Fatture saldate: {fattureSaldate.length}
                </ContentStyle.Row>
                <ContentStyle.Row>
                  Fatture da saldare: {fattureDaSaldare.length}
                </ContentStyle.Row>
                <hr />
                <ContentStyle.Row>
                  <LineChart />
                </ContentStyle.Row>
              </ContentStyle.InputAllPage>)
            : (
              <ContentStyle.InputAllPage style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                <i>
                  Selezionare un anno per mostrare i dati corrispondenti...
                </i>
              </ContentStyle.InputAllPage>
              )}
        </ContentStyle.Content>
      </Loader>
    )
  }
}
