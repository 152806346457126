import { Page, View, Document, Text, Image } from '@react-pdf/renderer'
import { PdfStyles } from '../Library/Tools/Pdf'
import { LocalStorage } from '../Services'
import Logo from '../images/Tecnosistemi.png'

export const PdfRichieste = (richiesta) => {
  return (
    <Document>
      <Page size='A4' style={PdfStyles.body}>
        <View style={PdfStyles.table}>
          <View style={PdfStyles.row}>
            <View style={PdfStyles.col50logo}>
              <Image style={PdfStyles.image} src={Logo} />
            </View>
            <View style={PdfStyles.col50}>
              <Text style={PdfStyles.title}>
                Tecnosistemi S.r.l.
                C.so Francia, 247 - 12100 Cuneo
                Reg. Imprese e P.I. 02023170042
                C.C.I.A.A. di Cuneo N. 149611
                Tel. +39 0171 344399 - Fax +39 0171 344711
                info@tecnosistemicuneo.it - www.tecnosistemicuneo.it
                PEC: tecnosistemicuneo@cert.unonet.it
                Servizio Assistenza: cell. 335/6558288 - Cell. 335/5444526
              </Text>
            </View>
          </View>
          <View style={PdfStyles.row}>
            <View style={PdfStyles.col50}>
              <View style={PdfStyles.row}>
                <View style={PdfStyles.col50sx}>
                  <Text style={PdfStyles.text}>Tipologia: Richiesta</Text>
                </View>
                <View style={PdfStyles.col50dx}>
                  <Text style={PdfStyles.text}>Data creazione: {richiesta.creatoDa} - {new Date(richiesta.dataOra).toLocaleString('it-IT')}</Text>
                </View>
              </View>
              <View style={PdfStyles.row}>
                <View style={PdfStyles.col100}>
                  <Text style={PdfStyles.text}>Titolo: {richiesta.titolo}</Text>
                </View>
              </View>
              <View style={PdfStyles.row}>
                <View style={PdfStyles.col100}>
                  <Text style={PdfStyles.text}>Urgenza: {richiesta.urgenza}</Text>
                </View>
              </View>
              <View style={PdfStyles.row}>
                <View style={PdfStyles.col50sx}>
                  <Text style={PdfStyles.text}>Tecnico: {richiesta.tecnico}</Text>
                </View>
                <View style={PdfStyles.col50dx}>
                  <Text style={PdfStyles.text}>Settore: {richiesta.settore}</Text>
                </View>
              </View>
            </View>
            <View style={PdfStyles.col50}>
              <Text style={PdfStyles.text}>Cliente: {richiesta.cliente}</Text>
            </View>
          </View>
          <View style={PdfStyles.row}>
            <View style={PdfStyles.col50}>
              <Text style={PdfStyles.text}>Stato richiesta: {richiesta.isClosed === false ? 'Aperta' : 'Chiusa'}</Text>
            </View>
            <View style={PdfStyles.col50}>
              <Text style={PdfStyles.text}>Data chiusura: {richiesta.chiusuraDataOra ? new Date(richiesta.chiusuraDataOra).toLocaleString('it-IT') : '-'}</Text>
            </View>
          </View>
          <View style={PdfStyles.col100box}>
            <View style={PdfStyles.rowTitles}>
              <Text style={PdfStyles.text}>Descrizione del problema</Text>
            </View>
          </View>
          <View style={PdfStyles.row}>
            <View style={PdfStyles.col100bottom}>
              <Text style={PdfStyles.text}>{richiesta.descrizione}</Text>
            </View>
          </View>
        </View>
        <Text style={PdfStyles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
        <Text style={PdfStyles.footer}>
          ~ Powered by: {LocalStorage.get('intestazione')} ~
        </Text>
      </Page>
    </Document>
  )
}
