const columnInstallazioni = [
  {
    id: 'codiceInstallazione',
    label: 'Codice Installazione'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'descrizione',
    label: 'Descrizione'
  },
  {
    id: 'settori',
    label: 'Settori'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'info',
    label: 'Info'
  }
]
export { columnInstallazioni }
