import styled from 'styled-components'

const Breadcrumb = {}

Breadcrumb.Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

Breadcrumb.Crumb = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

export default Breadcrumb
