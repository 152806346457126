import apisauce from 'apisauce'
import LocalStorage from './LocalStorage'
import { Config } from '../Global'
import moment from 'moment'
import { jwtDecode } from 'jwt-decode'

const Api = {}
const storage = LocalStorage.get('jwt')
Api.jwt = storage || null

const getClient = () => apisauce.create({
  baseURL: Config.API_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: Api.jwt
  }
})

let client = getClient()
Api.wait = ms => new Promise(resolve => setTimeout(resolve, ms))

Api.get = async (url, params) => {
  try {
    return await client.get(url, params)
  } catch (err) {
    return { ok: false, error: err }
  }
}

Api.patch = async (url, data) => {
  try {
    return await client.patch(url, data)
  } catch (err) {
    return { ok: false, error: err }
  }
}

Api.delete = async (url) => {
  try {
    return await client.delete(url)
  } catch (err) {
    return { ok: false, error: err }
  }
}

Api.post = async (url, data) => {
  try {
    return await client.post(url, data)
  } catch (err) {
    return { ok: false, error: err }
  }
}

Api.postData = async (url, data) => {
  try {
    return await client.post(url, data)
  } catch (err) {
    return { ok: false, error: err }
  }
}

Api.login = async (email, password) => {
  LocalStorage.set('lastLogin', new Date().toISOString())
  try {
    const res = await client.post('/authentication', { strategy: 'local', email, password })
    if (res.status === 201) {
      const jwt = res.data.accessToken
      const user = res.data.user
      Api.jwt = `Bearer ${jwt}`
      client = getClient()
      return { user, jwt }
    }
  } catch (err) {
    return false
  }
}

Api.upload = async file => {
  try {
    const files = new FormData()
    files.append('files', file)
    const response = await client.post('/upload', files)
    return response
  } catch (err) {
    return false
  }
}

Api.check = async () => {
  try {
    if (window.location.pathname !== '/') {
      const jwt = LocalStorage.get('jwt')
      const _id = LocalStorage.get('_id')
      if (jwt) {
        const valid = moment.unix(jwtDecode(jwt).exp) - moment() > 0
        if (!valid) {
          window.location.replace('/')
        } else {
          const res = await Api.get(`users/${_id}`)
          if (res.ok && res.data) {
            const user = res.data
            const { azienda, role, intestazione } = user
            LocalStorage.set('user', user)
            if (role === 'azienda') {
              LocalStorage.set('intestazione', intestazione)
            } else if (role !== 'proprietario') {
              const res = await Api.get(`users/${azienda}`)
              if (res.ok && res.data) {
                const { intestazione } = res.data
                LocalStorage.set('intestazione', intestazione)
              }
            }
          }
        }
      } else {
        window.location.replace('/')
      }
    }
  } catch (error) {}
}

export default Api
