const columnServizi = [
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'settore',
    label: 'Settore'
  },
  {
    id: 'costoOrario',
    label: 'Costo orario'
  },
  {
    id: 'descrizione',
    label: 'Descrizione'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  }
]

const mockupServizi = [
  {
    key: 'titolo',
    label: 'Titolo',
    type: 'text'
  },
  {
    key: 'costoOrario',
    label: 'Costo orario',
    type: 'number'
  },
  {
    key: 'descrizione',
    label: 'Descrizione',
    type: 'text'
  }
]

export { columnServizi, mockupServizi }
