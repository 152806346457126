import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'

const LineChart = () => {
  const chartContainerLine = useRef(null)
  const chartInstanceLine = useRef(null)
  const chartContainerPie = useRef(null)
  const chartInstancePie = useRef(null)
  const chartContainerBar = useRef(null)
  const chartInstanceBar = useRef(null)

  useEffect(() => {
    if (chartContainerLine && chartContainerLine.current) {
      const ctxLine = chartContainerLine.current.getContext('2d')

      // Dati del grafico (esempio)
      const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Fatturato',
            data: [65, 59, 80, 81, 56, 55, 60],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: 'Costi',
            data: [30, 40, 55, 70, 85, 60, 75],
            fill: false,
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1
          },
          {
            label: 'Utili',
            data: [10, 20, 35, 20, 25, 40, 45],
            fill: false,
            borderColor: 'rgb(0, 255, 0)',
            tension: 0.1
          }
        ]
      }

      // Configurazione del grafico
      const configLine = {
        type: 'line',
        data
      }

      // Se esiste già un'istanza del grafico, distruggila prima di crearne una nuova
      if (chartInstanceLine.current) {
        chartInstanceLine.current.destroy()
      }

      // Crea il grafico utilizzando Chart.js
      chartInstanceLine.current = new Chart(ctxLine, configLine)
    }

    // Creazione del grafico a torta
    if (chartContainerPie && chartContainerPie.current) {
      const ctxPie = chartContainerPie.current.getContext('2d')

      const dataPie = {
        labels: ['Tecnico1', 'Tecnico2', 'Tecnico3', 'Tecnico3', 'Tecnico4', 'Tecnico5'],
        datasets: [{
          label: 'My Pie Chart',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(153, 102, 255)',
            'rgb(255, 159, 64)'
          ],
          hoverOffset: 4
        }]
      }

      const configPie = {
        type: 'pie',
        data: dataPie
      }

      if (chartInstancePie.current) {
        chartInstancePie.current.destroy()
      }

      chartInstancePie.current = new Chart(ctxPie, configPie)
    }

    // Creazione del grafico a barre
    if (chartContainerBar && chartContainerBar.current) {
      const ctxBar = chartContainerBar.current.getContext('2d')

      const dataBar = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: 'Income for month',
          data: [65, 59, 80, 81, 56, 55, 40],
          backgroundColor: 'rgb(75, 192, 192)',
          borderColor: 'rgb(75, 192, 192)',
          borderWidth: 1
        }]
      }

      const configBar = {
        type: 'bar',
        data: dataBar,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      }

      if (chartInstanceBar.current) {
        chartInstanceBar.current.destroy()
      }

      chartInstanceBar.current = new Chart(ctxBar, configBar)
    }
  }, [])

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
      <div style={{ width: '300px', height: '300px' }}>
        <canvas ref={chartContainerLine} />
      </div>
      <div style={{ width: '300px', height: '300px' }}>
        <canvas ref={chartContainerPie} />
      </div>
      <div style={{ width: '300px', height: '300px' }}>
        <canvas ref={chartContainerBar} />
      </div>
    </div>
  )
}

export default LineChart
