import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import * as FaIcons from 'react-icons/fa'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'
import { formFornitori } from './MockupFornitori'

export default class Fornitori extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      fornitori: [],
      data: [],
      azienda: ''
    }
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      this.setState({ azienda, loading: true })
      const response = await Api.get('/fornitori', { azienda })
      if (!response.ok) {
        swaller.error('Non è stato possibile recuperare i fornitori')
      }
      const fornitoriData = response.data
      this.setState({ fornitori: fornitoriData, data: fornitoriData, loading: false })
    } catch (error) {
      swaller.error('Si è verificato un errore durante il recupero dei fornitori')
      this.setState({ loading: false })
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageFornitori = data.slice(startIndex, endIndex)
    return currentPageFornitori
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  filter (search) {
    const { fornitori } = this.state
    search === ''
      ? this.setState({ data: fornitori })
      : this.setState({ data: fornitori.filter(item => item.fornitore.toLowerCase().includes(search.toLowerCase())) })
  }

  render () {
    const { data, loading, fornitori, currentPage, itemPerPage } = this.state
    const totalPages = Math.ceil(fornitori.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value)} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/fornitori/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuovo fornitore</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {formFornitori.map((colonna) => {
                    return (
                      <Tcol key={colonna.id}>
                        <b>{colonna.label}</b>
                      </Tcol>
                    )
                  })}
                </Thead>
                <Tbody>
                  {fornitori.length > 0 && this.showData().map((fornitore) => {
                    return (
                      <Trow key={fornitore._id}>
                        <Tcol>{fornitore.fornitore}</Tcol>
                        <Tcol>{fornitore.pIva}</Tcol>
                        <Tcol>{fornitore.pec}</Tcol>
                        <Tcol>{fornitore.codiceUnivoco}</Tcol>
                        <Tcol>{fornitore.email}</Tcol>
                        <Tcol>{fornitore.telefono}</Tcol>
                        <Tcol>{fornitore.indirizzo}</Tcol>
                        <Tcol>{fornitore.citta}</Tcol>
                        <Tcol>{fornitore.cap}</Tcol>
                        <Tcol>{fornitore.nazione}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/fornitori/modifica', { state: fornitore, replace: false })} />
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Non ci sono fornitori</i>}
        </ContentStyle.Content>
      </Loader>
    )
  }
}
