import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import Loader from '../../Library/Tools/Loader'
import { palette } from '../../Library/Tools/Utilities'

export default class ModificaInstallazione extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      _id: '',
      codiceInstallazione: 0,
      cliente: '',
      elencoClienti: [],
      descrizione: '',
      settori: [],
      elencoSettori: []
    }
  }

  async componentDidMount () {
    const { _id, codiceInstallazione, descrizione, settori, cliente } = this.props.location.state
    const { azienda } = LocalStorage.get('user')
    const clientiResponse = await Api.get('/users', { azienda, role: 'cliente' })
    const settoriResponse = await Api.get('/settori', { azienda })
    this.setState({ azienda, _id, codiceInstallazione, descrizione, cliente, settori, elencoClienti: clientiResponse.data, elencoSettori: settoriResponse.data, loading: false })
  }

  async aggiornaInstallazione () {
    try {
      const { _id, azienda, codiceInstallazione, cliente, settori, descrizione } = this.state
      if (!codiceInstallazione || settori.length === 0) {
        swaller.error('Completa i campi obbligatori')
      }
      if (isNaN(codiceInstallazione)) {
        swaller.error('Il codice di installazione inserito non è un numero. Ricontrollare')
      }
      swaller.info('Salvataggio in corso...')
      const res = await Api.patch(`/installazioni/${_id}`, {
        codiceInstallazione,
        cliente,
        settori,
        descrizione,
        dataOraUpdate: new Date().toISOString(),
        azienda
      })
      if (res.ok) {
        swaller.success('Installazione salvata correttamente!')
        setTimeout(() => this.props.navigate('/installazioni'), 2000)
      } else {
        swaller.error('Errore durante la creazione dell\'installazione, prego riprovare!')
      }
    } catch (error) {
      swaller.error('Errore! Non è stato possibile aggiornare i dati. Riprovare')
    }
  }

  getData (_id) {
    const { elencoSettori } = this.state
    const foundItem = elencoSettori.find(item => item._id === _id)
    return foundItem ? foundItem.settore : '...'
  }

  aggiungiSettore (settore) {
    const { settori } = this.state
    if (settore === '') {
      swaller.info('Non si può aggiungere il settore di default', 1500)
    } else if (!settori.find(settoreItem => settoreItem === settore)) {
      this.setState({ settori: [...settori, settore] })
    }
  }

  rimuoviSettore (element) {
    const { settori } = this.state
    const nuoviSettori = settori.filter(e => e !== element)
    this.setState({ settori: nuoviSettori })
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/installazioni')
    }
  }

  render () {
    const { loading, codiceInstallazione, cliente, descrizione, elencoClienti, elencoSettori, settori, settore } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.aggiornaInstallazione()}>Aggiorna installazione</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content top='0px'>
          <ContentStyle.Form width='70%'>
            <ContentStyle.FormColumn gap='30px'>
              <ContentStyle.FormRow>
                <ContentStyle.RowItem gap='20px'>
                  <ContentStyle.Label>Codice installazione: </ContentStyle.Label>
                  <ContentStyle.Text width='200px' type='text' placeholder='codice installazione' value={codiceInstallazione} onChange={(e) => this.setState({ codiceInstallazione: e.target.value })} />
                </ContentStyle.RowItem>
                <ContentStyle.RowItem gap='20px'>
                  <ContentStyle.Label>Cliente: </ContentStyle.Label>
                  <ContentStyle.Select value={cliente} onChange={(e) => this.setState({ cliente: e.target.value })}>
                    <option value=''>...</option>
                    {elencoClienti.length > 0 && elencoClienti.map((item, index) =>
                      <option value={item._id} key={index}>
                        {item.isPrivate === '1' ? `${item.nome} ${item.cognome}` : item.intestazione}
                      </option>
                    )}
                  </ContentStyle.Select>
                </ContentStyle.RowItem>
              </ContentStyle.FormRow>
              <ContentStyle.FormRow>
                <ContentStyle.RowItem gap='20px'>
                  <ContentStyle.Label>Settore: </ContentStyle.Label>
                  <ContentStyle.Select value={settore} onChange={(e) => this.aggiungiSettore(e.target.value)}>
                    <option value=''>...</option>
                    {elencoSettori.map((item, index) => (
                      !settori.find(settoreItem => settoreItem === item._id)
                        ? <option key={index} value={item._id}>{item.settore}</option>
                        : null
                    ))}
                  </ContentStyle.Select>
                </ContentStyle.RowItem>
                <ContentStyle.RowItem gap='20px'>
                  {settori.length > 0 &&
                    <>
                      {settori.map((item, index) => (
                        <div key={index}>
                          <i>{this.getData(item)}</i>
                          <FaIcons.FaTrash
                            size={24}
                            style={{ color: palette.danger, cursor: 'pointer', marginLeft: '10px' }}
                            onClick={() => this.rimuoviSettore(item)}
                          />
                        </div>
                      ))}
                    </>}
                  {settori.length === 0 && <i>Nessun settore selezionato</i>}
                </ContentStyle.RowItem>
              </ContentStyle.FormRow>
              <br />
              <hr style={{ width: '80%' }} />
              <ContentStyle.FormRow>
                <ContentStyle.Label>Descrizione: </ContentStyle.Label>
              </ContentStyle.FormRow>
              <ContentStyle.FormRow>
                <ContentStyle.Textarea width='70%' type='text' placeholder='Descrizione' value={descrizione} onChange={(e) => this.setState({ descrizione: e.target.value })} />
              </ContentStyle.FormRow>
            </ContentStyle.FormColumn>
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
