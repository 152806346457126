import React, { Component } from 'react'
import { Api } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import { FaArrowLeft, FaKey } from 'react-icons/fa'
import { Loginbox, LoginFormButton, LoginLabel, Logo } from '../../Components/Layout'
import logo from '../../Assets/tecnel.jpg'

export default class ResetPassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      current: '',
      newPassword: '',
      checkNewPassword: '',
      otp: true,
      codiceOtp: '',
      email: '',
      user: ''
    }
  }

  async resetPassword () {
    const { newPassword, checkNewPassword, user } = this.state
    if (newPassword === 'password') {
      swaller.error('La nuova password non può essere password')
    } else if (newPassword === checkNewPassword) {
      const res = await Api.patch(`/users/${user._id}`, { password: newPassword }) // faccio una patch con le password nuove se sono
      if (res.ok) {
        swaller.success('La password è stata modificata con successo')
        this.props.navigate('/')
      }
    } else {
      swaller.error('Le password inserite non corrispondono')
    }
  }

  async handleKeyDown (event) {
    if (event.keyCode === 13) {
      await this.resetPassword()
    }
  }

  async checkOtp () {
    const { codiceOtp, email } = this.state
    const res = await Api.post('/otp', { otp: codiceOtp })
    if (res.ok) {
      if (res.data.success) {
        const response = await Api.get('users', { email })
        if (response.ok) {
          this.setState({ user: response.data[0], otp: true })
        } else {
          swaller.error('Indirizzo mail non valido')
        }
      } else {
        swaller.error('Codice OTP non valido')
      }
    } else {
      swaller.error('Impossibile contattare il server')
    }
  }

  async handleKeyDownOtp (event) {
    if (event.keyCode === 13) {
      await this.checkOtp()
    }
  }

  render () {
    const { otp, newPassword, checkNewPassword } = this.state
    return (
      <>
        {otp &&
          <Loginbox>
            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><FaArrowLeft style={{ cursor: 'pointer' }} onClick={() => this.props.navigate('/')} /></div>
            <Logo src={logo} />
            <br />
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}><FaKey /> Reset Password</div>
            <LoginLabel type='text' placeholder='Email' onChange={(e) => this.setState({ email: e.target.value })} />
            <LoginLabel type='text' placeholder='OTP - codice a 6 cifre' onKeyDown={e => this.handleKeyDownOtp(e)} onChange={(e) => this.setState({ codiceOtp: e.target.value })} />
            <LoginFormButton type='primary' outline='true' onClick={async () => await this.checkOtp()}>Conferma</LoginFormButton>
          </Loginbox>}
        {!otp &&
          <Loginbox>
            <Logo src={logo} />
            <br />
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}><FaKey /> Cambio Password</div>
            <LoginLabel type='password' placeholder='Nuova password' value={newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
            <LoginLabel type='password' placeholder='Ripeti nuova password' onKeyDown={e => this.handleKeyDown(e)} value={checkNewPassword} onChange={(e) => this.setState({ checkNewPassword: e.target.value })} />
            <LoginFormButton type='primary' outline='true' onClick={async () => await this.resetPassword()}>Conferma</LoginFormButton>
          </Loginbox>}
      </>
    )
  }
}
