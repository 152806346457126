import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api } from '../../Services'
import Loader from '../../Library/Tools/Loader'
import { mockupArticoli } from './MockupArticoli'
import upload from '../../Assets/upload.svg'

export default class ModificaArticolo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      _id: '',
      referenza: '',
      titolo: '',
      prezzoAcquisto: '',
      produttore: '',
      fornitore: '...',
      settore: '',
      descrizione: '',
      qtaMinStock: 0,
      qta: 0,
      immagineUrl: '',
      dataOra: '',
      produttori: [],
      fornitori: [],
      settori: [],
      azienda: '',
      deprecated: false
    }
  }

  async componentDidMount () {
    try {
      const { azienda, _id, referenza, titolo, qtaMinStock, prezzoAcquisto, produttore, fornitore, descrizione, settore, dataOra, immagineUrl, deprecated, qta } = this.props.location.state
      this.setState({ azienda, referenza, _id, titolo, qtaMinStock, prezzoAcquisto, produttore, fornitore, settore, descrizione, dataOra, immagineUrl, deprecated, qta })
      const produttori = await Api.get('/produttori', { azienda })
      const settori = await Api.get('/settori', { azienda })
      const fornitori = await Api.get('/fornitori', { azienda })
      if (produttori.ok && settori.ok && fornitori.ok) {
        this.setState({ produttori: produttori.data, settori: settori.data, fornitori: fornitori.data, loading: false })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Errore durante il recupero dei dati')
    }
  }

  async aggiornaArticolo () {
    const { _id, azienda, referenza, titolo, qtaMinStock, prezzoAcquisto, produttore, fornitore, settore, descrizione, immagineUrl, deprecated, qta } = this.state
    swaller.info('Salvataggio in corso...')
    const res = await Api.patch(`/articoli/${_id}`, {
      referenza,
      titolo,
      prezzoAcquisto,
      qtaMinStock,
      qta,
      produttore,
      fornitore,
      settore,
      descrizione,
      immagineUrl,
      dataOra: new Date().toISOString(),
      azienda,
      deprecated
    })
    if (res.ok) {
      swaller.success('Articolo salvato correttamente!')
      setTimeout(() => this.props.navigate('/articoli'), 2000)
    } else {
      swaller.error('Errore durante la creazione articolo, prego riprovare!')
    }
  }

  async handleFileSelect (event) {
    try {
      const files = event.target.files
      let immagineUrl = ''
      for (const file of files) {
        const res = await Api.upload(file)
        immagineUrl = res.data.file.url
      }
      this.setState({ immagineUrl })
    } catch (error) {
      swaller.error('Si è verificato un errore durante il caricamento delle immagini. Prego riprovare!')
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/articoli')
    }
  }

  render () {
    const { loading, produttori, fornitori, settori, produttore, fornitore, settore, immagineUrl, deprecated } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.aggiornaArticolo()}><FaIcons.FaCheck />Aggiorna Articolo</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content center='space-around'>
          <ContentStyle.FormColumn width='30%'>
            {mockupArticoli.map((field) => (
              <ContentStyle.Row key={field.key}>
                <ContentStyle.Label>{field.label}:</ContentStyle.Label>
                <ContentStyle.Text type={field.type} placeholder={field.label.toLowerCase()} value={this.state[field.key]} onChange={(e) => this.setState({ [field.key]: e.target.value })} />
              </ContentStyle.Row>
            ))}
          </ContentStyle.FormColumn>
          <ContentStyle.FormColumn width='30%'>
            <ContentStyle.Row>
              <ContentStyle.Label>Deprecated: </ContentStyle.Label>
              <ContentStyle.Select value={deprecated} onChange={(e) => this.setState({ deprecated: e.target.value })}>
                <option value>SI</option>
                <option value={false}>NO</option>
              </ContentStyle.Select>
            </ContentStyle.Row>
            {[
              { label: 'Produttore', state: produttore, list: produttori },
              { label: 'Fornitore', state: fornitore, list: fornitori },
              { label: 'Settore', state: settore, list: settori }
            ].map((item, index) => (
              <ContentStyle.Row key={index}>
                <ContentStyle.Label>{item.label}:</ContentStyle.Label>
                <ContentStyle.Select value={item.state} onChange={(e) => this.setState({ [item.label.toLowerCase()]: e.target.value })}>
                  <option value=''>...</option>
                  {item.list.length > 0 && item.list.map((listItem, i) => (
                    <option value={listItem._id} key={i}>
                      {listItem[item.label.toLowerCase()]}
                    </option>
                  ))}
                </ContentStyle.Select>
              </ContentStyle.Row>
            ))}
          </ContentStyle.FormColumn>
          <ContentStyle.FormColumn width='30%'>
            {immagineUrl !== '' &&
              <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <ContentStyle.BoxImmagine style={{ width: '256px', height: '256px', boxShadow: 'none' }} src={immagineUrl} alt='' />
                <Button type='danger' onClick={() => this.setState({ immagineUrl: '' })}>Elimina</Button>
              </div>}
            {immagineUrl === '' &&
              <label>
                <ContentStyle.Text id='files' style={{ width: '0px', visibility: 'hidden', display: 'block' }} type='file' accept='image/*' onChange={(e) => this.handleFileSelect(e)} />
                <ContentStyle.BoxImmagine width='256px' cursor='pointer' src={upload} alt='' />
              </label>}
            <i>Carica immagine articolo</i>
          </ContentStyle.FormColumn>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
