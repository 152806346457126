import styled from 'styled-components'
import { dark, font, medium, secondary } from '../Tools/Utilities'

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 94vw;
  height: 12vh;
  border-bottom: 1px solid #ccc;
`

export const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width:${({ width }) => width || '300px'};
`

export const Tree = styled.div`
  cursor: pointer;
  color: ${({ color }) => color ? `${dark}` : `${secondary}`};
  font-size: ${({ size }) => size ? font[size] : medium};
`

export const Delimiter = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  color: ${secondary};
  font-size: ${({ fontsize }) => fontsize || medium};
`

export const Active = styled.div`
  color: ${dark} !important;
  font-size: ${({ fontsize }) => fontsize || medium};
  font-weight: bold;
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  font-size: ${({ size }) => size ? font[size] : medium};
  font-weight: 500;
  gap: 10px;
`

export const Tools = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  width:${({ width }) => width || '300px'};
  font-size: ${({ size }) => size ? font[size] : medium};
  font-weight: 300;
`
