import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Trow, Tcol, Tbody } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { columnFatturaPreventivo, columnFatturaRichiesta } from '../TitoliColonne'
import Loader from '../../Library/Tools/Loader'

export default class NuovaFattura extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      nFattura: '',
      importo: 0,
      cliente: null,
      dataOra: '',
      clienti: [],
      dataClienti: [],
      preventivi: [],
      richieste: [],
      resoconti: [],
      azienda: '',
      ricavi: [],
      totale: 0,
      totalePreFattura: 0
    }
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      const [preventivi, richieste, dataClienti, ricavi] = await Promise.all([
        Api.get('/preventivi', { azienda, stato: '3' }),
        Api.get('/richieste', { azienda, stato: '3' }),
        Api.get('/users', { azienda, role: 'cliente' }),
        Api.get('/ricavi', { azienda })
      ])
      if (preventivi.ok && richieste.ok && dataClienti.ok && ricavi.ok) {
        const clienti = [...preventivi.data, ...richieste.data]
        const clientiUnici = clienti.reduce((acc, elemento) => {
          return acc.some(el => el.cliente === elemento.cliente) ? acc : [...acc, elemento]
        }, [])
        this.setState({ azienda, dataClienti: dataClienti.data, clienti: clientiUnici, richieste: richieste.data, preventivi: preventivi.data, ricavi: ricavi.data, loading: false })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante il recupero dei dati')
    }
  }

  getCliente (_id) {
    const { dataClienti } = this.state
    const foundCliente = dataClienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  async generaCodice () {
    const { azienda } = this.state
    const fatture = await Api.get('/fatture', { azienda })
    const codice = fatture.ok
      ? fatture.data.length + 1
      : new Date().unix()
    this.setState({ nFattura: codice })
    return codice
  }

  async salvaFattura (obj) {
    try {
      swaller.info('Salvataggio in corso...')
      const res = await Api.post('/fatture', obj)
      if (res.ok) {
        swaller.success('Fattura salvata correttamente!')
      } else {
        swaller.error('Errore durante la creazione della fattura, prego riprovare!')
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante il salvataggio della fattura')
    }
  }

  async generaFattura (valore, tipo) {
    try {
      const { azienda, cliente, ricavi } = this.state
      const conferma = await swaller.question('Si desidera generare la fattura per l\'elemento selezionato?')
      if (!conferma) return
      const codice = await this.generaCodice()
      swaller.info(`Procedura fattura N. ${codice}`)
      const resResoconto = await Api.get('/resoconti', { referenza: valore })
      let totaleMateriale = 0
      let totaleServizi = 0
      const dettaglioResoconto = []
      resResoconto.data.forEach(resoconto => {
        dettaglioResoconto.push({ tecnico: resoconto.tecnico, descrizione: resoconto.descrizione })
        resoconto.carrelloMateriale.forEach(materiale => {
          const ricarico = ricavi.find(e => e.settore === materiale.settore && e.tipo === 'Articoli')
          totaleMateriale += (((materiale.prezzoAcquisto * ricarico.ricarico) / 100) + materiale.prezzoAcquisto) * materiale.qta
        })
        resoconto.carrelloServizi.forEach(servizi => {
          const ricarico = ricavi.find(e => e.settore === servizi.settore && e.tipo === 'Servizi')
          totaleServizi += (((servizi.costoOrario * ricarico.ricarico) / 100) + servizi.costoOrario) * servizi.qta
        })
      })
      let importo = 0
      if (tipo === 'preventivo') {
        const resPreventivo = await Api.get('/preventivi', { _id: valore })
        if (!resPreventivo.ok || resPreventivo.data.length === 0) {
          swaller.error('Errore durante il recupero del preventivo')
          return
        }
        importo = Number(resPreventivo.data[0].totale.toFixed(2))
      }
      const fatturaData = {
        tipo: tipo === 'preventivo' ? 'Preventivo' : 'Richiesta',
        numero: codice,
        cliente,
        azienda,
        saldata: 'false',
        dataOra: new Date().toISOString(),
        referenza: valore,
        importo,
        extraMateriale: Number(totaleMateriale.toFixed(2)),
        extraServizi: Number(totaleServizi.toFixed(2)),
        dettaglioResoconto
      }
      const res = await Api.post('/fatture', fatturaData)
      if (res.ok) {
        swaller.success('Fattura generata correttamente!')
        if (tipo === 'preventivo') {
          await this.changeStatus(valore, 'preventivo')
        } else {
          await this.changeStatus(valore, 'richiesta')
        }
      } else {
        swaller.error('Errore durante la creazione della fattura!')
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante la generazione della fattura')
    }
  }

  async changeStatus (referenza, tipo) {
    try {
      let endpoint
      let successMessage
      switch (tipo) {
        case 'preventivo':
          endpoint = `/preventivi/${referenza}`
          successMessage = 'Preventivo modificato con successo'
          break
        case 'richiesta':
          endpoint = `/richieste/${referenza}`
          successMessage = 'Richiesta fatturata con successo!'
          break
        default:
          swaller.error('Tipo non valido')
      }
      const res = await Api.patch(endpoint, { stato: 4 })
      if (res.ok) {
        swaller.success(successMessage)
      } else {
        swaller.error('Errore durante la modifica dello stato')
      }
    } catch (error) {
      swaller.error(`Si è verificato un errore durante la modifica dello stato di ${tipo}`)
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/fatture')
    }
  }

  render () {
    const { loading, clienti, cliente, preventivi, richieste } = this.state
    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft />Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content border='none' boxshadow='none' background='transparent'>
          <ContentStyle.InputAllPage>
            <ContentStyle.Title>Descrizione:</ContentStyle.Title>
            <ContentStyle.Row center='center'>
              <ContentStyle.Box>
                <ContentStyle.Label>Cliente: </ContentStyle.Label>
                {clienti.length > 0 &&
                  <ContentStyle.Select onChange={(e) => this.setState({ cliente: e.target.value })}>
                    <option value={null}>...</option>
                    {clienti.map((item, i) => (
                      <option value={item.cliente} key={i}>
                        {this.getCliente(item.cliente)}
                      </option>
                    ))}
                  </ContentStyle.Select>}
                {clienti.length === 0 && <i>Nessun cliente da fatturare</i>}
              </ContentStyle.Box>
            </ContentStyle.Row>
            <>
              <ContentStyle.Row>
                <ContentStyle.Box>
                  <ContentStyle.Label><b>Preventivi:</b></ContentStyle.Label>
                </ContentStyle.Box>
              </ContentStyle.Row>
              <ContentStyle.Row>
                {preventivi.length > 0 && preventivi.filter(el => el.cliente === cliente).length > 0
                  ? (
                    <ContentStyle.Table>
                      <Table>
                        <Thead>
                          <Trow>
                            {columnFatturaPreventivo.map((colonne, i) => {
                              return (
                                <Tcol key={i}>
                                  <b>{colonne.label}</b>
                                </Tcol>
                              )
                            })}
                          </Trow>
                        </Thead>
                        <Tbody>
                          {preventivi.filter(el => el.cliente === cliente).map((item, i) => {
                            return (
                              <Trow key={i}>
                                <Tcol>{item.numero}</Tcol>
                                <Tcol>{this.getCliente(item.cliente)}</Tcol>
                                <Tcol>
                                  <Button type='success' onClick={async () => await this.generaFattura(item._id, 'preventivo')}>Fattura</Button>
                                </Tcol>
                              </Trow>
                            )
                          })}
                        </Tbody>
                      </Table>
                    </ContentStyle.Table>
                    )
                  : (
                    <i>
                      Nessun preventivo da mostrare
                    </i>
                    )}
              </ContentStyle.Row>
              <hr />
              <ContentStyle.Row>
                <ContentStyle.Label><b>Richieste: </b></ContentStyle.Label>
              </ContentStyle.Row>
              <ContentStyle.Row>
                {richieste.length > 0 && richieste.filter(el => el.cliente === cliente).length > 0
                  ? (
                    <ContentStyle.Table>
                      <Table>
                        <Thead>
                          <Trow>
                            {columnFatturaRichiesta.map((colonne, i) => {
                              return (
                                <Tcol key={i}>
                                  <b>{colonne.label}</b>
                                </Tcol>
                              )
                            })}
                          </Trow>
                        </Thead>
                        <Tbody>
                          {richieste.filter(el => el.cliente === cliente).map((item, i) => {
                            return (
                              <Trow key={i}>
                                <Tcol>{item.titolo}</Tcol>
                                <Tcol>{this.getCliente(item.cliente)}</Tcol>
                                <Tcol style={{ textAlign: 'center' }}>
                                  <Button type='success' onClick={async () => await this.generaFattura(item._id, 'richiesta')}>Fattura</Button>
                                </Tcol>
                              </Trow>
                            )
                          })}
                        </Tbody>
                      </Table>
                    </ContentStyle.Table>
                    )
                  : (
                    <i>
                      Nessuna richiesta da mostrare
                    </i>
                    )}
              </ContentStyle.Row>
            </>
          </ContentStyle.InputAllPage>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
