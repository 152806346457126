import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, ModalLabel, Modal, ModalIcon, ModalTitle, ModalContent } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import { columnArticoli } from './MockupArticoli'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { Config } from '../../Global'
import CopyToClipboard from 'react-copy-to-clipboard'
import Loader from '../../Library/Tools/Loader'
import { FaArrowCircleLeft, FaArrowLeft, FaArrowRight, FaCopy, FaEdit, FaInfo, FaShoppingCart, FaTimes } from 'react-icons/fa'
/* import { ModalImageContainer } from '../../Components/Modal' */

export default class Articoli extends Component {
  constructor (props) {
    super(props)
    this.state = {
      itemPerPage: 6,
      currentPage: 0,
      loading: true,
      articoli: [],
      data: [],
      show: false,
      content: [],
      settore: [],
      produttore: [],
      fornitore: [],
      azienda: '',
      immagineUrl: ''
    }
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      this.setState({ azienda })
      const articoliResponse = await Api.get('/articoli', { azienda })
      if (!articoliResponse.ok) {
        swaller.error('Non è stato possibile recuperare gli articoli')
      }
      const produttoriResponse = await Api.get('/produttori', { azienda })
      const settoriResponse = await Api.get('/settori', { azienda })
      const fornitoriResponse = await Api.get('/fornitori', { azienda })
      if (produttoriResponse.ok && settoriResponse.ok && fornitoriResponse.ok) {
        this.setState({ articoli: articoliResponse.data, data: articoliResponse.data, content: articoliResponse.data, produttore: produttoriResponse.data, settore: settoriResponse.data, fornitore: fornitoriResponse.data, loading: false })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Errore nel caricamento dei dati')
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageArticoli = data.slice(startIndex, endIndex)
    return currentPageArticoli
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  filter (search, value) {
    const { articoli } = this.state
    search === ''
      ? this.setState({ data: articoli })
      : this.setState({ data: articoli.filter(item => item[value].toLowerCase().includes(search.toLowerCase())) })
  }

  getInfo (_id, value, field) {
    const items = this.state[value]
    const foundItem = items.find(item => item._id === _id)
    if (field) {
      return foundItem ? foundItem[field] : ''
    } else {
      return foundItem ? foundItem[value] : ''
    }
  }

  infoBox (stato, item, immagineUrl) {
    this.setState({ show: stato, content: item, immagineUrl })
  }

  async handleKeyDown (event) {
    if (event.keyCode === 13) {
      this.searchName()
    }
  }

  render () {
    const { data, loading, articoli, show, content, settore, /* immagineUrl, */ currentPage, itemPerPage } = this.state
    const totalPages = Math.ceil(articoli.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'titolo' || 'referenza')} />
            <ContentStyle.Select onChange={(e) => this.filter(e.target.value, 'settore')}>
              <option value=''>Tutti i settori</option>
              {settore.length > 0 && settore.map((item) => (
                <option value={item._id} key={item._id}>{item.settore}</option>))}
            </ContentStyle.Select>
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/articoli/nuovo')}><IconWrapper><FaShoppingCart /></IconWrapper><span>Nuovo articolo</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnArticoli.map((colonne) => (
                    <Tcol key={colonne.id}>
                      <b>{colonne.label}</b>
                    </Tcol>
                  ))}
                </Thead>
                <Tbody>
                  {this.showData().map((item) => {
                    return (
                      <Trow key={item._id}>
                        <Tcol>
                          <CopyToClipboard text={item.referenza} onCopy={() => swaller.info(`${(item.referenza).toUpperCase()} copiato negli appunti!`)}>
                            <span style={{ color: palette.purple, cursor: 'pointer' }}><FaCopy /> {item.referenza}</span>
                          </CopyToClipboard>
                        </Tcol>
                        <Tcol>{item.titolo}</Tcol>
                        <Tcol>{Config.format(item.prezzoAcquisto)}</Tcol>
                        {['produttore', 'settore'].map((infoType) => (
                          <Tcol key={infoType}>{this.getInfo(item[infoType], infoType)}</Tcol>
                        ))}
                        <Tcol>{item.qtaMinStock}</Tcol>
                        <Tcol>{item.deprecated ? 'SI' : 'NO'}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/articoli/modifica', { state: item, replace: false })} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.infoBox(true, item, item.immagineUrl)} />
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Non ci sono articoli</i>}
          <ContentStyle.PaginationContainer>
            <Button minwidth='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaArrowLeft /></Button>
            <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
            <Button minwidth='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaArrowRight /></Button>
          </ContentStyle.PaginationContainer>
        </ContentStyle.Content>
        <Modal visible={show}>
          <ModalIcon onClick={() => this.setState({ show: false })}><FaTimes size={48} /></ModalIcon>
          <ModalTitle>Informazioni</ModalTitle>
          <ModalContent align='center'>
            {[{ label: 'Titolo', value: content.titolo },
              { label: 'Prezzo di acquisto', value: Config.format(content.prezzoAcquisto) },
              { label: 'Quantità minima stock', value: content.qtaMinStock },
              { label: 'Quantità', value: content.qta },
              { label: 'Produttore', value: this.getInfo(content.produttore, 'produttore') },
              { label: 'Fornitore', value: this.getInfo(content.fornitore, 'fornitore') },
              { label: 'Settore', value: this.getInfo(content.settore, 'settore') },
              { label: 'Descrizione', value: content.descrizione },
              { label: 'Ultima modifica', value: new Date(content.dataOra).toLocaleString('it-IT') }
            ].map((item, index) => (
              <ModalLabel key={index} position='center'>
                <b>{item.label}:</b> {item.value}
              </ModalLabel>
            ))}
            <br />
            {/* <ModalImageContainer>
              <ContentStyle.BoxImmagine src={immagineUrl !== '' ? immagineUrl : null} alt='' />
            </ModalImageContainer> */}
          </ModalContent>
        </Modal>
      </Loader>
    )
  }
}
