import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { mockupFornitori } from './MockupFornitori'

export default class NuovoFornitore extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fornitore: '',
      email: '',
      telefono: '',
      indirizzo: '',
      citta: '',
      cap: '',
      nazione: '',
      pIva: '',
      pec: '',
      codiceUnivoco: '',
      azienda: '',
      nomeAzienda: ''
    }
  }

  componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    this.setState({ azienda })
  }

  async salvaFornitore () {
    const { fornitore, email, telefono, indirizzo, citta, cap, nazione, pIva, pec, codiceUnivoco, azienda } = this.state
    if (!fornitore || !email || !telefono || !indirizzo || !citta || !cap || !nazione || !pIva || !pec || !codiceUnivoco) {
      swaller.error('Compilare i campi obbligatori e riprovare!')
    } else {
      swaller.info('Salvataggio in corso...')
      const res = await Api.post('/fornitori', {
        fornitore,
        email,
        telefono,
        indirizzo,
        citta,
        cap,
        nazione,
        pIva,
        pec,
        codiceUnivoco,
        azienda
      })
      if (res.ok) {
        swaller.success('Fornitore salvato correttamente!')
        setTimeout(() => this.props.navigate('/fornitori'), 2000)
      } else {
        swaller.error('Errore durante la creazione fornitore, prego riprovare!')
      }
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/fornitori')
    }
  }

  render () {
    const dimensioneParte = Math.ceil(mockupFornitori.length / 2)

    return (
      <>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.salvaFornitore()}><FaIcons.FaCheck />Salva Fornitore</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form flexdirection='row' width='100%'>
            {Array.from({ length: 2 }, (_, indiceColonna) => (
              <ContentStyle.FormColumn key={indiceColonna}>
                {mockupFornitori.slice(indiceColonna * dimensioneParte, (indiceColonna + 1) * dimensioneParte).map((field) => (
                  <ContentStyle.Row width='65%' key={field.key}>
                    <ContentStyle.Label><b>{field.label}:</b></ContentStyle.Label>
                    <ContentStyle.Text
                      type={field.type}
                      placeholder={field.label.toLowerCase()}
                      value={this.state[field.key]}
                      onChange={(e) => this.setState({ [field.key]: e.target.value })}
                    />
                  </ContentStyle.Row>
                ))}
              </ContentStyle.FormColumn>
            ))}
          </ContentStyle.Form>
        </ContentStyle.Content>
      </>
    )
  }
}
