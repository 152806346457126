import React, { Component } from 'react'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import { QRCodeSVG } from 'qrcode.react'
import { FaArrowLeft, FaKey } from 'react-icons/fa'
import { LoginFormButton, Loginbox, Logo, LoginLabel } from '../../Components/Layout'
import logo from '../../Assets/tecnel.jpg'

export default class FirstOtp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      qrcode: '',
      codiceOtp: ''
    }
  }

  async componentDidMount () {
    const { _id } = LocalStorage.get('_id')
    const res = await Api.get(`/users/${_id}`)
    const user = res.data || {}
    const secrets = user.secrets
    if (secrets && secrets.totp) {
      await this.getQrcode()
    } else {
      await this.getSecret()
    }
  }

  async getSecret () {
    const res = await Api.get('/otp/secret')
    if (!res.ok) {
      swaller.error('Non è stato possbile recuperare il qrcode')
    } else {
      this.setState({ qrcode: res.data })
    }
  }

  async getQrcode () {
    const res = await Api.get('/otp/qrcode')
    if (!res.ok) {
      swaller.error('Non è stato possbile recuperare il qrcode')
    } else {
      this.setState({ qrcode: res.data })
    }
  }

  async setOtp () {
    await swaller.info('Salvataggio OTP in corso')
    const { codiceOtp } = this.state
    const res = await Api.post('otp', { otp: codiceOtp })
    if (!res.ok) {
      swaller.error('OTP non valido')
    } else {
      swaller.success('OTP corretto')
      const _id = LocalStorage.get('_id')
      const response = await Api.patch(`/users/${_id}`, { otp: true })
      if (response.ok) {
        swaller.success('OTP impostato correttamente')
        setTimeout(() => this.props.navigate('/'), 2000)
      } else {
        swaller.error('Utente non aggiornato')
      }
    }
  }

  async handleKeyDown (event) {
    if (event.keyCode === 13) {
      await this.setOtp()
    }
  }

  render () {
    const { codiceOtp, qrcode } = this.state
    return (
      <>
        <Loginbox>
          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><FaArrowLeft style={{ cursor: 'pointer' }} onClick={() => this.props.navigate('/')} /></div>
          <Logo src={logo} />
          <br />
          <div style={{ fontSize: '24px', fontWeight: 'bold' }}><FaKey /> OTP</div>
          <br />
          <QRCodeSVG value={qrcode} />
          <LoginLabel type='text' placeholder='OTP' onKeyDown={e => this.handleKeyDown(e)} value={codiceOtp} onChange={(e) => this.setState({ codiceOtp: e.target.value })} />
          <LoginFormButton type='primary' outline='true' onClick={async () => await this.setOtp()}>Salva</LoginFormButton>
        </Loginbox>
      </>
    )
  }
}
