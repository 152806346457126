import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

function WithNavigate ({ Component }) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  return <Component navigate={navigate} location={location} params={params} />
}

export default WithNavigate
