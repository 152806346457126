export const primary = '#0d6efd'
export const secondary = '#6c757d'
export const success = '#28a745'
export const danger = '#dc3545'
export const warning = '#ffc107'
export const info = '#17a2b8'
export const light = '#f8f9fa'
export const dark = '#343a40'
export const orange = '#fd7e14'
export const purple = '#8232aa'

export const large = '24px'
export const medium = '20px'
export const small = '18px'

export const day = '#eee'
export const night = '#212529'

export const card = '0 0 10px 0 rgb(0, 0, 0, 0.15)'
export const toolbar = '0 5px 10px 0 rgb(0, 0, 0, 0.3)'

export const palette = {}
palette.primary = primary
palette.secondary = secondary
palette.success = success
palette.danger = danger
palette.warning = warning
palette.info = info
palette.light = light
palette.dark = dark
palette.purple = purple
palette.orange = orange

export const font = {}
font.large = large
font.medium = medium
font.small = small

export const shadow = {}
shadow.card = card
shadow.toolbar = toolbar
