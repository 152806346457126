// const API_BASE_URL = 'https://emsapi.tecnelmc.com'
const API_BASE_URL = 'http://85.235.146.233:3030'
// const API_BASE_ADDR = 'https://ems.tecnelmc.com'

const Config = {
  STORAGE_KEY: 'EMSTECNEL',
  API_BASE_URL,
  appVersion: require('../../package.json').version,
  format
}

function format (value) {
  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  return formatter.format(value)
}

export default Config
