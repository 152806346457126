import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Tcol, Tbody, Trow, Table, Thead, BoxInfo, BoxContent, BoxRow } from '../../Components'
import { Button, CloseButton } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import Loader from '../../Library/Tools/Loader'
import { columnAggiuntaCarrello, columnAggiuntaServizi, columnResoconto, columnResocontoCarrello } from '../Resoconti/MockupResoconti'
import { palette } from '../../Library/Tools/Utilities'
import { BoxAggiuntaMateriale } from '../../Components/ModalInfo'

export default class AggiungiResoconto extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      _id: '',
      numero: 0,
      titolo: '',
      cliente: '...',
      settore: '...',
      tecnico: '...',
      carrelloMateriale: [],
      carrelloServizi: [],
      azienda: '',
      tecnici: [],
      clienti: [],
      dataInizio: '',
      dataFine: '',
      descrizione: '',
      servizi: [],
      articoli: [],
      showBoxAggiuntaMateriale: false,
      showBoxAggiuntaServizi: false,
      showInfoBox: false,
      infoValue: {},
      tipo: ''
    }
  }

  async componentDidMount () {
    const { _id, numero, titolo, cliente, tecnico, stato, pathname } = this.props.location.state
    let tipo = ''
    if (titolo) {
      tipo = 'Richiesta'
    } else if (numero) {
      tipo = 'Preventivo'
    }
    const { azienda } = LocalStorage.get('user')
    this.setState({ _id, numero, titolo, cliente, tecnico, stato, pathname, azienda, tipo }, async () => {
      await this.loadData()
    })
  }

  async creaResoconto () {
    const { _id, numero, titolo, tipo, cliente, tecnico, dataInizio, dataFine, descrizione, azienda, carrelloMateriale, carrelloServizi } = this.state
    const resResoconto = await Api.post('/resoconti', {
      referenza: _id,
      titolo: tipo === 'Preventivo' ? numero : titolo,
      tipo,
      cliente,
      tecnico,
      dataInizio,
      dataFine,
      descrizione,
      carrelloMateriale,
      carrelloServizi,
      creatoDa: LocalStorage.get('user')._id,
      oraCreazione: new Date().toISOString(),
      azienda

    })
    if (resResoconto.ok) {
      swaller.success('Resoconto creato correttamente!')
      setTimeout(() => this.backPage('save'), 2000)
    } else {
      swaller.error('Errore durante la creazione del resoconto, prego riprovare!')
    }
  }

  async loadData () {
    const { azienda } = this.state
    const articoli = await Api.get('/articoli', { azienda })
    const servizi = await Api.get('/servizi', { azienda })
    const magazzino = await Api.get('/magazzino', { azienda })
    const produttori = await Api.get('/produttori', { azienda })
    const settori = await Api.get('/settori', { azienda })
    const fornitori = await Api.get('/fornitori', { azienda })
    const clienti = await Api.get('/users', { azienda, role: 'cliente' })
    const tecnici = await Api.get('/users', { azienda, role: 'utente' })
    produttori.ok && settori.ok && fornitori.ok && articoli.ok && magazzino.ok && tecnici.ok && clienti.ok && servizi.ok
      ? this.setState({ articoli: articoli.data, servizi: servizi.data, data: articoli.data, magazzino: magazzino.data, produttore: produttori.data, settore: settori.data, fornitore: fornitori.data, clienti: clienti.data, tecnici: tecnici.data }, () => this.setState({ loading: false }))
      : swaller.error('Non è stato possibile recuperare i dati')
  }

  async backPage (type) {
    let res = false
    if (type !== 'save') {
      res = await swaller.question('Sicuro di voler uscire?')
    } else {
      res = true
    }
    if (res) {
      if (this.state.tipo === 'Preventivo') {
        this.props.navigate('/preventivi/resoconti', { state: { _id: this.props.location.state._id }, replace: false })
      } else {
        this.props.navigate('/richieste/resoconti', { state: { _id: this.props.location.state._id }, replace: false })
      }
    }
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  getTecnico (_id) {
    const { tecnici } = this.state
    const tecnico = tecnici.find(tecnico => tecnico._id === _id)
    return tecnico ? `${tecnico.nome} ${tecnico.cognome}` : ''
  }

  getInfo (_id, value, field) {
    const items = this.state[value]
    const text = items.find(item => item._id === _id)
    if (field) {
      return text ? text[field] : ''
    } else {
      return text ? text[value] : ''
    }
  }

  addCarrelloMateriale (item) {
    const { carrelloMateriale } = this.state
    const check = carrelloMateriale.includes(item)
    if (check) {
      swaller.warning('Articolo già aggiunto')
    } else {
      item.qta = 0
      carrelloMateriale.push(item)
      this.setState({ carrelloMateriale })
    }
  }

  addCarrelloServizi (item) {
    const { carrelloServizi } = this.state
    const check = carrelloServizi.includes(item)
    if (check) {
      swaller.warning('Articolo già aggiunto')
    } else {
      item.qta = 0
      carrelloServizi.push(item)
      this.setState({ carrelloServizi })
    }
  }

  async removeCarrelloMateriale (index) {
    const { carrelloMateriale } = this.state
    const res = await swaller.question('Sicuro di voler eliminare ?')
    if (res) {
      carrelloMateriale.splice(index, 1)
      this.setState({ carrelloMateriale })
    }
  }

  async removeCarrelloServizi (index) {
    const { carrelloServizi } = this.state
    const res = await swaller.question('Sicuro di voler eliminare ?')
    if (res) {
      carrelloServizi.splice(index, 1)
      this.setState({ carrelloServizi })
    }
  }

  handleQuantityMateriale (index, qta) {
    const { carrelloMateriale } = this.state
    carrelloMateriale[index].qta = qta >= 0
      ? Number(qta)
      : 0
    this.setState({ carrelloMateriale })
  }

  handleQuantityServizi (index, qta) {
    const { carrelloServizi } = this.state
    carrelloServizi[index].qta = qta >= 0
      ? Number(qta)
      : 0
    this.setState({ carrelloServizi })
  }

  async changeStatusPreventivo (status) {
    const { _id } = this.state
    let accettato = false
    swaller.info('Modifica stato preventivo in corso')
    if (status === '1') {
      accettato = true
    }
    const res = await Api.patch(`/preventivi/${_id}`, {
      stato: status,
      accettato
    })
    if (res) {
      swaller.success('Preventivo modificato con successo')
    } else {
      swaller.error('Errore durante la modifica dello stato preventivo')
    }
  }

  async changeStatusRichiesta (status) {
    const { _id } = this.state
    swaller.info('Modifica stato richiesta in corso')
    let resRichieste = ''
    if (Number(status) === 3) {
      resRichieste = await Api.patch(`/richieste/${_id}`, {
        stato: status,
        chiusuraDataOra: new Date().toISOString(),
        modificataDa: LocalStorage.get('user')._id,
        modificaData: new Date().toISOString()
      })
    } else {
      resRichieste = await Api.patch(`/richieste/${_id}`, {
        stato: status,
        chiusuraDataOra: '',
        modificataDa: LocalStorage.get('user')._id,
        modificaData: new Date().toISOString()
      })
    }
    if (resRichieste.ok) {
      swaller.success('Resoconto aggiunto con successso')
    } else {
      swaller.error('Errore durante l\'aggiunta del resoconto, prego riprovare!')
    }
  }

  format (value) {
    return value.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
  }

  render () {
    const { loading, tecnico, descrizione, tecnici, showInfoBox, infoValue, articoli, carrelloMateriale, carrelloServizi, servizi, showBoxAggiuntaMateriale, showBoxAggiuntaServizi, tipo } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <Button type='success' outline='true' onClick={async () => await this.creaResoconto()}><FaIcons.FaCheckCircle />Crea resoconto</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content overflow='scroll' border='none' boxshadow='none' background='transparent'>
          <ContentStyle.InputAllPage>
            <br />
            <br />
            <ContentStyle.Title>Resoconto: </ContentStyle.Title>
            <ContentStyle.Row style={{ justifyContent: 'center' }}>
              <ContentStyle.Box>
                <ContentStyle.Label>Tecnico: </ContentStyle.Label>
                <ContentStyle.Select value={tecnico} onChange={(e) => this.setState({ tecnico: e.target.value })}>
                  <option value='...'>...</option>
                  {tecnici && tecnici.length > 0 && tecnici.map((item, i) => {
                    return (
                      <option value={item._id} key={i}>
                        {item.nome} {item.cognome}
                      </option>
                    )
                  })}
                </ContentStyle.Select>
                <ContentStyle.Label>Data inizio attività: </ContentStyle.Label>
                <input type='datetime-local' onChange={e => this.setState({ dataInizio: e.target.value })} />
                <ContentStyle.Label>Data fine attività: </ContentStyle.Label>
                <input type='datetime-local' onChange={e => this.setState({ dataFine: e.target.value })} />
              </ContentStyle.Box>
            </ContentStyle.Row>
            <hr />
            <ContentStyle.Row>
              <ContentStyle.Box style={{ width: '100%' }}>
                <ContentStyle.Label>Descrizione: </ContentStyle.Label>
                <ContentStyle.Textarea placeholder='descrizione' value={descrizione} onChange={(e) => e.target.value != null && this.setState({ descrizione: e.target.value })} />
              </ContentStyle.Box>
            </ContentStyle.Row>
            <hr />
            <ContentStyle.Row>
              <ContentStyle.Box>
                <ContentStyle.Label>Materiale extra: </ContentStyle.Label>
                {articoli.length > 0 && <Button type='info' outline='true' onClick={() => this.setState({ showBoxAggiuntaMateriale: true })}><FaIcons.FaPlusCircle />Aggiungi materiale</Button>}
              </ContentStyle.Box>
            </ContentStyle.Row>
            <ContentStyle.Table>
              {carrelloMateriale.length > 0 &&
                <Table>
                  <Thead>
                    <Trow>
                      {columnResocontoCarrello.map((colonne, i) => {
                        return (
                          <Tcol key={i}>
                            <b>{colonne.label}</b>
                          </Tcol>
                        )
                      })}
                    </Trow>
                  </Thead>
                  <Tbody>
                    {carrelloMateriale.map((item, i) => {
                      return (
                        <Trow key={i}>
                          <Tcol>{this.getInfo(item.settore, 'settore')}</Tcol>
                          <Tcol>{item.referenza}</Tcol>
                          <Tcol>{tipo === 'Preventivo' ? `${item.numero}` : `${item.titolo}`}</Tcol>
                          <Tcol>{this.getInfo(item.fornitore, 'fornitore')}</Tcol>
                          <Tcol>{this.getInfo(item.produttore, 'produttore')}</Tcol>
                          <Tcol style={{ textAlign: 'center' }}>
                            <input style={{ width: 50 }} type='number' value={item.qta} onChange={(e) => this.handleQuantityMateriale(i, e.target.value)} />
                          </Tcol>
                          <Tcol style={{ textAlign: 'center' }}>
                            <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: item })} />
                          </Tcol>
                          <Tcol style={{ textAlign: 'center' }}>
                            <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.removeCarrelloMateriale(i)} />
                          </Tcol>
                        </Trow>
                      )
                    })}
                  </Tbody>
                </Table>}
              {carrelloMateriale.length === 0 && <i style={{ margin: 'auto' }}>Nessun prodotto aggiunto</i>}
            </ContentStyle.Table>
            <hr />
            <ContentStyle.Row>
              <ContentStyle.Box>
                <ContentStyle.Label>Servizi extra: </ContentStyle.Label>
                {servizi.length > 0 && <Button type='info' outline='true' onClick={() => this.setState({ showBoxAggiuntaServizi: true })}><FaIcons.FaPlusCircle />Aggiungi servizi</Button>}
              </ContentStyle.Box>
            </ContentStyle.Row>
            <ContentStyle.Table>
              {carrelloServizi.length > 0 &&
                <Table>
                  <Thead>
                    <Trow>
                      {columnResoconto.map((colonne, i) => {
                        return (
                          <Tcol key={i}>
                            <b>{colonne.label}</b>
                          </Tcol>
                        )
                      })}
                    </Trow>
                  </Thead>
                  <Tbody>
                    {carrelloServizi.map((item, i) => {
                      return (
                        <Trow key={i}>
                          <Tcol>{item.titolo}</Tcol>
                          <Tcol>{item.descrizione}</Tcol>
                          <Tcol>{this.format(item.costoOrario)}</Tcol>
                          <Tcol style={{ textAlign: 'center' }}>
                            <input style={{ width: 50 }} type='number' value={item.qta} onChange={(e) => this.handleQuantityServizi(i, e.target.value)} />
                          </Tcol>
                          <Tcol style={{ textAlign: 'center' }}>
                            <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.removeCarrelloServizi(i)} />
                          </Tcol>
                        </Trow>
                      )
                    })}
                  </Tbody>
                </Table>}
              {carrelloServizi.length === 0 && <i style={{ margin: 'auto' }}>Nessun servizio aggiunto</i>}
            </ContentStyle.Table>
          </ContentStyle.InputAllPage>
          <BoxInfo showInfoBox={showInfoBox}>
            <BoxContent>
              <Toolbar>
                <CloseButton>
                  <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: false, infoValue: {} })} />
                </CloseButton>
              </Toolbar>
              <ContentStyle.Title>Descrizione:</ContentStyle.Title>
              <hr />
              <BoxRow>Referenza: {infoValue.referenza}</BoxRow>
              <BoxRow>Titolo: {infoValue.titolo}</BoxRow>
              <BoxRow>Quantità stock: {infoValue.qtaStock}</BoxRow>
              <BoxRow>Quantità minima stock: {infoValue.qtaMinStock}</BoxRow>
              <ContentStyle.Row style={{ display: 'flex', alignItems: 'center', gap: 10 }}>Descrizione: <ContentStyle.Textarea disabled value={infoValue.descrizione} /></ContentStyle.Row>
              <BoxRow>Immagine: {infoValue.immagine}</BoxRow>
              <BoxRow>Ultima modifica: {new Date(infoValue.dataOra).toLocaleString('it-IT')}</BoxRow>
            </BoxContent>
          </BoxInfo>
          <BoxAggiuntaMateriale show={showBoxAggiuntaMateriale}>
            <Toolbar paddingleft='30px' paddingright='30px'>
              <ToolbarItem>
                <h3>Selezionare gli articoli</h3>
                <ContentStyle.Search type='text' style={{ marginLeft: 10 }} placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'referenza')} />
              </ToolbarItem>
              <ToolbarItem isend='true'>
                <FaIcons.FaCheckSquare size={36} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.setState({ showBoxAggiuntaMateriale: false, search: '' })} />
              </ToolbarItem>
            </Toolbar>
            <br />
            <ContentStyle.Table>
              <Table>
                <Thead>
                  <Trow>
                    {columnAggiuntaCarrello.map((colonne, i) => {
                      return (
                        <Tcol key={i}>
                          <b>{colonne.label}</b>
                        </Tcol>
                      )
                    })}
                  </Trow>
                </Thead>
                <Tbody>
                  {articoli.map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{item.referenza}</Tcol>
                      <Tcol>{item.titolo}</Tcol>
                      <Tcol>{this.getInfo(item.fornitore, 'fornitore')}</Tcol>
                      <Tcol>{this.getInfo(item.produttore, 'produttore')}</Tcol>
                      <Tcol>{this.getInfo(item.settore, 'settore')}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        {carrelloMateriale.filter((e) => e._id === item._id).length === 0 && <FaIcons.FaPlusCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrelloMateriale(item)} />}
                        {carrelloMateriale.filter((e) => e._id === item._id).length > 0 && <FaIcons.FaCheckCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrelloMateriale(item)} />}
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: item })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
            </ContentStyle.Table>
          </BoxAggiuntaMateriale>
          <BoxAggiuntaMateriale show={showBoxAggiuntaServizi}>
            <Toolbar paddingleft='30px' paddingright='30px'>
              <ToolbarItem>
                <h3>Selezionare gli articoli</h3>
                <ContentStyle.Search type='text' style={{ marginLeft: 10 }} placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'referenza')} />
              </ToolbarItem>
              <ToolbarItem isend='true'>
                <FaIcons.FaCheckSquare size={36} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.setState({ showBoxAggiuntaServizi: false, search: '' })} />
              </ToolbarItem>
            </Toolbar>
            <br />
            <ContentStyle.Table>
              <Table>
                <Thead>
                  <Trow>
                    {columnAggiuntaServizi.map((colonne, i) => {
                      return (
                        <Tcol key={i}>
                          <b>{colonne.label}</b>
                        </Tcol>
                      )
                    })}
                  </Trow>
                </Thead>
                <Tbody>
                  {servizi.map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{item.titolo}</Tcol>
                      <Tcol>{item.descrizione}</Tcol>
                      <Tcol>{this.format(item.costoOrario)}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        {carrelloServizi.filter((e) => e._id === item._id).length === 0 && <FaIcons.FaPlusCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrelloServizi(item)} />}
                        {carrelloServizi.filter((e) => e._id === item._id).length > 0 && <FaIcons.FaCheckCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrelloServizi(item)} />}
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
            </ContentStyle.Table>
          </BoxAggiuntaMateriale>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
