import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    user-select: none;
    font-size: 22px;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: red; 
      border-radius: 24px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
    }
  }
`

export default GlobalStyle
