export const variabili = [
  {
    id: 'tipologie',
    name: 'tipologia',
    newName: 'newTipologia',
    title: 'Tipologia'

  },
  {
    id: 'produttori',
    name: 'produttore',
    newName: 'newProduttore',
    title: 'Produttori'

  },
  {
    id: 'settori',
    name: 'settore',
    newName: 'newSettore',
    title: 'Settori'

  }
]
