const columnCanoni = [
  {
    key: 'cliente',
    label: 'Cliente'
  },
  {
    key: 'descrizione',
    label: 'Descrizione'
  },
  {
    key: 'importo',
    label: 'Importo (€)'
  },
  {
    key: 'canone',
    label: 'Canone'
  },
  {
    key: 'scadenza',
    label: 'Scadenza'
  },
  {
    key: 'modifica',
    label: 'Modifica'
  }
]

export { columnCanoni }
