import * as FaIcons from 'react-icons/fa'
import { LittleIcon } from '../../Components'

const dashboard = [
  {
    color: 'purple',
    title: 'Aziende',
    icon: <FaIcons.FaCity style={LittleIcon} />,
    path: '/aziende',
    enabled: false
  },
  {
    color: 'info',
    title: 'Clienti',
    icon: <FaIcons.FaPeopleArrows style={LittleIcon} />,
    path: '/clienti',
    enabled: true
  },
  {
    color: 'success',
    title: 'Fornitori',
    icon: <FaIcons.FaPeopleCarry style={LittleIcon} />,
    path: '/fornitori',
    enabled: true
  },
  {
    color: 'secondary',
    title: 'Settaggi',
    icon: <FaIcons.FaToolbox style={LittleIcon} />,
    path: '/settaggi',
    enabled: false
  },
  {
    color: 'primary',
    title: 'Utenti',
    icon: <FaIcons.FaUser style={LittleIcon} />,
    path: '/utenti',
    enabled: false
  },
  {
    color: 'danger',
    title: 'Preventivi',
    icon: <FaIcons.FaWallet style={LittleIcon} />,
    path: '/preventivi',
    enabled: true
  },
  {
    color: 'info',
    title: 'Fatture',
    icon: <FaIcons.FaFileInvoiceDollar style={LittleIcon} />,
    path: '/fatture',
    enabled: true
  },
  {
    color: 'purple',
    title: 'Magazzino',
    icon: <FaIcons.FaWarehouse style={LittleIcon} />,
    path: '/magazzino',
    enabled: true
  },
  {
    color: 'orange',
    title: 'Scatole',
    icon: <FaIcons.FaBox style={LittleIcon} />,
    path: '/scatole',
    enabled: true
  },
  {
    color: 'warning',
    title: 'Richieste',
    icon: <FaIcons.FaRegQuestionCircle style={LittleIcon} />,
    path: '/richieste',
    enabled: true
  },
  {
    color: 'dark',
    title: 'Installazioni',
    icon: <FaIcons.FaTools style={LittleIcon} />,
    path: '/installazioni',
    enabled: true
  },
  {
    color: 'primary',
    title: 'Servizi',
    icon: <FaIcons.FaHandHoldingUsd style={LittleIcon} />,
    path: '/Servizi',
    enabled: true
  },
  {
    color: 'success',
    title: 'Articoli',
    icon: <FaIcons.FaGripVertical style={LittleIcon} />,
    path: '/Articoli',
    enabled: true
  },
  {
    color: 'danger',
    title: 'Ricavi',
    icon: <FaIcons.FaMoneyBill style={LittleIcon} />,
    path: '/Ricavi',
    enabled: true
  },
  {
    color: 'orange',
    title: 'Rimborsi',
    icon: <FaIcons.FaCarCrash style={LittleIcon} />,
    path: '/rimborsi',
    enabled: true
  },
  {
    color: 'info',
    title: 'Analisi',
    icon: <FaIcons.FaCube style={LittleIcon} />,
    path: '/analisi',
    enabled: true
  },
  {
    color: 'warning',
    title: 'Costi',
    icon: <FaIcons.FaChartLine style={LittleIcon} />,
    path: '/costi',
    enabled: true
  },
  {
    color: 'purple',
    title: 'Resoconti',
    icon: <FaIcons.FaListOl style={LittleIcon} />,
    path: '/resoconti',
    enabled: true
  },
  {
    color: 'dark',
    title: 'Ordini',
    icon: <FaIcons.FaHandshake style={LittleIcon} />,
    path: '/ordini',
    enabled: true
  },
  {
    color: 'info',
    title: 'Variabili',
    icon: <FaIcons.FaPencilAlt style={LittleIcon} />,
    path: '/variabili',
    enabled: true
  },
  {
    color: 'dark',
    title: 'Progetti',
    icon: <FaIcons.FaProjectDiagram style={LittleIcon} />,
    path: '/progetti',
    enabled: true
  },
  {
    color: 'dark',
    title: 'Canoni',
    icon: <FaIcons.FaProjectDiagram style={LittleIcon} />,
    path: '/canoni',
    enabled: true
  }
]

export default dashboard
