import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import Loader from '../../Library/Tools/Loader'
import { Config } from '../../Global'

export default class ModificaFattura extends Component {
  constructor (props) {
    super(props)
    this.state = {
      _id: '',
      numero: 0,
      referenza: '',
      loading: true,
      saldata: '',
      tipo: '',
      importo: 0,
      extraMateriale: 0,
      extraServizi: 0,
      cliente: null,
      dataOra: '',
      clienti: [],
      dataClienti: [],
      preventivi: [],
      richieste: [],
      resoconti: [],
      azienda: '',
      ricavi: [],
      dettaglioResoconto: [],
      tecnici: []
    }
  }

  async componentDidMount () {
    const { azienda, cliente, dettaglioResoconto, extraMateriale, extraServizi, importo, numero, referenza, saldata, tipo, _id } = this.props.location.state
    this.setState({ azienda, cliente, dettaglioResoconto, extraMateriale, extraServizi, importo, numero, referenza, saldata, tipo, _id }, async () => await this.loadData())
  }

  async loadData () {
    const { azienda } = this.state
    this.setState({ loading: true })
    const preventivi = await Api.get('/preventivi', { azienda, stato: '3' })
    const richieste = await Api.get('/richieste', { azienda, stato: '3' })
    const dataClienti = await Api.get('/users', { azienda, role: 'cliente' })
    const ricavi = await Api.get('/ricavi', { azienda })
    const tecnici = await Api.get('/users', { azienda, role: 'utente' })
    if (preventivi.ok && richieste.ok && dataClienti.ok && ricavi.ok && tecnici.ok) {
      const clienti = [...preventivi.data, ...richieste.data]
      // CLIENTI UNICI RISPETTO AL LORO ID
      const clientiUnici = clienti.reduce((acc, elemento) => {
        return acc.some(el => el.cliente === elemento.cliente) ? acc : [...acc, elemento]
      }, [])
      this.setState({ dataClienti: dataClienti.data, clienti: clientiUnici, richieste: richieste.data, preventivi: preventivi.data, ricavi: ricavi.data, tecnici: tecnici.data }, () => {
        this.setState({ loading: false })
      })
    } else {
      swaller.error('Non è stato possibile recuperare i dati')
    }
  }

  getCliente (_id) {
    const { dataClienti } = this.state
    const foundCliente = dataClienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  getTecnico (_id) {
    const { tecnici } = this.state
    const tecnico = tecnici.find(tecnico => tecnico._id === _id)
    return tecnico ? `${tecnico.nome} ${tecnico.cognome}` : ''
  }

  async generaCodice () {
    const { azienda } = this.state
    const fatture = await Api.get('/fatture', { azienda })
    const codice = fatture.ok
      ? fatture.data.length + 1
      : new Date().unix()
    this.setState({ nFattura: codice })
    return codice
  }

  async aggiornaFattura (valore) {
    const { _id, importo, extraMateriale, extraServizi } = this.state
    const res = await Api.patch(`/fatture/${_id}`, {
      dataOraModifica: new Date().toISOString(),
      modificataDa: LocalStorage.get('_id'),
      referenza: valore,
      importo,
      extraMateriale,
      extraServizi
    })
    if (res.ok) {
      swaller.success('Fattura aggiornata correttamente!')
      this.props.navigate('/fatture')
    } else {
      swaller.error('Errore durante la creazione della fattura!')
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/fatture')
    }
  }

  render () {
    const { loading, _id, tipo, numero, cliente, importo, extraMateriale, extraServizi, dettaglioResoconto } = this.state
    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft />Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={async () => await this.aggiornaFattura(_id, tipo, false)}>Aggiorna fattura</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content overflow='scroll' border='none' boxshadow='none' background='transparent'>
          <ContentStyle.InputAllPage>
            <br />
            <br />
            <ContentStyle.Title>Dettagli fattura: </ContentStyle.Title>
            <ContentStyle.Row center='center'>
              <ContentStyle.Box>
                <ContentStyle.Label><b>Fattura numero:</b> {numero}</ContentStyle.Label>
                <ContentStyle.Label><b>Tipo fattura:</b> {tipo}</ContentStyle.Label>
                <ContentStyle.Label><b>Cliente:</b> {this.getCliente(cliente)}</ContentStyle.Label>
              </ContentStyle.Box>
            </ContentStyle.Row>
            <hr />
            <ContentStyle.Row center='center'>
              <ContentStyle.Box>
                <ContentStyle.Label><b>Importo preventivato:</b> {Config.format(importo)}</ContentStyle.Label>
                <ContentStyle.Label><b>Extra materiale:</b> {Config.format(extraMateriale)}</ContentStyle.Label>
                <ContentStyle.Label><b>Extra servizi:</b> {Config.format(extraServizi)}</ContentStyle.Label>
              </ContentStyle.Box>
            </ContentStyle.Row>
            <hr />
            <ContentStyle.Row center='center'>
              <ContentStyle.Box>
                <ContentStyle.Label><b>TOTALE FATTURA:</b> {Config.format(importo + extraMateriale + extraServizi)}</ContentStyle.Label>
              </ContentStyle.Box>
            </ContentStyle.Row>
            <hr />
            <ContentStyle.Row center='center'>
              <ContentStyle.Label><b>Resoconti associati:</b></ContentStyle.Label>
              {dettaglioResoconto && dettaglioResoconto.map((item, i) => {
                return (
                  <div key={i}>
                    <ContentStyle.Row>
                      Tecnico: {this.getTecnico(item.tecnico)}
                    </ContentStyle.Row>
                    <ContentStyle.Row>
                      Descrizione: {item.descrizione}
                    </ContentStyle.Row>
                  </div>
                )
              })}
            </ContentStyle.Row>
            <hr />
            <ContentStyle.Title>Modifica manuale importi: </ContentStyle.Title>
            <ContentStyle.Row center='center'>
              <ContentStyle.Box style={{ width: '80%', flexWrap: 'wrap' }}>
                <ContentStyle.Label>Importo preventivato: </ContentStyle.Label>
                <ContentStyle.Text type='number' placeholder='Variazione' value={importo} onChange={(e) => this.setState({ importo: Number(e.target.value) })} />
                <ContentStyle.Label>€</ContentStyle.Label>
                <ContentStyle.Label>Extra materiale: </ContentStyle.Label>
                <ContentStyle.Text type='number' placeholder='Variazione' value={extraMateriale} onChange={(e) => this.setState({ extraMateriale: Number(e.target.value) })} />
                <ContentStyle.Label>€</ContentStyle.Label>
                <ContentStyle.Label>Extra servizi: </ContentStyle.Label>
                <ContentStyle.Text type='number' placeholder='Variazione' value={extraServizi} onChange={(e) => this.setState({ extraServizi: Number(e.target.value) })} />
                <ContentStyle.Label>€</ContentStyle.Label>
              </ContentStyle.Box>
            </ContentStyle.Row>
          </ContentStyle.InputAllPage>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
