import styled from 'styled-components'
import { font, dark, light, medium, palette, shadow } from '../Library/Tools/Utilities'

export const LittleCard = styled.div`
  min-width: 400px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  color: ${dark};
  background: ${light};
  border: 2px solid #eee;
  font-size: 22px;
  margin: 60px 30px;
  box-shadow: -50px -50px 50px 0 white, 50px 50px 80px 0 #AEAEC0;
  box-shadow: inset 0 -10px 10px 0 rgba(174, 174, 192, 0.25), inset 0 10px 10px 0 #fff;

  &:hover {
    cursor: ${({ isDraggable }) => isDraggable ? 'move' : 'default'};
    transform: scale(1.1);
    transition: 0.5s;
    box-shadow: 0 0 25px 2px lightgray;
  }
`

export const LittleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 90%;
  height: 45%;
  font-size: 40px;
`

export const LittleFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 45%;
  font-size: ${({ size }) => size ? font[size] : medium};
  border-top: 1px solid #ddd;
`

export const LittleToolbar = styled.div`
  position: relative;
  top: -24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 96px;
  height: 96px;
  color: ${({ tipo }) => tipo === 'warning' ? dark : light};
  background: ${({ tipo }) => palette[tipo]};
  font-size: ${({ size }) => size ? font[size] : medium};
  box-shadow: ${shadow.toolbar};
`

export const LittleIcon = {
  color: 'white',
  width: 48,
  height: 48,
  backgroundColor: 'transparent'
}
