import { StyleSheet, PDFDownloadLink } from '@react-pdf/renderer'
import { FaFilePdf } from 'react-icons/fa'
import swaller from './Swaller'
import { palette } from './Utilities'
import Spinner from './Spinner'

export const PdfStyles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 11,
    textAlign: 'right',
    padding: 10
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40
  },
  subtitle: {
    fontSize: 14,
    margin: 12
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: 'left'
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  rowTitles: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'lightgray'
  },
  row: {
    margin: 'auto',
    flexDirection: 'row'
  },
  col50: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 1
  },
  col50sx: {
    width: '50%',
    borderStyle: 'solid',
    borderLeftWidth: 0,
    borderTopWidth: 1
  },
  col50dx: {
    width: '50%',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1
  },
  col50logo: {
    width: '50%',
    borderStyle: 'solid',
    borderRightWidth: 1
  },
  col100: {
    width: '100%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0
  },
  col100bottom: {
    width: '100%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 0
  },
  col100box: {
    width: '100%',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 1,
    borderLeftWidth: 0
  },
  col25: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  col20: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey'
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  pageNumber: {
    width: '200%',
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  colHeader: {
    width: '50%'
  },
  image: {
  }
})

export const PdfDownload = (document, fileName) => {
  return (
    <PDFDownloadLink document={document} fileName={fileName}>
      {({ blob, url, loading, error }) =>
        loading
          ? <Spinner />
          : <FaFilePdf size={24} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => swaller.success('esportazione pdf')} />}
    </PDFDownloadLink>
  )
}
