import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import Loader from '../../Library/Tools/Loader'
export default class NuovoRicavo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      settori: [],
      settore: '',
      tipo: '',
      ricarico: 0,
      azienda: ''
    }
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      const settori = await Api.get('/settori', { azienda })
      if (settori.ok) {
        this.setState({ settori: settori.data, azienda, loading: false })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Non è stato possibile caricare i settori. Riprovare più tardi')
    }
  }

  async salvaRicavo () {
    const { azienda, settore, tipo, ricarico } = this.state
    if (!azienda || !settore || !tipo || !ricarico) {
      swaller.error('Inserire tutti i campi prima di salvare il ricavo')
      return
    }
    swaller.info('Salvataggio in corso...')
    try {
      const res = await Api.post('/ricavi', {
        settore,
        tipo,
        ricarico,
        azienda
      })
      if (res.ok) {
        swaller.success('Ricavo salvato correttamente!')
        this.props.navigate('/ricavi')
      } else {
        swaller.error('Errore durante la creazione del ricavo, prego riprovare!')
      }
    } catch (error) {
      swaller.error('Errore durante la comunicazione con il server. Riprovare più tardi.')
    }
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/ricavi')
    }
  }

  render () {
    const { loading, settori } = this.state
    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft />Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.salvaRicavo()}>Salva ricavo</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form width='40%'>
            <ContentStyle.FormRow gap='25px'>
              <ContentStyle.Title>Fornire le indicazioni seguenti: </ContentStyle.Title>
              <ContentStyle.Row justifycontent='flex-start'>
                <ContentStyle.Label>Settore: </ContentStyle.Label>
                <ContentStyle.Select onChange={(e) => this.setState({ settore: e.target.value })}>
                  <option value=''>...</option>
                  {settori.length > 0 && settori.map((item, i) => {
                    return (
                      <option value={item._id} key={i}>
                        {item.settore}
                      </option>
                    )
                  })}
                </ContentStyle.Select>
              </ContentStyle.Row>
              <ContentStyle.Row>
                <ContentStyle.Label>Tipo: </ContentStyle.Label>
                <ContentStyle.Select onChange={(e) => this.setState({ tipo: e.target.value })}>
                  <option value='...'>...</option>
                  <option value='Servizi'>Servizi</option>
                  <option value='Articoli'>Articoli</option>
                </ContentStyle.Select>
              </ContentStyle.Row>
              <ContentStyle.Row>
                <ContentStyle.Label>Ricarico %: </ContentStyle.Label>
                <ContentStyle.Text type='number' placeholder='ricarico %' onChange={(e) => this.setState({ ricarico: Number(e.target.value) })} />
              </ContentStyle.Row>
            </ContentStyle.FormRow>
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
