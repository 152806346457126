import styled from 'styled-components'
import { palette, dark } from '../Library/Tools/Utilities'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme || '#fff'};
`

export const Toolbar = styled.div`
  width: 90vw;
  height: ${({ height }) => height || '8vh'};
  display: flex;
  align-items: center;
  justify-content: ${({ isend }) => isend ? 'flex-end' : 'space-between'};
  margin-top: ${({ margintop }) => margintop || '0px'};
  padding-left: ${({ paddingleft }) => paddingleft || '0px'};
  padding-right: ${({ paddingright }) => paddingright || '0px'};
`

export const ToolbarItem = styled.div`
  height: ${({ height }) => height || '100%'};
  display: flex;
  margin-top: ${({ margintop }) => margintop || '0px'};
  justify-content: ${({ isend }) => isend ? 'flex-end' : 'flex-start'};
  align-items: center;
  gap: ${({ gap }) => gap || '15px'};
`

export const Loginbox = styled.div`
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  max-height: 600px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 20px;
  border-radius: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(9.4px);
  -webkit-backdrop-filter: blur(9.4px);
`

export const Logo = styled.img`
  width: 128px;
  height: 128px;
  margin-bottom: 10px;
  border-radius: 50%;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
              -2px -2px 12px rgba(255, 255, 255, 0.5),
              inset 2px 2px 4px rgba(255, 255, 255, 0.1),
              2px 2px 8px rgba(0, 0, 0, .3);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  background-color: #ecf0f3;
`

export const LoginLabel = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  margin: 10px auto;
  padding: 15px 20px;
  border: none;
  border-radius: 23px;
  box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
              inset -2px -2px 12px rgba(255, 255, 255, 0.5),
              inset 2px 2px 4px rgba(255, 255, 255, 0.1),
              inset 2px 2px 8px rgba(0, 0, 0, .3);
  background-color: #ecf0f3;
  font-size: 1rem;
  outline: none;

  &::placeholder {
    color: #5c5c5c;
  }
`

export const LoginFormButton = styled.button`
  width: 100%;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 15px;
  border: none;
  border-radius: 25px;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
              -2px -2px 12px rgba(255, 255, 255, 0.5),
              inset 2px 2px 4px rgba(255, 255, 255, 0.1),
              2px 2px 8px rgba(0, 0, 0, .3);
  color: ${({ type, outline }) => outline ? palette[type] : type === 'warning' ? dark : 'black'};
  font-size: 20px;
  background-color: #ecf0f3;
  outline: none;
  cursor: pointer;

  &:hover{
    transform: scale(0.98);
    box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
                inset -2px -2px 12px rgba(255, 255, 255, 0.5),
                inset 2px 2px 4px rgba(255, 255, 255, 0.1),
                inset 2px 2px 8px rgba(0, 0, 0, .3);
  }
`

export const Logout = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  gap: 20px;
`
