import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, BoxInfo, BoxContent, BoxRow } from '../../Components'
import { Button, CloseButton } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnMagazzino } from '../TitoliColonne'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { PdfDownload } from '../../Library/Tools/Pdf'
import { PdfArticolo } from '../../Pdf'
import Loader from '../../Library/Tools/Loader'

export default class Magazzino extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      magazzino: [],
      articoli: [],
      data: [],
      showInfoBox: false,
      content: [],
      settore: [],
      produttore: [],
      fornitore: [],
      azienda: '',
      immagineUrl: ''
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const [produttore, settore, fornitore, magazzino, articoli] = await Promise.all([
      Api.get('/produttori', { azienda }),
      Api.get('/settori', { azienda }),
      Api.get('/fornitori', { azienda }),
      Api.get('/magazzino', { azienda }),
      Api.get('/articoli', { azienda })
    ])
    produttore.ok && settore.ok && fornitore.ok && magazzino.ok && articoli.ok
      ? this.setState({ produttore: produttore.data, settore: settore.data, fornitore: fornitore.data, magazzino: magazzino.data, data: magazzino.data, articoli: articoli.data, loading: false })
      : swaller.error('Non è stato possibile recuperare i dati')
  }

  filter (search, value) {
    const { magazzino } = this.state
    search === ''
      ? this.setState({ data: magazzino })
      : this.setState({ data: magazzino.filter(item => this.getArticolo(item)[value].toLowerCase().includes(search.toLowerCase())) })
  }

  getInfo (_id, value, field) {
    const items = this.state[value]
    const foundItem = items.find(item => item._id === _id)
    if (foundItem) {
      return field ? foundItem[field] : foundItem[value]
    } else {
      return ''
    }
  }

  pdfExport (item) {
    const data = { ...item }
    data.produttore = this.getInfo(item.produttore, 'produttore')
    data.settore = this.getInfo(item.settore, 'settore')
    data.fornitore = this.getInfo(item.fornitore, 'fornitore')
    data.immagineUrl = require('../../images/Tecnosistemi.png')
    return data
  }

  getArticolo (item) {
    const { articoli } = this.state
    const matchingArticolo = articoli.find(e => e._id === item.articolo)
    if (matchingArticolo) {
      matchingArticolo.qtaStock = item.qtaStock
      return matchingArticolo
    } else {
      return {}
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageMagazzino = data.slice(startIndex, endIndex)
    return currentPageMagazzino
  }

  render () {
    const { data, loading, magazzino, showInfoBox, content, settore, itemPerPage, currentPage } = this.state
    const totalPages = Math.ceil(magazzino.length / itemPerPage)

    return (
      <Loader loadData={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'titolo')} />
            <Button type='primary' outline='true' onClick={() => this.props.navigate('/carrello', { state: 'creazione scatola', replace: false })}><FaIcons.FaBox /> Creazione scatola</Button>
            <Button type='primary' outline='true' onClick={() => this.props.navigate('/carrello', { state: 'consegna materiale', replace: false })}><FaIcons.FaHandPaper /> Consegna materiale</Button>
            <ContentStyle.Select onChange={(e) => this.filter(e.target.value, 'settore')}>
              <option value=''>Tutti i settori</option>
              {settore && settore.length > 0 && settore.map((item, i) =>
                <option value={item._id} key={i}>{item.settore}</option>)}
            </ContentStyle.Select>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnMagazzino.map((colonne, i) => {
                    return (
                      <Tcol key={i}>
                        <b>{colonne.label}</b>
                      </Tcol>
                    )
                  })}
                </Thead>
                <Tbody>
                  {this.showData().map((item, i) => {
                    return (
                      <Trow key={i}>
                        <Tcol>{this.getArticolo(item).titolo}</Tcol>
                        <Tcol>{this.getInfo(this.getArticolo(item).produttore, 'produttore')}</Tcol>
                        <Tcol>{this.getInfo(this.getArticolo(item).fornitore, 'fornitore')}</Tcol>
                        <Tcol>{this.getArticolo(item).qtaStock}</Tcol>
                        <Tcol>{this.getArticolo(item).qtaMinStock}</Tcol>
                        <Tcol>{this.getInfo(this.getArticolo(item).settore, 'settore')}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, content: this.getArticolo(item) })} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          {PdfDownload(PdfArticolo(this.pdfExport(this.getArticolo(item))), this.getArticolo(item).titolo)}
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun prodotto nel magazzino</i>}
          <BoxInfo showInfoBox={showInfoBox}>
            <BoxContent>
              <Toolbar>
                <ToolbarItem>
                  <CloseButton>
                    <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: false, infoValue: {} })} />
                  </CloseButton>
                </ToolbarItem>
              </Toolbar>
              <ContentStyle.Title>Descrizione:</ContentStyle.Title>
              <div style={{ width: '100%', overflow: 'auto' }}>
                <BoxRow>Titolo: {content.titolo}</BoxRow>
                <BoxRow>Produttore: {this.getInfo(content.produttore, 'produttore')}</BoxRow>
                <BoxRow>Fornitore: {this.getInfo(content.fornitore, 'fornitore')}</BoxRow>
                <BoxRow>Quantità stock: {content.qtaStock}</BoxRow>
                <BoxRow>Quantità minima stock: {content.qtaMinStock}</BoxRow>
                <BoxRow>Settore: {this.getInfo(content.settore, 'settore')}</BoxRow>
                <ContentStyle.Row style={{ display: 'flex', alignItems: 'center', gap: 10 }}>Descrizione: <ContentStyle.Textarea disabled value={content.descrizione} /></ContentStyle.Row>
                <br />
                <BoxRow>Ultima modifica: {new Date(content.dataOra).toLocaleString('it-IT')}</BoxRow>
                <ContentStyle.Row>Anteprima immagini:</ContentStyle.Row>
                <br />
                <ContentStyle.BoxImmagine src={content.immagineUrl} alt='' />
              </div>
            </BoxContent>
          </BoxInfo>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
