import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import Loader from '../../Library/Tools/Loader'

export default class ModificaCosto extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      titolo: '',
      descrizione: '',
      dataOra: '',
      azienda: '',
      costo: '',
      isAnnual: true
    }
  }

  async componentDidMount () {
    const { _id, titolo, descrizione, costo, dataOra, isAnnual } = this.props.location.state
    const { azienda } = LocalStorage.get('user')
    this.setState({ azienda, _id, titolo, costo, isAnnual, descrizione, dataOra }, async () => {
      this.setState({ loading: false })
    })
  }

  async aggiornaCosto () {
    const { _id, titolo, descrizione, costo, isAnnual } = this.state
    swaller.info('Salvataggio in corso...')
    const res = await Api.patch(`/costi/${_id}`, {
      _id,
      titolo,
      costo,
      isAnnual,
      descrizione,
      dataOra: new Date().toISOString()
    })
    if (res.ok) {
      swaller.success('Costo aggiornato correttamente!')
      setTimeout(() => this.props.navigate('/costi'), 2000)
    } else {
      swaller.error('Errore durante la modifica del costo, prego riprovare!')
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/costi')
    }
  }

  render () {
    const { loading, titolo, costo, descrizione, isAnnual } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.aggiornaCosto()}><FaIcons.FaEdit />Aggiorna costo</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content border='none' boxshadow='none' background='transparent'>
          <ContentStyle.InputAllPage>
            <br />
            <br />
            <ContentStyle.Title>Fornire le indicazioni seguenti: </ContentStyle.Title>
            <hr />
            <ContentStyle.Row>
              <ContentStyle.Label>Titolo: </ContentStyle.Label>
              <ContentStyle.Text type='text' value={titolo} onChange={(e) => this.setState({ titolo: e.target.value })} />
            </ContentStyle.Row>
            <ContentStyle.Row>
              <ContentStyle.Label>Costo: </ContentStyle.Label>
              <ContentStyle.Text type='number' placeholder='costo €' value={costo} min='0' onChange={(e) => this.setState({ costo: Number(e.target.value) })} />
            </ContentStyle.Row>
            <ContentStyle.Row gap='10px'>
              <ContentStyle.Row>
                <ContentStyle.Label>Periodicità: </ContentStyle.Label>
                <Button type={isAnnual ? 'info' : 'danger'} outline='true' onClick={() => this.setState({ isAnnual: !isAnnual })}>{isAnnual ? 'Annuale' : 'Mensile'}</Button>
              </ContentStyle.Row>
              <ContentStyle.Label>Descrizione: </ContentStyle.Label>
              <ContentStyle.Textarea type='text' placeholder='descrizione' value={descrizione} onChange={(e) => this.setState({ descrizione: e.target.value })} />
            </ContentStyle.Row>
          </ContentStyle.InputAllPage>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
