import { Page, View, Document, Text, Image } from '@react-pdf/renderer'
import { PdfStyles } from '../Library/Tools/Pdf'
import { LocalStorage } from '../Services'
import Logo from '../images/Tecnosistemi.png'
import { Config } from '../Global'

export const PdfOrdini = (ordine) => {
  return (
    <Document>
      <Page size='A4' style={PdfStyles.body}>
        <View style={PdfStyles.table}>
          <View style={PdfStyles.row}>
            <View style={PdfStyles.col50logo}>
              <Image style={PdfStyles.image} src={Logo} />
            </View>
            <View style={PdfStyles.col50}>
              <Text style={PdfStyles.title}>
                Tecnosistemi S.r.l.
                C.so Francia, 247 - 12100 Cuneo
                Reg. Imprese e P.I. 02023170042
                C.C.I.A.A. di Cuneo N. 149611
                Tel. +39 0171 344399 - Fax +39 0171 344711
                info@tecnosistemicuneo.it - www.tecnosistemicuneo.it
                PEC: tecnosistemicuneo@cert.unonet.it
                Servizio Assistenza: cell. 335/6558288 - Cell. 335/5444526
              </Text>
            </View>
          </View>
          <View style={PdfStyles.row}>
            <View style={PdfStyles.col50}>
              <View style={PdfStyles.row}>
                <View style={PdfStyles.col50sx}>
                  <Text style={PdfStyles.text}>Tipologia: Ordine</Text>
                </View>
                <View style={PdfStyles.col50dx}>
                  <Text style={PdfStyles.text}>N. {ordine.nOrdine} - {new Date(ordine.dataOraCreazione).toLocaleString('it-IT')}</Text>
                </View>
              </View>
              <View style={PdfStyles.row}>
                <View style={PdfStyles.col100}>
                  <Text style={PdfStyles.text}>Pagamento:</Text>
                </View>
              </View>
              <View style={PdfStyles.row}>
                <View style={PdfStyles.col100}>
                  <Text style={PdfStyles.text}>Nostre Banche:</Text>
                </View>
              </View>
              <View style={PdfStyles.row}>
                <View style={PdfStyles.col50sx}>
                  <Text style={PdfStyles.text}>Zona:</Text>
                </View>
                <View style={PdfStyles.col50dx}>
                  <Text style={PdfStyles.text}>Tipologia commerciale:</Text>
                </View>
              </View>
            </View>
            <View style={PdfStyles.col50}>
              <Text style={PdfStyles.text}>Cliente:</Text>
            </View>
          </View>
          <View style={PdfStyles.row}>
            <View style={PdfStyles.col50}>
              <Text style={PdfStyles.text}>Codice Cliente:</Text>
            </View>
            <View style={PdfStyles.col50}>
              <Text style={PdfStyles.text}>Codice fiscale / Partita Iva:</Text>
            </View>
          </View>
          <View style={PdfStyles.col100box}>
            <View style={PdfStyles.rowTitles}>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>Articolo</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>Settore</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>Produttore</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>Fornitore</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>Quantità</Text>
              </View>
            </View>
          </View>
          <View style={PdfStyles.col100box}>
            {ordine.articoli && ordine.articoli.map((item, i) => {
              return (
                <View style={PdfStyles.row} key={i}>
                  <View style={PdfStyles.col20}>
                    <Text style={PdfStyles.text}>{item.titolo}</Text>
                  </View>
                  <View style={PdfStyles.col20}>
                    <Text style={PdfStyles.text}>{item.settore}</Text>
                  </View>
                  <View style={PdfStyles.col20}>
                    <Text style={PdfStyles.text}>{item.produttore}</Text>
                  </View>
                  <View style={PdfStyles.col20}>
                    <Text style={PdfStyles.text}>{item.fornitore}</Text>
                  </View>
                  <View style={PdfStyles.col20}>
                    <Text style={PdfStyles.text}>{item.qta}</Text>
                  </View>
                </View>
              )
            })}
          </View>
        </View>
        <View style={PdfStyles.table}>
          <View style={PdfStyles.col100box}>
            <View style={PdfStyles.rowTitles}>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>Imponibile Iva</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>% Iva</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>Descrizione Iva</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>Importi Iva</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>Totale ordine</Text>
              </View>
            </View>
            <View style={PdfStyles.row}>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>{ordine.totale}</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>ES74</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>-</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>-</Text>
              </View>
              <View style={PdfStyles.col20}>
                <Text style={PdfStyles.text}>{Config.format(ordine.totale)}</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={PdfStyles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
        <Text style={PdfStyles.footer}>
          ~ Powered by: {LocalStorage.get('intestazione')} ~
        </Text>
      </Page>
    </Document>
  )
}
