import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Trow, Tcol, Tbody } from '../../Components'
import { Button, CloseButton } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { columnAggiuntaServizi, columnResoconto } from '../Resoconti/MockupResoconti'
import { columnAggiuntaMateriale } from '../TitoliColonne'
import Loader from '../../Library/Tools/Loader'
import { palette } from '../../Library/Tools/Utilities'
import { BoxAggiuntaMateriale, BoxContent, BoxInfo, BoxRow } from '../../Components/ModalInfo'

export default class ModificaPreventivo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      _id: '',
      numero: 0,
      loading: true,
      azienda: '',
      settori: [],
      settoriAvailable: [],
      settore: '...',
      servizi: [],
      articoli: [],
      produttore: [],
      fornitore: [],
      tipologie: [],
      tipologia: '...',
      clienti: [],
      cliente: '...',
      sezioni: [],
      variazione: 0,
      singoli: false,
      showBoxAggiuntaMateriale: false,
      showBoxAggiuntaServizi: false,
      showInfoBox: false,
      infoValue: {},
      showing: '',
      section: '',
      ricavi: [],
      stato: 0,
      parzialeArticoli: 0,
      parzialeServizi: 0,
      totaleArticoli: 0,
      totaleServizi: 0,
      accettato: false,
      note: ''
    }
  }

  async componentDidMount () {
    const { _id, sezioni, cliente, tipologia, numero, singoli, variazione, stato, parzialeArticoli, parzialeServizi, totaleArticoli, totaleServizi, accettato, note } = this.props.location.state
    const { azienda } = LocalStorage.get('user')
    this.setState({ azienda, _id, sezioni, cliente, tipologia, numero, singoli, variazione, stato, parzialeArticoli, parzialeServizi, totaleArticoli, totaleServizi, accettato, note }, async () => await this.loadData(sezioni))
  }

  async loadData (sezioni) {
    const { azienda } = this.state
    const articoli = await Api.get('/articoli', { azienda })
    const produttore = await Api.get('/produttori', { azienda })
    const fornitore = await Api.get('/fornitori', { azienda })
    const settori = await Api.get('/settori', { azienda })
    const tipologie = await Api.get('/tipologie', { azienda })
    const clienti = await Api.get('/users', { azienda, role: 'cliente' })
    const servizi = await Api.get('/servizi', { azienda })
    const ricavi = await Api.get('/ricavi', { azienda })
    if (articoli.ok && settori.ok && servizi.ok && tipologie.ok && clienti.ok && produttore.ok && fornitore.ok && ricavi.ok) {
      this.setState({ servizi: servizi.data, articoli: articoli.data, settori: settori.data, tipologie: tipologie.data, clienti: clienti.data, produttore: produttore.data, fornitore: fornitore.data, ricavi: ricavi.data }, () => {
        sezioni.forEach(element => {
          const settoriFiltrato = settori.data.filter(item => item._id !== element.settore)
          this.setState({ settoriAvailable: settoriFiltrato })
        })
        this.setState({ loading: false })
      })
    } else {
      swaller.error('Non è stato possibile recuperare i dati')
    }
  }

  async generaCodice () {
    const { azienda } = this.state
    const res = await Api.get('/preventivi', { azienda })
    const codice = res.ok
      ? res.data.length + 1
      : new Date().unix()
    return codice
  }

  async aggiornaPreventivo () {
    const res = await swaller.question('Vuoi confermare il preventivo?')
    if (res) {
      const { _id, sezioni, cliente, tipologia, singoli, variazione, note, numero } = this.state
      if (cliente === '...' || tipologia === '...' || sezioni.length === 0) {
        swaller.error('Compilare tutti i campi obbligatori!')
      } else {
        swaller.info(`Salvataggio preventivo N. ${numero} in corso...`)
        const parziali = this.calculatePaziali(false)
        const totali = this.calculatePaziali(true)
        const preventivo = {
          sezioni,
          cliente,
          tipologia,
          singoli,
          variazione,
          note,
          dataOraModifica: new Date().toISOString(),
          modificatoDa: LocalStorage.get('user')._id,
          parzialeArticoli: parziali.articoli,
          parzialeServizi: parziali.servizi,
          totaleArticoli: totali.articoli,
          totaleServizi: totali.servizi,
          totale: totali.servizi + totali.articoli + variazione
        }
        const res = await Api.patch(`/preventivi/${_id}`, preventivo)
        if (res.ok) {
          swaller.success(`Preventivo N. ${numero} aggiornato correttamente!`)
          setTimeout(() => this.props.navigate('/preventivi'), 2000)
        } else {
          swaller.error('Errore durante l\'aggiornamento del preventivo!')
        }
      }
    }
  }

  getInfo (_id, value, field) {
    const items = this.state[value]
    const text = items.find(item => item._id === _id)
    if (field) {
      return text ? text[field] : ''
    } else {
      return text ? text[value] : ''
    }
  }

  getTipologia (_id) {
    const { tipologie } = this.state
    let text = ''
    tipologie.forEach(tipologia => {
      if (_id === tipologia._id) {
        text = tipologia.tipologia
      }
    })
    return text
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  aggiungiSezione (value) {
    const { sezioni, settoriAvailable } = this.state
    sezioni.push({ settore: value, articoli: [], servizi: [] })
    const index = settoriAvailable.findIndex(e => e._id === value)
    settoriAvailable.splice(index, 1)
    this.setState({ sezioni, settore: '...', settoriAvailable })
  }

  async rimuoviSezione (value, _id) {
    const settore = this.getInfo(_id, 'settori', 'settore')
    const res = await swaller.question(`Vuoi eliminare la sezione ${settore}?`)
    if (res) {
      const { sezioni, settoriAvailable } = this.state
      settoriAvailable.push({ settore, _id })
      sezioni.splice(value, 1)
      this.setState({ sezioni, settoriAvailable })
    }
  }

  addCarrello (_id, type) {
    const { sezioni, showing } = this.state
    const index = sezioni.findIndex(e => e.settore === showing)
    const check = sezioni[index][type].find(e => e._id === _id)
    if (check) {
      swaller.warning('Articolo già aggiunto')
    } else {
      const item = {
        qta: 0,
        _id
      }
      sezioni[index][type].push(item)
      this.setState({ sezioni })
    }
  }

  async removeCarrello (pos, index, type) {
    const { sezioni } = this.state
    const res = await swaller.question('Sicuro di voler eliminare ?')
    if (res) {
      sezioni[index][type].splice(pos, 1)
      this.setState({ sezioni })
    }
  }

  handleQuantity (pos, index, type, qta) {
    const { sezioni } = this.state
    sezioni[index][type][pos].qta = qta >= 0
      ? Number(qta)
      : 0
    this.setState({ sezioni })
  }

  format (value) {
    return value.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
  }

  calculatePaziali (margin) {
    const { sezioni, ricavi } = this.state
    const parziali = { articoli: 0, servizi: 0 }
    if (ricavi.length > 0) {
      for (const sezione of sezioni) {
        for (const articolo of sezione.articoli) {
          const costo = this.getInfo(articolo._id, 'articoli', 'prezzoAcquisto')
          const item = ricavi.find(e => e.settore === sezione.settore && e.tipo === 'Articoli')
          margin
            ? parziali.articoli += ((articolo.qta * costo) * (100 + item.ricarico) / 100)
            : parziali.articoli += (articolo.qta * costo)
        }
        for (const servizio of sezione.servizi) {
          const costo = this.getInfo(servizio._id, 'servizi', 'costoOrario')
          const item = ricavi.find(e => e.settore === sezione.settore && e.tipo === 'Servizi')
          margin
            ? parziali.servizi += ((servizio.qta * costo) / 100 * (100 + item.ricarico))
            : parziali.servizi += (servizio.qta * costo)
        }
      }
    }
    return parziali
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/preventivi')
    }
  }

  render () {
    const { loading, clienti, tipologia, cliente, tipologie, settore, sezioni, singoli, showBoxAggiuntaMateriale, showInfoBox, infoValue, articoli, settoriAvailable, showBoxAggiuntaServizi, servizi, showing, section, variazione, note } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <Button type='success' outline='true' onClick={async () => await this.aggiornaPreventivo()}><FaIcons.FaCheckCircle />Aggiorna preventivo</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content overflow='scroll' border='none' boxshadow='none' background='transparent'>
          <ContentStyle.InputAllPage>
            <br />
            <br />
            <ContentStyle.Title>Descrizione:</ContentStyle.Title>
            <ContentStyle.Row style={{ justifyContent: 'center' }}>
              <ContentStyle.Box>
                <ContentStyle.Label>Cliente: </ContentStyle.Label>
                <ContentStyle.Select value={cliente} onChange={(e) => e.target.value != null && this.setState({ cliente: e.target.value })}>
                  <option value='...'>...</option>
                  {clienti && clienti.length > 0 && clienti.map((item, i) => {
                    return (
                      <option value={item._id} key={i}>
                        {item.tipologia === 'privato' ? `${item.nome} ${item.cognome}` : item.intestazione}
                      </option>
                    )
                  })}
                </ContentStyle.Select>
                {cliente !== '...' && <ContentStyle.Label>{this.getInfo(cliente, 'clienti', 'tipologia')}</ContentStyle.Label>}
              </ContentStyle.Box>
              <ContentStyle.Box>
                <ContentStyle.Label>Tipologia: </ContentStyle.Label>
                <ContentStyle.Select value={tipologia} onChange={(e) => e.target.value != null && this.setState({ tipologia: e.target.value })}>
                  <option value='...'>...</option>
                  {tipologie && tipologie.length > 0 && tipologie.map((item, i) => {
                    return (
                      <option value={item._id} key={i}>
                        {item.tipologia}
                      </option>
                    )
                  })}
                </ContentStyle.Select>
              </ContentStyle.Box>
              <ContentStyle.Box>
                <ContentStyle.Label>Mostra prezzi singoli: </ContentStyle.Label>
                <ContentStyle.Select value={singoli} onChange={(e) => e.target.value != null && this.setState({ singoli: e.target.value })}>
                  <option value={false}>NO</option>
                  <option value>SI</option>
                </ContentStyle.Select>
              </ContentStyle.Box>
              <ContentStyle.Box>
                <ContentStyle.Label>Variazione (sconto, maggiorazione): </ContentStyle.Label>
                <ContentStyle.Text type='number' placeholder='Variazione' value={variazione} onChange={(e) => this.setState({ variazione: Number(e.target.value) })} />
                <ContentStyle.Label>€</ContentStyle.Label>
              </ContentStyle.Box>
            </ContentStyle.Row>
            <ContentStyle.Row style={{ justifyContent: 'center' }}>
              <ContentStyle.Box>
                <ContentStyle.Label>Note: </ContentStyle.Label>
                <ContentStyle.Textarea type='text' placeholder='note...' value={note} onChange={(e) => e.target.value != null && this.setState({ note: e.target.value })} />
              </ContentStyle.Box>
            </ContentStyle.Row>
            <ContentStyle.Row style={{ justifyContent: 'center' }}>
              <ContentStyle.Box>
                <ContentStyle.Label>Parziali: </ContentStyle.Label>
                <ContentStyle.Label>{this.format(this.calculatePaziali(false).articoli + this.calculatePaziali().servizi)}</ContentStyle.Label>
                <ContentStyle.Label>Parziali articoli: </ContentStyle.Label>
                <ContentStyle.Label>{this.format(this.calculatePaziali(false).articoli)}</ContentStyle.Label>
                <ContentStyle.Label>Parziali servizi: </ContentStyle.Label>
                <ContentStyle.Label>{this.format(this.calculatePaziali(false).servizi)}</ContentStyle.Label>
              </ContentStyle.Box>
            </ContentStyle.Row>
            <ContentStyle.Row style={{ justifyContent: 'center' }}>
              <ContentStyle.Box>
                <ContentStyle.Label>Parziali + Margine: </ContentStyle.Label>
                <ContentStyle.Label>{this.format(this.calculatePaziali(true).articoli + this.calculatePaziali(true).servizi)} ({(100 - ((this.calculatePaziali(false).articoli + this.calculatePaziali(false).servizi) / (this.calculatePaziali(true).articoli + this.calculatePaziali(true).servizi) * 100)).toFixed(2)}%)</ContentStyle.Label>
                <ContentStyle.Label>Parziali articoli + Margine: </ContentStyle.Label>
                <ContentStyle.Label>{this.format(this.calculatePaziali(true).articoli)}</ContentStyle.Label>
                <ContentStyle.Label>Parziali servizi + Margine: </ContentStyle.Label>
                <ContentStyle.Label>{this.format(this.calculatePaziali(true).servizi)}</ContentStyle.Label>
              </ContentStyle.Box>
            </ContentStyle.Row>
            <ContentStyle.Row style={{ justifyContent: 'center' }}>
              <ContentStyle.Box>
                <ContentStyle.Label><b>TOTALE (compresa variazione):</b></ContentStyle.Label>
                <ContentStyle.Label>{this.format((this.calculatePaziali(true).articoli + this.calculatePaziali(true).servizi) + variazione)}</ContentStyle.Label>
              </ContentStyle.Box>
            </ContentStyle.Row>
            {sezioni && sezioni.length > 0 && sezioni.map((item, i) => {
              return (
                <div key={i}>
                  <hr style={{ width: '100%' }} />
                  <ContentStyle.Row style={{ marginTop: 20, marginBottom: 20 }}>
                    <ContentStyle.Label>
                      <div style={{ fontSize: 24, fontWeight: 'bold' }}>{this.getInfo(item.settore, 'settori', 'settore')}</div>
                    </ContentStyle.Label>
                    <ContentStyle.Box style={{ justifyContent: 'end' }}>
                      <Button type='danger' outline='true' onClick={async () => await this.rimuoviSezione(i, item.settore)}>Elimina sezione</Button>
                      <Button type='primary' outline='true' onClick={() => this.setState({ showBoxAggiuntaMateriale: true, showing: item.settore, section: i })}>Aggiungi materiale</Button>
                      <Button type='info' outline='true' onClick={() => this.setState({ showBoxAggiuntaServizi: true, showing: item.settore, section: i })}>Aggiungi servizi</Button>
                    </ContentStyle.Box>
                  </ContentStyle.Row>
                  {item.articoli.length > 0 &&
                    (
                      <>
                        <ContentStyle.Row style={{ justifyContent: 'space-between', marginTop: 20, marginBottom: 20 }}>
                          <ContentStyle.Label>
                            <div style={{ fontSize: 22 }}>Materiale</div>
                          </ContentStyle.Label>
                        </ContentStyle.Row>
                        <ContentStyle.Table>
                          <Table>
                            <Thead>
                              {columnResoconto.map((colonne, i) => {
                                return (
                                  <Tcol key={i} style={{ textAlign: 'center', width: '20%' }}>
                                    <b>{colonne.label}</b>
                                  </Tcol>
                                )
                              })}
                            </Thead>
                            <Tbody>
                              {item.articoli.map((articolo, ind) => {
                                return (
                                  <Trow key={ind}>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>{this.getInfo(articolo._id, 'articoli', 'titolo')}</Tcol>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>{this.getInfo(articolo._id, 'articoli', 'descrizione')}</Tcol>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>{this.format(this.getInfo(articolo._id, 'articoli', 'prezzoAcquisto'))}</Tcol>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>
                                      <input style={{ width: 50, textAlign: 'center' }} type='number' value={articolo.qta} onChange={(e) => this.handleQuantity(ind, i, 'articoli', e.target.value)} />
                                    </Tcol>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>
                                      <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer' }} onClick={async () => await this.removeCarrello(ind, i, 'articoli')} />
                                    </Tcol>
                                  </Trow>
                                )
                              })}
                            </Tbody>
                          </Table>
                        </ContentStyle.Table>
                      </>
                    )}
                  {item.servizi.length > 0 &&
                    (
                      <>
                        <ContentStyle.Row style={{ justifyContent: 'space-between', marginTop: 20, marginBottom: 20 }}>
                          <ContentStyle.Label>
                            <div style={{ fontSize: 22 }}>Servizi</div>
                          </ContentStyle.Label>
                        </ContentStyle.Row>
                        <ContentStyle.Table>
                          <Table>
                            <Thead>
                              <Trow>
                                {columnResoconto.map((colonne, i) => {
                                  return (
                                    <Tcol key={i} style={{ textAlign: 'center', width: '20%' }}>
                                      <b>{colonne.label}</b>
                                    </Tcol>
                                  )
                                })}
                              </Trow>
                            </Thead>
                            <Tbody>
                              {item.servizi.map((servizio, ind) => {
                                return (
                                  <Trow key={ind}>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>{this.getInfo(servizio._id, 'servizi', 'titolo')}</Tcol>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>{this.getInfo(servizio._id, 'servizi', 'descrizione')}</Tcol>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>{this.format(this.getInfo(servizio._id, 'servizi', 'costoOrario'))}</Tcol>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>
                                      <input style={{ width: 50, textAlign: 'center' }} type='number' value={servizio.qta} onChange={(e) => this.handleQuantity(ind, i, 'servizi', e.target.value)} />
                                    </Tcol>
                                    <Tcol style={{ textAlign: 'center', width: '20%' }}>
                                      <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer' }} onClick={async () => await this.removeCarrello(ind, i, 'servizi')} />
                                    </Tcol>
                                  </Trow>
                                )
                              })}
                            </Tbody>
                          </Table>
                        </ContentStyle.Table>
                      </>
                    )}
                </div>
              )
            })}
            <hr style={{ width: '100%' }} />
            <ContentStyle.Row style={{ paddingBottom: 40 }}>
              <ContentStyle.Box>
                <ContentStyle.Label>*Aggiungi nuova sezione: </ContentStyle.Label>
                <ContentStyle.Select value={settore} onChange={(e) => e.target.value != null && this.setState({ settore: e.target.value })}>
                  <option value='...'>...</option>
                  {settoriAvailable && settoriAvailable.length > 0 && settoriAvailable.map((item, i) => {
                    return (
                      <option key={i} value={item._id}>{item.settore}</option>
                    )
                  })}
                </ContentStyle.Select>
                {settore !== '...' && <Button type='success' outline='true' onClick={() => this.aggiungiSezione(settore)}>Genera</Button>}
              </ContentStyle.Box>
            </ContentStyle.Row>
          </ContentStyle.InputAllPage>
          {showInfoBox &&
            <BoxInfo showInfoBox>
              <BoxContent>
                <Toolbar>
                  <CloseButton>
                    <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: false, infoValue: {} })} />
                  </CloseButton>
                </Toolbar>
                <ContentStyle.Title>Descrizione:</ContentStyle.Title>
                <hr />
                <BoxRow>Referenza: {infoValue.referenza}</BoxRow>
                <BoxRow>Titolo: {infoValue.titolo}</BoxRow>
                <BoxRow>Produttore: {this.getInfo(infoValue.produttore, 'produttore')}</BoxRow>
                <BoxRow>Fornitore: {this.getInfo(infoValue.fornitore, 'fornitore')}</BoxRow>
                <BoxRow>Quantità stock: {infoValue.qtaStock}</BoxRow>
                <BoxRow>Quantità minima stock: {infoValue.qtaMinStock}</BoxRow>
                <BoxRow>Settore: {this.getInfo(infoValue.settore, 'settori', 'settore')}</BoxRow>
                <ContentStyle.Row style={{ display: 'flex', alignItems: 'center', gap: 10 }}>Descrizione: <ContentStyle.Textarea disabled value={infoValue.descrizione} /></ContentStyle.Row>
                <BoxRow>Immagine: {infoValue.immagine}</BoxRow>
                <BoxRow>Ultima modifica: {new Date(infoValue.dataOra).toLocaleString('it-IT')}</BoxRow>
              </BoxContent>
            </BoxInfo>}
          {showBoxAggiuntaMateriale &&
            <BoxAggiuntaMateriale show>
              <Toolbar paddingleft='30px' paddingright='30px'>
                <ToolbarItem>
                  <h3>Selezionare gli articoli</h3>
                  <ContentStyle.Search type='text' style={{ marginLeft: 10 }} placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'referenza')} />
                </ToolbarItem>
                <ToolbarItem isend='true'>
                  <FaIcons.FaCheckSquare size={36} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.setState({ showBoxAggiuntaMateriale: false, search: '' })} />
                </ToolbarItem>
              </Toolbar>
              <br />
              <ContentStyle.Table>
                <Table>
                  <Thead>
                    <Trow>
                      {columnAggiuntaMateriale.map((colonne, i) => {
                        return (
                          <Tcol key={i} style={{ textAlign: 'center', width: '16%' }}>
                            <b>{colonne.label}</b>
                          </Tcol>
                        )
                      })}
                    </Trow>
                  </Thead>
                  <Tbody>
                    {articoli.map((item, i) => item.settore === showing &&
                      <Trow key={i}>
                        <Tcol style={{ textAlign: 'center', width: '16%' }}>{item.referenza}</Tcol>
                        <Tcol style={{ textAlign: 'center', width: '16%' }}>{item.titolo}</Tcol>
                        <Tcol style={{ textAlign: 'center', width: '16%' }}>{this.getInfo(item.fornitore, 'fornitore')}</Tcol>
                        <Tcol style={{ textAlign: 'center', width: '16%' }}>{this.getInfo(item.produttore, 'produttore')}</Tcol>
                        <Tcol style={{ textAlign: 'center', width: '16%' }}>
                          {sezioni[section].articoli.filter((e) => e._id === item._id).length === 0 && <FaIcons.FaPlusCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrello(item._id, 'articoli')} />}
                          {sezioni[section].articoli.filter((e) => e._id === item._id).length > 0 && <FaIcons.FaCheckCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrello(item._id, 'articoli')} />}
                        </Tcol>
                        <Tcol style={{ textAlign: 'center', width: '16%' }}>
                          <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: item })} />
                        </Tcol>
                      </Trow>
                    )}
                  </Tbody>
                </Table>
              </ContentStyle.Table>
            </BoxAggiuntaMateriale>}
          {showBoxAggiuntaServizi &&
            <BoxAggiuntaMateriale show>
              <Toolbar>
                <ToolbarItem>
                  <h3>Selezionare i servizi</h3>
                  <ContentStyle.Search type='text' style={{ marginLeft: 10 }} placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'referenza')} />
                </ToolbarItem>
                <ToolbarItem isend='true'>
                  <FaIcons.FaCheckSquare size={36} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.setState({ showBoxAggiuntaServizi: false, search: '' })} />
                </ToolbarItem>
              </Toolbar>
              <br />
              <ContentStyle.Table>
                <Table>
                  <Thead>
                    <Trow>
                      {columnAggiuntaServizi.map((colonne, i) => {
                        return (
                          <Tcol key={i} style={{ textAlign: 'center', width: '25%' }}>
                            <b>{colonne.label}</b>
                          </Tcol>
                        )
                      })}
                    </Trow>
                  </Thead>
                  <Tbody>
                    {servizi.map((item, i) => item.settore === showing &&
                      <Trow key={i}>
                        <Tcol style={{ textAlign: 'center', width: '25%' }}>{item.titolo}</Tcol>
                        <Tcol style={{ textAlign: 'center', width: '25%' }}>{item.descrizione}</Tcol>
                        <Tcol style={{ textAlign: 'center', width: '25%' }}>{this.format(item.costoOrario)}</Tcol>
                        <Tcol style={{ textAlign: 'center', width: '25%' }}>
                          {sezioni[section].servizi.filter((e) => e._id === item._id).length === 0 && <FaIcons.FaPlusCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrello(item._id, 'servizi')} />}
                          {sezioni[section].servizi.filter((e) => e._id === item._id).length > 0 && <FaIcons.FaCheckCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addCarrello(item._id, 'servizi')} />}
                        </Tcol>
                      </Trow>
                    )}
                  </Tbody>
                </Table>
              </ContentStyle.Table>
            </BoxAggiuntaMateriale>}
        </ContentStyle.Content>
      </Loader>
    )
  }
}
