const mockupAzienda = [
  { key: 'intestazione', label: 'Intestazione', type: 'text' },
  { key: 'pIva', label: 'Partita Iva', type: 'text' },
  { key: 'pec', label: 'Pec', type: 'text' },
  { key: 'codiceUnivoco', label: 'Codice univoco', type: 'text' },
  { key: 'email', label: 'Email', type: 'text' },
  { key: 'telefono', label: 'Numero di telefono', type: 'text' },
  { key: 'contabilita', label: 'Contabilità', type: 'text' },
  { key: 'referente', label: 'Referente', type: 'text' },
  { key: 'indirizzo', label: 'Indirizzo', type: 'text' },
  { key: 'citta', label: 'Città', type: 'text' },
  { key: 'cap', label: 'CAP', type: 'text' },
  { key: 'nazione', label: 'Nazione', type: 'text' }
]

const mockupPrivato = [
  { key: 'nome', label: 'Nome', type: 'text' },
  { key: 'cognome', label: 'Cognome', type: 'text' },
  { key: 'codiceFiscale', label: 'Codice Fiscale', type: 'text' },
  { key: 'email', label: 'Email', type: 'text' },
  { key: 'telefono', label: 'Numero di telefono', type: 'text' },
  { key: 'indirizzo', label: 'Indirizzo', type: 'text' },
  { key: 'citta', label: 'Città', type: 'text' },
  { key: 'cap', label: 'CAP', type: 'text' },
  { key: 'nazione', label: 'Nazione', type: 'text' }
]

const mockupState = {
  _id: '',
  loading: true,
  isPrivate: '1', // 0 - Azienda || 1 - Privato
  nome: '', // solo se privato
  cognome: '', // solo se privato
  email: '',
  telefono: '',
  indirizzo: '',
  citta: '',
  cap: '',
  nazione: '',
  codiceFiscale: '', // solo se privato
  pIva: '', // solo se azienda
  pec: '', // solo se azienda
  codiceUnivoco: '', // solo se azienda
  intestazione: '', // solo se azienda
  contabilita: '', // solo se azienda
  referente: '', // solo se azienda
  azienda: '',
  role: 'cliente'
}

const columnAziende = [
  {
    id: 'Intestazione',
    label: 'Intestazione'
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'telefono',
    label: 'Telefono'
  },
  {
    id: 'nazione',
    label: 'Nazione'
  },
  {
    id: 'abilitato',
    label: 'Abilitata'
  },
  {
    id: 'visualizza',
    label: 'Visualizza'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  }
]

const columnClienti = [
  {
    id: 'intestazione',
    label: 'Intestazione'
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'telefono',
    label: 'Telefono'
  },
  {
    id: 'isPrivate',
    label: 'Categoria'
  },
  {
    id: 'info',
    label: 'Info'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  }
]

export { mockupAzienda, columnAziende, mockupPrivato, columnClienti, mockupState }
