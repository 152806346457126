import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import Loader from '../../Library/Tools/Loader'
import { tipoRimborso } from './MockupRimborsi'

export default class NuovoRimborso extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      tecnico: '',
      tipo: '',
      data: '',
      valore: 0,
      descrizione: '',
      azienda: '',
      immagineUrl: '',
      file: null
    }
  }

  async componentDidMount () {
    const { azienda, _id } = LocalStorage.get('user')
    this.setState({ azienda, tecnico: _id, loading: false })
  }

  handleFileSelect (event) {
    const files = event.target.files
    if (files.length > 0) {
      const file = files[0]
      const immagineUrl = URL.createObjectURL(file)
      this.setState({ file, immagineUrl })
    } else {
      this.setState({ file: null, immagineUrl: '' })
    }
  }

  handleEliminaFoto () {
    const { immagineUrl } = this.state
    if (immagineUrl !== '') {
      this.setState({ immagineUrl: '', file: null })
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/rimborsi')
    }
  }

  async salvaRimborso () {
    const { tecnico, azienda, tipo, valore, data, descrizione, immagineUrl, file } = this.state
    if (!tipo || !valore || !data || !descrizione || !immagineUrl) {
      swaller.error('Compila tutti i campi e riprova')
    } else if (valore <= 0) {
      swaller.error('Il valore del rimborso deve essere superiore a 0')
    } else {
      swaller.info('Salvataggio in corso...')
      try {
        const upload = await Api.upload(file)
        const immagineUrl = upload.data.file.url
        const res = await Api.post('/rimborsi', {
          tecnico,
          tipo,
          valore,
          data,
          descrizione,
          dataOra: new Date().toISOString(),
          azienda,
          immagineUrl
        })
        if (res.ok) {
          swaller.success('Rimborso salvato correttamente!')
          setTimeout(() => this.props.navigate('/rimborsi'), 2000)
        } else {
          swaller.error('Errore durante la creazione del rimborso, prego riprovare!')
        }
      } catch (error) {
        swaller.error('Errore durante l\'aggiornamento del rimborso, prego riprovare!')
      }
    }
  }

  render () {
    const { loading, tipo, descrizione, immagineUrl } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.salvaRimborso()}><FaIcons.FaCheck />Salva rimborso</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form width='90vw' height='70vh'>
            <ContentStyle.Title>Fornire le indicazioni seguenti: </ContentStyle.Title>
            <ContentStyle.FormColumn>
              <ContentStyle.FormRow justify='space-between'>
                <ContentStyle.Label>Tipo: </ContentStyle.Label>
                <ContentStyle.Select value={tipo} onChange={(e) => this.setState({ tipo: e.target.value })}>
                  {tipoRimborso.map((opzione) => (
                    <option key={opzione.value} value={opzione.value} disabled={opzione.disabled}>
                      {opzione.label}
                    </option>))}
                </ContentStyle.Select>
              </ContentStyle.FormRow>
              <ContentStyle.FormRow justify='space-between'>
                <ContentStyle.Label>Data: </ContentStyle.Label>
                <ContentStyle.Calendario type='datetime-local' onChange={(e) => this.setState({ data: e.target.value })} />
              </ContentStyle.FormRow>
              <ContentStyle.FormRow justify='space-between'>
                <ContentStyle.Label>Valore: </ContentStyle.Label>
                <ContentStyle.Text type='number' placeholder='Costo €' min='0' onChange={(e) => this.setState({ valore: Number(e.target.value) })} />
              </ContentStyle.FormRow>
              <ContentStyle.FormRow justify='space-between'>
                <ContentStyle.Label>Descrizione: </ContentStyle.Label>
                <ContentStyle.Textarea type='text' placeholder='descrizione' value={descrizione} onChange={(e) => this.setState({ descrizione: e.target.value })} />
              </ContentStyle.FormRow>
            </ContentStyle.FormColumn>
            <ContentStyle.FormRow justify='flex-start'>
              <ContentStyle.RowItem direction='column' align='flex-start'>
                <ContentStyle.Label>Foto giustificativo: </ContentStyle.Label>
                {immagineUrl !== '' && <Button type='danger' onClick={() => this.handleEliminaFoto()}>Elimina</Button>}
                <Button type='info' outline='true'>
                  <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    Upload
                    <ContentStyle.Text style={{ width: '0px', visibility: 'hidden', display: 'block', cursor: 'pointer', marginLeft: '30px' }} type='file' accept='image/*' onChange={(e) => this.handleFileSelect(e)} />
                  </label>
                </Button>
              </ContentStyle.RowItem>
              <ContentStyle.RowItem justify='flex-end'>
                {immagineUrl !== '' && <ContentStyle.BoxImmagine src={immagineUrl} alt='' />}
              </ContentStyle.RowItem>
            </ContentStyle.FormRow>
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
