import React, { Component } from 'react'
import loader from '../../Assets/loader.svg'

export default class Loader extends Component {
  render () {
    const { loading, children } = this.props

    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {loading
          ? (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '256px', height: '256px', backgroundImage: `url(${loader})` }} />
              <i>Caricamento in corso...</i>
            </div>)
          : children}
      </div>
    )
  }
}
