import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { QRCodeSVG } from 'qrcode.react'
import Loader from '../../Library/Tools/Loader'
const { version } = require('../../../package.json')

export default class Settaggi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      currentPassword: '',
      newPassword: '',
      checkNewPassword: '',
      otp: false,
      qrcode: '',
      codiceVerifica: '',
      email: ''
    }
  }

  async componentDidMount () {
    const { otp, email } = LocalStorage.get('user')
    await this.getQrcode()
    this.setState({ otp, email, loading: false })
  }

  async changePassword () {
    const { currentPassword, newPassword, checkNewPassword, email } = this.state
    const _id = LocalStorage.get('_id')
    const logres = await Api.login(email, currentPassword) // verifico la password corrente
    if (!logres) {
      swaller.error('La password corrente non è corretta')
    } else if (newPassword === 'password') {
      swaller.error('La nuova password non può essere password')
    } else if (newPassword === checkNewPassword) {
      const res = await Api.patch(`/users/${_id}`, { password: newPassword, isFirstLogin: false }) // aggiornamento nuova password
      if (res.ok) {
        swaller.success('La password è stata modificata con successo')
        this.props.navigate('/home')
      }
    } else {
      swaller.error('Le password inserite non corrispondono')
    }
  }

  async getQrcode () {
    const res = await Api.get('/otp/qrcode')
    if (!res.ok) {
      swaller.error('Non è stato possbile recuperare il qrcode')
    } else {
      this.setState({ qrcode: res.data })
    }
  }

  async handleKeyDown (event, isPassword) {
    if (event.keyCode === 13) {
      if (isPassword) {
        await this.resetPassword()
      } else {
        await this.checkOtp()
      }
    }
  }

  async checkOtp () {
    const { codiceVerifica } = this.state
    const res = await Api.post('/otp', { otp: codiceVerifica })
    if (res.ok && res.data) {
      if (res.data.success) {
        swaller.success('OTP corretto')
        this.setState({ codiceVerifica: '' })
      } else {
        swaller.error('OTP errato')
      }
    } else {
      swaller.error('Impossibile contattare il server')
    }
  }

  async changeOtpStatus (otp) {
    const _id = LocalStorage.get('_id')
    const res = await Api.patch(`/users/${_id}`, { otp }) // aggiornamento otp status
    if (res.ok) {
      swaller.success('Stato OTP modificato con successo')
      const user = LocalStorage.get('user')
      user.otp = otp
      LocalStorage.set('user', user)
      this.setState({ otp })
    }
  }

  render () {
    const { loading, currentPassword, newPassword, checkNewPassword, otp, qrcode, codiceVerifica } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            Versione: {version}
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form flexdirection='row'>
            <ContentStyle.FormColumn>
              <ContentStyle.Title>Cambio password</ContentStyle.Title>
              <ContentStyle.FormRow justify='center' gap='50px'>
                <ContentStyle.Label>Corrente:</ContentStyle.Label>
                <ContentStyle.Text width='220px' type='password' placeholder='password corrente' value={currentPassword} onChange={(e) => this.setState({ currentPassword: e.target.value })} />
              </ContentStyle.FormRow>
              <ContentStyle.FormRow justify='center' gap='70px'>
                <ContentStyle.Label>Nuova:</ContentStyle.Label>
                <ContentStyle.Text width='220px' type='password' placeholder='nuova password' value={newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
              </ContentStyle.FormRow>
              <ContentStyle.FormRow justify='center' gap='35px'>
                <ContentStyle.Label>Conferma:</ContentStyle.Label>
                <ContentStyle.Text width='220px' type='password' placeholder='conferma password' onKeyDown={e => this.handleKeyDown(e, true)} value={checkNewPassword} onChange={(e) => this.setState({ checkNewPassword: e.target.value })} />
              </ContentStyle.FormRow>
              <ContentStyle.FormRow justify='center' gap='50px'>
                <Button type='primary' outline='true' onClick={async () => await this.changePassword()}>Aggiorna</Button>
              </ContentStyle.FormRow>
            </ContentStyle.FormColumn>
            <ContentStyle.FormColumn>
              <ContentStyle.Title>Autenticazione OTP</ContentStyle.Title>
              <QRCodeSVG value={qrcode} />
              <ContentStyle.Text text='center' type='text' placeholder='codice di verifica' value={codiceVerifica} onKeyDown={(e) => this.handleKeyDown(e, false)} onChange={(e) => this.setState({ codiceVerifica: e.target.value })} />
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <br />
                <Button type='info' outline='true' onClick={async () => await this.checkOtp()}>Controlla OTP</Button>
                <Button width='60px' outline='true' onClick={async () => await this.changeOtpStatus(!otp)} type={otp ? 'success' : 'danger'}>{otp ? <><FaIcons.FaCheck /><span>Attivo</span></> : <><FaIcons.FaTimes /><span>Disattivato</span></>}</Button>
              </div>
            </ContentStyle.FormColumn>
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
