import styled from 'styled-components'
import { palette } from '../Library/Tools/Utilities'

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the modal itself */
  width: ${({ width }) => width || '70%'};
  height: ${({ height }) => height || '80%'};
  position: absolute;
  z-index: 999;
  visibility: ${({ visible }) => visible || 'hidden'};
  background: white;
  border-radius: 20px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.25); /* box-shadow to cover the whole screen */
`

export const ModalIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: ${({ color }) => color || `${palette.danger}`};
  position: fixed;
  top: 8vh;
  right: 7vw;
  cursor: pointer;
`

export const ModalTitle = styled.div`
  width: ${({ width }) => width || 'auto'};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  justify-content: ${({ justify }) => justify || 'center'};
  padding-left: ${({ paddingleft }) => paddingleft || '0'};
  padding-right: ${({ paddingright }) => paddingright || '0'};
  align-items: center;
  font-weight: bold;
  font-size: 36px;
`

export const ModalContent = styled.div`
  width: ${({ width }) => width || '100%'};
  display: flex;
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  flex-direction: ${({ direction }) => direction || 'column'};
  justify-content: center;
  align-items: ${({ align }) => align || 'flex-start'};
  gap: 8px;
`

export const ModalLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ position }) => position || 'center'};
  align-items: center;
  gap: 10px;
  font-size: 26px;
`

export const ModalImageContainer = styled.div`
  display: flex;
  max-width: 650px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 20px;
`
