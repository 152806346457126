const mockupUtenti = [
  { key: 'nome', label: 'Nome', type: 'text' },
  { key: 'cognome', label: 'Cognome', type: 'text' },
  { key: 'codiceFiscale', label: 'Codice Fiscale', type: 'text' },
  { key: 'email', label: 'Email', type: 'text' },
  { key: 'telefono', label: 'Numero di telefono', type: 'text' },
  { key: 'indirizzo', label: 'Indirizzo', type: 'text' },
  { key: 'citta', label: 'Città', type: 'text' },
  { key: 'cap', label: 'CAP', type: 'text' },
  { key: 'nazione', label: 'Nazione', type: 'text' }
]

const mockupColonneUtenti = ['Anagrafica', 'Email', 'Telefono', 'Abilitato', 'Visualizza', 'Modifica', 'Reset Password']

export { mockupUtenti, mockupColonneUtenti }
