import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { FaArrowCircleLeft, FaEdit } from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api } from '../../Services'
import Loader from '../../Library/Tools/Loader'

export default class ModificaRicavo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      _id: '',
      settori: [],
      settore: '',
      tipo: '',
      ricarico: 0,
      azienda: ''
    }
  }

  async componentDidMount () {
    try {
      const { _id, settore, tipo, ricarico, azienda } = this.props.location.state
      this.setState({ _id, settore, tipo, ricarico, azienda })
      const settoriResponse = await Api.get('/settori', { azienda })
      if (settoriResponse.ok) {
        const settori = settoriResponse.data
        this.setState({ settori, loading: false })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Errore durante il recupero dei dati')
    }
  }

  async aggiornaRicavo () {
    const { _id, ricarico } = this.state
    if (!ricarico) {
      swaller.error('Assicurati di compilare tutti i campi.')
      return
    }
    if (ricarico <= 0 || ricarico > 100) {
      swaller.error('La percentuale di ricarico deve essere compresa tra 0 e 100. Ricontrollare')
      return
    }
    swaller.info('Salvataggio in corso...')
    const res = await Api.patch(`/ricavi/${_id}`, {
      ricarico
    })
    if (res.ok) {
      swaller.success('Ricavo salvato correttamente!')
      setTimeout(() => this.props.navigate('/ricavi'), 2000)
    } else {
      swaller.error('Errore durante la creazione del ricavo, prego riprovare!')
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/ricavi')
    }
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  render () {
    const { loading, settore, tipo, ricarico } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaArrowCircleLeft />Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.aggiornaRicavo()}> <FaEdit /> Aggiorna ricavo</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Form width='40%'>
            <ContentStyle.FormRow gap='25px'>
              <ContentStyle.Title>Fornire le indicazioni seguenti: </ContentStyle.Title>
              <ContentStyle.Row justifycontent='flex-start'>
                <ContentStyle.Label>Settore: </ContentStyle.Label>
                <h1>{this.getSettore(settore)}</h1>
              </ContentStyle.Row>
              <ContentStyle.Row>
                <ContentStyle.Label>Tipo: </ContentStyle.Label>
                <h1>{tipo}</h1>
              </ContentStyle.Row>
              <ContentStyle.Row>
                <ContentStyle.Label>Ricarico %: </ContentStyle.Label>
                <ContentStyle.Text value={ricarico} type='number' placeholder='ricarico %' onChange={(e) => this.setState({ ricarico: Number(e.target.value) })} />
              </ContentStyle.Row>
            </ContentStyle.FormRow>
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
