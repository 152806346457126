import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import Loader from '../../Library/Tools/Loader'
import { mockupAzienda, mockupPrivato, mockupState } from './MockupClienti'

export default class NuovoCliente extends Component {
  constructor (props) {
    super(props)
    this.state = mockupState
  }

  componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    this.setState({ azienda, loading: false })
  }

  async salvaCliente () {
    try {
      delete this.state._id
      swaller.info('Salvataggio in corso...')
      const res = await Api.post('/users', this.state)
      if (res.ok) {
        swaller.success('Cliente salvato correttamente!')
        setTimeout(() => this.props.navigate('/clienti'), 2000)
      } else {
        swaller.error('Errore durante la creazione cliente, prego riprovare!')
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante il salvataggio del cliente.')
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/clienti')
    }
  }

  renderFields (fields) {
    const numeroColonne = 3
    const lunghezzaCampo = Math.ceil(fields.length / numeroColonne)
    const array = []
    for (let index = 0; index < numeroColonne; index++) {
      array.push(
        <ContentStyle.FormColumn width={`${100 / numeroColonne}%`} key={index}>
          {fields.slice(index * lunghezzaCampo, (index + 1) * lunghezzaCampo).map(({ key, label, type }) =>
            <ContentStyle.Row width='90%' key={key}>
              <ContentStyle.Label><b>{label}:</b></ContentStyle.Label>
              <ContentStyle.Text
                type={type}
                placeholder={label.toLowerCase()}
                value={this.state[key]}
                onChange={(e) => this.setState({ [key]: e.target.value })}
              />
            </ContentStyle.Row>
          )}
        </ContentStyle.FormColumn>
      )
    }
    return array
  }

  render () {
    const { isPrivate, loading } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.salvaCliente()}><FaIcons.FaCheck />Aggiungi Cliente</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content direction='row'>
          <ContentStyle.Row width='auto' gap='20px'>
            <ContentStyle.Label>Tipologia: </ContentStyle.Label>
            <ContentStyle.Select value={isPrivate} onChange={(e) => this.setState({ isPrivate: e.target.value })}>
              <option value='1'>Privato</option>
              <option value='0'>Azienda</option>
            </ContentStyle.Select>
          </ContentStyle.Row>
          <ContentStyle.Form flexdirection='row' width='100%'>
            {isPrivate === '0'
              ? this.renderFields(mockupAzienda)
              : this.renderFields(mockupPrivato)}
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
