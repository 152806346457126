export const columnUser = [
  {
    id: 'anagrafica',
    label: 'Anagrafica'
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'telefono',
    label: 'Telefono'
  },
  {
    id: 'abilitato',
    label: 'Abilitato'
  },
  {
    id: 'visualizza',
    label: 'Visualizza'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'reset',
    label: 'Reset Password'
  }
]

export const columnPreventivi = [
  {
    id: 'nPreventivo',
    label: 'N° Preventivo'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'settori',
    label: 'Settori'
  },
  {
    id: 'totale',
    label: 'Totale'
  },
  {
    id: 'variazione',
    label: 'Variazione'
  },
  {
    id: 'creazione',
    label: 'Creazione'
  },
  {
    id: 'stato',
    label: 'Stato'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'resoconti',
    label: 'Resoconti'
  },
  {
    id: 'esporta',
    label: 'Esporta'
  }
]

export const columnResocontoPreventivi = [
  {
    id: 'nPreventivo',
    label: 'Numero Preventivo'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'tipologia',
    label: 'Tipologia'
  },
  {
    id: 'articoli',
    label: 'Articoli'
  },
  {
    id: 'totale',
    label: 'Totale'
  },
  {
    id: 'dataOraCreazione',
    label: 'Data creazione'
  },
  {
    id: 'creatore',
    label: 'Creato da'
  },
  {
    id: 'stato',
    label: 'Stato'
  },
  {
    id: 'info',
    label: 'Info'
  },
  {
    id: 'resoconto',
    label: 'Resoconto'
  },
  {
    id: 'esporta',
    label: 'Esporta'
  }
]

export const columnMagazzino = [
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'produttore',
    label: 'Produttore'
  },
  {
    id: 'fornitore',
    label: 'Fornitore'
  },
  {
    id: 'qtaStock',
    label: 'Quantità stock'
  },
  {
    id: 'qtaMinStock',
    label: 'Quantità minima stock'
  },
  {
    id: 'settore',
    label: 'Settore'
  },
  {
    id: 'info',
    label: 'Info'
  },
  {
    id: 'esporta',
    label: 'Esporta'
  }
]

export const columnAggiuntaMateriale = [
  {
    id: 'referenza',
    label: 'Referenza'
  },
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'fornitore',
    label: 'Fornitore'
  },
  {
    id: 'produttore',
    label: 'Produttore'
  },
  {
    id: 'aggiungi',
    label: 'Aggiungi'
  },
  {
    id: 'info',
    label: 'Info'
  }
]

export const columnFatturaPreventivo = [
  {
    id: 'numero',
    label: 'Numero preventivo'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'fattura',
    label: 'Fattura'
  }
]
export const columnFatturaRichiesta = [
  {
    id: 'titolo',
    label: 'Titolo richiesta'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'fattura',
    label: 'Fattura'
  }
]

export const columnResocontiRichieste = [
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'settore',
    label: 'Settore'
  },
  {
    id: 'tecnico',
    label: 'Tecnico'
  },
  {
    id: 'urgenza',
    label: 'Urgenza'
  },
  {
    id: 'dataOra',
    label: 'Apertura'
  },
  {
    id: 'chiusuraDataOra',
    label: 'Chiusura'
  },
  {
    id: 'stato',
    label: 'Stato'
  },
  {
    id: 'resoconto',
    label: 'Resoconto'
  },
  {
    id: 'info',
    label: 'Info'
  },
  {
    id: 'esporta',
    label: 'Esporta'
  }
]

export const columnOrdini = [
  {
    id: 'nOrdine',
    label: 'Numero Ordine'
  },
  {
    id: 'articoli',
    label: 'Articoli'
  },
  {
    id: 'dataOraCreazione',
    label: 'Data creazione'
  },
  {
    id: 'creatore',
    label: 'Creato da'
  },
  {
    id: 'ordinato',
    label: 'Ordinato'
  },
  {
    id: 'ordinatoDa',
    label: 'Evaso da'
  },
  {
    id: 'info',
    label: 'Info'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'esporta',
    label: 'Esporta'
  },
  {
    id: 'tipoOrdine',
    label: 'Tipo ordine'
  }
]

export const columnDettaglioOrdini = [
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'referenza',
    label: 'Referenza'
  },
  {
    id: 'quantita',
    label: 'Quantità'
  },
  {
    id: 'produttore',
    label: 'Produttore'
  },
  {
    id: 'fornitore',
    label: 'Fornitore'
  },
  {
    id: 'settore',
    label: 'Settore'
  }
]

export const columnDettaglioPreventivi = [
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'quantita',
    label: 'Quantità'
  },
  {
    id: 'produttore',
    label: 'Produttore'
  },
  {
    id: 'fornitore',
    label: 'Fornitore'
  },
  {
    id: 'settore',
    label: 'Settore'
  }
]

export const columnInstallazioni = [
  {
    id: 'codiceInstallazione',
    label: 'Codice Installazione'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'descrizione',
    label: 'Descrizione'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'info',
    label: 'Info'
  }
]

export const columnScatole = [
  {
    id: 'nScatola',
    label: 'Numero'
  },
  {
    id: 'assegantario',
    label: 'Assegnatario'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  }
]

export const columnResocontiPage = [
  {
    id: 'tipo',
    label: 'Tipo'
  },
  {
    id: 'tecnico',
    label: 'Tecnico'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'dataInizio',
    label: 'Inizio'
  },
  {
    id: 'dataFine',
    label: 'Fine'
  },
  {
    id: 'descrizione',
    label: 'Descrizione'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'info',
    label: 'Info'
  }
]

export const columnFatture = [
  {
    id: 'nFattura',
    label: 'Fattura'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'importo',
    label: 'Importo'
  },
  {
    id: 'saldata',
    label: 'Saldata'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'info',
    label: 'Info'
  }
]

export const columnRicavi = [
  {
    id: 'settore',
    label: 'Settore'
  },
  {
    id: 'tipo',
    label: 'Tipo'
  },
  {
    id: 'ricarico',
    label: 'Ricarico'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  }
]

export const columnResoconti = [
  {
    id: 'tipo',
    label: 'Tipo'
  },
  {
    id: 'tecnico',
    label: 'Tecnico'
  },
  {
    id: 'cliente',
    label: 'Cliente'
  },
  {
    id: 'dataInizio',
    label: 'Inizio'
  },
  {
    id: 'dataFine',
    label: 'Fine'
  },
  {
    id: 'descrizione',
    label: 'Descrizione'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'info',
    label: 'Info'
  }
]
