import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'
import { mockupResoconti, columnResoconti } from './MockupRichieste'

export default class VisualizzaResoconti extends Component {
  constructor (props) {
    super(props)
    this.state = mockupResoconti
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      const { _id } = this.props.location.state
      const resoconti = await Api.get('/resoconti', { azienda, referenza: _id })
      const tecnici = await Api.get('/users', { azienda, role: 'utente' })
      const clienti = await Api.get('/users', { azienda, role: 'cliente' })
      const utenti = await Api.get('/users', { azienda })
      const richieste = await Api.get('/richieste', { _id })
      if (tecnici.ok && clienti.ok && utenti.ok && richieste.ok) {
        this.setState({ tecnici: tecnici.data, clienti: clienti.data, utenti: utenti.data, richieste: richieste.data[0], resoconti: resoconti.data, data: resoconti.data, azienda, _id, loading: false })
      } else {
        swaller.error('Errore durante il recupero dei dati')
      }
    } catch (error) {
      swaller.error('Errore durante il recupero dei dati')
    }
  }

  getTecnico (_id) {
    const { tecnici } = this.state
    const tecnico = tecnici.find(tecnico => tecnico._id === _id)
    return tecnico ? `${tecnico.nome} ${tecnico.cognome}` : ''
  }

  render () {
    const { loading, resoconti, settori, _id } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/richieste')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value)} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/resoconti/nuovo', { state: _id, replace: false })}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuovo Resoconto</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          <ContentStyle.Table>
            <Table>
              <Thead>
                <Trow>
                  {columnResoconti.map((colonne, i) =>
                    <Tcol key={i}>
                      {colonne.label === 'Settore'
                        ? (
                          <ContentStyle.Select onChange={(e) => this.sectorFilter(e.target.value)}>
                            <option value='settore'>Tutti i settori</option>
                            {settori.length > 0 && settori.map((item, j) => (
                              <option value={item._id} key={j}>{item.settore}</option>
                            ))}
                          </ContentStyle.Select>)
                        : <b>{colonne.label}</b>}
                    </Tcol>)}
                </Trow>
              </Thead>
              <Tbody>
                {resoconti.length > 0 && resoconti.map((item, i) =>
                  <Trow key={i}>
                    <Tcol>{this.getTecnico(item.tecnico)}</Tcol>
                    <Tcol>{new Date(item.dataInizio).toLocaleString('it-IT')}</Tcol>
                    <Tcol>{new Date(item.dataFine).toLocaleString('it-IT')}</Tcol>
                    <Tcol>{item.descrizione}</Tcol>
                    <Tcol style={{ textAlign: 'center' }}>
                      <FaIcons.FaEdit size={24} style={{ color: palette.info }} onClick={() => this.props.navigate('/resoconti/modifica', { state: item, replace: false })} />
                    </Tcol>
                  </Trow>
                )}
              </Tbody>
            </Table>
          </ContentStyle.Table>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
