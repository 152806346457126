import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import Loader from '../../Library/Tools/Loader'
export default class NuovoCosto extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      titolo: '',
      costo: 0,
      descrizione: '',
      dataOra: '',
      azienda: '',
      isAnnual: true
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    this.setState({ azienda, loading: false })
  }

  async salvaCosto () {
    const { azienda, titolo, costo, descrizione, isAnnual } = this.state
    swaller.info('Salvataggio in corso...')
    const res = await Api.post('/costi', {
      titolo,
      costo,
      descrizione,
      dataOra: new Date().toISOString(),
      azienda,
      isAnnual
    })
    if (res.ok) {
      swaller.success('Costo salvata correttamente!')
      setTimeout(() => this.props.navigate('/costi'), 2000)
    } else {
      swaller.error('Errore durante la creazione del costo, prego riprovare!')
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/costi')
    }
  }

  render () {
    const { loading, titolo, descrizione, isAnnual } = this.state
    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.salvaCosto()}><FaIcons.FaCheck />Crea costo</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content border='none' boxshadow='none' background='transparent'>
          <ContentStyle.InputAllPage>
            <br />
            <br />
            <ContentStyle.Title>Fornire le indicazioni seguenti: </ContentStyle.Title>
            <hr />
            <ContentStyle.Row>
              <ContentStyle.Label>Titolo: </ContentStyle.Label>
              <ContentStyle.Text type='text' placeholder='titolo' value={titolo} onChange={(e) => e.target.value != null && this.setState({ titolo: e.target.value })} />
            </ContentStyle.Row>
            <ContentStyle.Row>
              <ContentStyle.Label>Costo: </ContentStyle.Label>
              <ContentStyle.Text type='number' placeholder='Costo €' min='0' onChange={(e) => this.setState({ costo: Number(e.target.value) })} />
            </ContentStyle.Row>
            <ContentStyle.Row>
              <ContentStyle.Label>Periodicità: </ContentStyle.Label>
              <Button type={isAnnual ? 'info' : 'danger'} outline='true' onClick={() => this.setState({ isAnnual: !isAnnual })}>{isAnnual ? 'Annuale' : 'Mensile'}</Button>
            </ContentStyle.Row>
            <ContentStyle.Row>
              <ContentStyle.Label>Descrizione: </ContentStyle.Label>
              <ContentStyle.Textarea type='text' placeholder='descrizione' value={descrizione} onChange={(e) => e.target.value != null && this.setState({ descrizione: e.target.value })} />
            </ContentStyle.Row>
          </ContentStyle.InputAllPage>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
