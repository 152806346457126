const columnRimborsi = [
  {
    id: 'utente',
    label: 'Utente'
  },
  {
    id: 'tipo',
    label: 'Tipo rimborso'
  },
  {
    id: 'data',
    label: 'Data giustificativo'
  },
  {
    id: 'valore',
    label: 'Valore'
  },
  {
    id: 'descrizione',
    label: 'Descrizione'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'info',
    label: 'Info'
  }
]

const inputFields = [
  { label: 'Data:', type: 'datetime-local', stateKey: 'data', onChange: (e) => this.setState({ data: e.target.value }) },
  { label: 'Valore:', type: 'number', placeholder: 'Costo €', stateKey: 'valore', onChange: (e) => this.setState({ valore: Number(e.target.value) }) },
  { label: 'Descrizione:', type: 'text', placeholder: 'descrizione', stateKey: 'descrizione', onChange: (e) => this.setState({ descrizione: e.target.value }) },
  { label: 'Foto giustificativo:', type: 'file', placeholder: 'immagine', onChange: (e) => this.handleFileSelect(e) }
]

const tipoRimborso = [
  { value: '', label: 'Seleziona un tipo...', disabled: true },
  { value: 'Parcheggio', label: 'Parcheggio' },
  { value: 'Carburante', label: 'Carburante' },
  { value: 'Autostrada', label: 'Autostrada' },
  { value: 'Pasto', label: 'Pasto' },
  { value: 'Hotel', label: 'Hotel' },
  { value: 'Altro', label: 'Altro...' }
]

export { columnRimborsi, inputFields, tipoRimborso }
