const columnCosti = [
  {
    id: 'titolo',
    label: 'Titolo'
  },
  {
    id: 'costo',
    label: 'Costo'
  },
  {
    id: 'descrizione',
    label: 'Descrizione'
  },
  {
    id: 'periodicità',
    label: 'Periodicità'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  },
  {
    id: 'info',
    label: 'Info'
  }
]

export { columnCosti }
