import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, Modal, ModalIcon, ModalTitle, ModalContent, ModalLabel } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnRimborsi } from './MockupRimborsi'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { Config } from '../../Global'
import Loader from '../../Library/Tools/Loader'

export default class Rimborsi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      _id: '',
      role: '',
      data: [],
      rimborsi: [],
      utenti: [],
      immagineUrl: '',
      infoValue: {},
      show: 'hidden',
      azienda: '',
      descrizione: ''
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const rimborsi = await Api.get('/rimborsi', { azienda })
    const utenti = await Api.get('/users', { azienda, role: 'utente' })
    if (utenti.ok && rimborsi.ok) {
      this.setState({ utenti: utenti.data, rimborsi: rimborsi.data, data: rimborsi.data, loading: false })
    } else {
      swaller.error('Non è stato possibile recuperare i dati')
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageRimborsi = data.slice(startIndex, endIndex)
    return currentPageRimborsi
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  getUtente (_id) {
    const { utenti } = this.state
    const utente = utenti.find((utente) => utente._id === _id)
    return utente ? `${utente.nome} ${utente.cognome}` : ''
  }

  render () {
    const { data, currentPage, itemPerPage, rimborsi, show, immagineUrl, loading, infoValue } = this.state
    const totalPages = Math.ceil(rimborsi.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/rimborsi/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuovo Rimborso</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnRimborsi.map((colonne, i) =>
                    <Tcol key={i}>
                      <b>{colonne.label}</b>
                    </Tcol>
                  )}
                </Thead>
                <Tbody>
                  {rimborsi.length > 0 && this.showData().map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{this.getUtente(item.tecnico)}</Tcol>
                      <Tcol>{item.tipo}</Tcol>
                      <Tcol>{new Date(item.data).toLocaleDateString()}</Tcol>
                      <Tcol>{Config.format(item.valore)}</Tcol>
                      <Tcol>{item.descrizione}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/rimborsi/modifica', { state: item, replace: false })} />
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ show: 'visible', infoValue: item, immagineUrl })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun rimborso presente</i>}
        </ContentStyle.Content>
        <Modal visible={show}>
          <ModalIcon onClick={() => this.setState({ show: 'hidden', infoValue: {} })}><FaIcons.FaTimes size={48} /></ModalIcon>
          <ModalTitle><b>Informazioni:</b></ModalTitle>
          <ModalContent align='center'>
            <ModalLabel><b>Tecnico: </b>{this.getUtente(infoValue.tecnico)}</ModalLabel>
            <ModalLabel><b>Tipo: </b>{infoValue.tipo}</ModalLabel>
            <ModalLabel><b>Data: </b>{new Date(infoValue.data).toLocaleDateString()}</ModalLabel>
            <ModalLabel><b>Valore: </b>{Config.format(infoValue.valore)}</ModalLabel>
            <ModalLabel><b>Descrizione: </b>{infoValue.descrizione}</ModalLabel>
            <ModalLabel><b>Anteprima immagini:</b></ModalLabel>
            <ContentStyle.BoxImmagine src={infoValue.immagineUrl} alt='Foto giustificativo' />
          </ModalContent>
        </Modal>
      </Loader>
    )
  }
}
