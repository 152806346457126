import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, BoxInfo, BoxContent, BoxRow } from '../../Components'
import { Button, Button2, CloseButton, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import { palette } from '../../Library/Tools/Utilities'
import { PdfDownload } from '../../Library/Tools/Pdf'
import { PdfRichieste } from '../../Pdf'
import Loader from '../../Library/Tools/Loader'
import { mockupState, columnRichieste } from './MockupRichieste'

export default class Richieste extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...mockupState,
      currentPage: 0,
      itemPerPage: 6,
      richieste: [],
      defaultUser: '',
      urgenza: ''
    }
  }

  async componentDidMount () {
    try {
      const { azienda, _id } = LocalStorage.get('user')
      const clienti = await Api.get('/users', { azienda, role: 'cliente' })
      const settori = await Api.get('/settori', { azienda })
      const tecnici = await Api.get('/users', { azienda, role: 'utente' })
      const richieste = await Api.get('/richieste', { azienda })
      if (clienti.ok && settori.ok && tecnici.ok && richieste.ok) {
        this.setState({ defaultUser: _id, clienti: clienti.data, settori: settori.data, tecnici: tecnici.data, richieste: richieste.data, data: richieste.data, loading: false, azienda })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Errore durante il recupero dei dati')
    }
  }

  showData () {
    const { data, defaultUser, itemPerPage, currentPage, urgenza } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    let currentPageRichieste
    defaultUser
      ? currentPageRichieste = data.filter(item => item.tecnico.includes(defaultUser) && item.urgenza.includes(urgenza)).slice(startIndex, endIndex)
      : currentPageRichieste = data.slice(startIndex, endIndex)
    return currentPageRichieste
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  filter (search) {
    const { richieste } = this.state
    search === ''
      ? this.setState({ data: richieste })
      : this.setState({ data: richieste.filter(item => this.getCliente(item.cliente).toLowerCase().includes(search.toLowerCase())) })
  }

  infoBox (stato, item) {
    this.setState({ showInfoBox: stato, content: item })
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  getTecnico (_id) {
    const { tecnici } = this.state
    const tecnico = tecnici.find(tecnico => tecnico._id === _id)
    return tecnico ? `${tecnico.nome} ${tecnico.cognome}` : ''
  }

  pdfExport (richiesta) {
    return { ...richiesta, cliente: this.getCliente(richiesta.cliente), settore: this.getSettore(richiesta.settore), tecnico: this.getTecnico(richiesta.tecnico) }
  }

  filterStatus (item, value) {
    const { richieste } = this.state
    let data = richieste
    let stato = ''
    let tecnico = ''
    let urgenza = ''
    if (item === '') {
      this.setState({ data: richieste, stato, tecnico, defaultUser: '', urgenza })
      return
    }
    if (item !== '') {
      switch (value) {
        case 'stato':
          data = richieste.filter(e => e.stato === item)
          stato = item
          break
        case 'tecnico':
          data = richieste.filter(e => e.tecnico === item)
          tecnico = item
          break
        case 'urgenza':
          data = richieste.filter(e => e.urgenza === item)
          urgenza = item
          break
        default:
      }
    }
    this.setState({ data, stato, tecnico, defaultUser: tecnico, urgenza })
  }

  async changeStatus (id, stato) {
    const response = await swaller.question('Vuoi modificare lo stato della richiesta?')
    if (response) {
      swaller.info('Modifica richiesta in corso')
      let resRichiesta = ''
      if (stato === '1') {
        resRichiesta = await Api.patch(`/richieste/${id}`, {
          stato,
          accettato: true,
          dataOraAccettazione: new Date().toISOString(),
          chiusuraDataOra: ''
        })
      } else if (stato === '3') {
        resRichiesta = await Api.patch(`/richieste/${id}`, {
          stato,
          chiusuraDataOra: new Date().toISOString()
        })
      } else {
        resRichiesta = await Api.patch(`/richieste/${id}`, { stato, chiusuraDataOra: '' })
      }
      if (resRichiesta.ok) {
        swaller.success('Richiesta modificata con successo')
      }
      const { azienda } = this.state
      const richieste = await Api.get('/richieste', { azienda })
      if (richieste.ok) {
        this.setState({ richieste: richieste.data, data: richieste.data })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    }
  }

  render () {
    const { defaultUser, currentPage, itemPerPage, loading, data, tecnici, stato, urgenza, richieste, showInfoBox, content } = this.state
    const totalPages = Math.ceil(richieste.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca cliente...' onChange={(e) => this.filter(e.target.value)} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/richieste/nuovo', { state: mockupState, replace: false })}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuova Richiesta</span> </Button2>
            <ContentStyle.Select value={stato} onChange={(e) => this.filterStatus(e.target.value, 'stato')}>
              <option value='default'>Tutti gli stati</option>
              {['Nuova', 'Assegnata', 'Sospesa', 'Chiusa', 'Fatturata'].map((status, index) => (
                <option key={index} value={index.toString()}>{status}</option>
              ))}
            </ContentStyle.Select>
            <ContentStyle.Select value={defaultUser} onChange={(e) => this.filterStatus(e.target.value, 'tecnico')}>
              <option value=''>Tutti i tecnici</option>
              {tecnici && tecnici.length > 0 && tecnici.map((item, i) => {
                return (
                  <option key={i} value={item._id}>{`${item.nome} ${item.cognome}`}</option>
                )
              })}
            </ContentStyle.Select>
            <ContentStyle.Select value={urgenza} onChange={(e) => this.filterStatus(e.target.value, 'urgenza')}>
              {['default', 'bassa', 'media', 'alta'].map((value, index) => (
                <option key={index} value={value}>{value === 'default' ? 'Tutte le urgenze' : value.charAt(0).toUpperCase() + value.slice(1)}</option>
              ))}
            </ContentStyle.Select>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnRichieste.map((colonne, i) =>
                    <Tcol key={i}>
                      <b>{colonne.label}</b>
                    </Tcol>
                  )}
                </Thead>
                <Tbody>
                  {data.length > 0 && this.showData().map((richiesta, i) =>
                    <Trow key={i}>
                      <Tcol>{this.getCliente(richiesta.cliente)}</Tcol>
                      <Tcol>{this.getSettore(richiesta.settore)}</Tcol>
                      <Tcol>{this.getTecnico(richiesta.tecnico)}</Tcol>
                      <Tcol>{richiesta.urgenza}</Tcol>
                      <Tcol>{new Date(richiesta.dataOra).toLocaleString('it-IT')}</Tcol>
                      <Tcol>{new Date(richiesta.chiusuraDataOra).toLocaleString('it-IT') === 'Invalid Date' ? '-' : new Date(richiesta.chiusuraDataOra).toLocaleString('it-IT')}</Tcol>
                      <ContentStyle.Select value={richiesta.stato} onChange={(e) => this.changeStatus(richiesta._id, e.target.value)}>
                        {['Nuova', 'Assegnata', 'Sospesa', 'Chiusa', 'Fatturata'].map((status, index) => (
                          <option key={index} value={index.toString()}>{status}</option>
                        ))}
                      </ContentStyle.Select>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/richieste/modifica', { state: richiesta, replace: false })} />
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.infoBox(true, richiesta)} />
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaListOl size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/richieste/resoconti', { state: richiesta, replace: false })} />
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        {PdfDownload(PdfRichieste(this.pdfExport(richiesta)), richiesta.titolo)}
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Non ci sono richieste</i>}
          <BoxInfo showInfoBox={showInfoBox}>
            <BoxContent>
              <Toolbar>
                <ToolbarItem>
                  <CloseButton>
                    <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: false, infoValue: {} })} />
                  </CloseButton>
                </ToolbarItem>
              </Toolbar>
              <ContentStyle.Title>Informazioni:</ContentStyle.Title>
              <div style={{ width: '100%', overflow: 'auto' }}>
                <BoxRow>Cliente: {this.getCliente(content.cliente)}</BoxRow>
                <BoxRow>Settore: {this.getSettore(content.settore)}</BoxRow>
                <BoxRow>Tecnico: {this.getTecnico(content.tecnico)}</BoxRow>
                <BoxRow>Urgenza: {content.urgenza}</BoxRow>
                <BoxRow>Titolo: {content.titolo}</BoxRow>
                <BoxRow>Descrizione: {content.descrizione}</BoxRow>
              </div>
            </BoxContent>
          </BoxInfo>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
