import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, Modal, ModalIcon, ModalTitle, ModalContent, ModalLabel } from '../../Components'
import { Button } from '../../Library/Components'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import * as FaIcons from 'react-icons/fa'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'
import { mockupColonneUtenti, mockupUtenti } from './MockupUtenti'

export default class Utenti extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      users: [],
      data: [],
      infoValue: {},
      show: 'hidden'
    }
  }

  async componentDidMount () {
    const _id = LocalStorage.get('_id')
    const res = await Api.get('/users', { role: 'utente', azienda: _id })
    if (res.ok) {
      this.setState({ users: res.data, data: res.data, loading: false })
    } else {
      swaller.error('Non è stato possibile recuperare gli utenti')
      setTimeout(() => this.props.navigate('/home'), 2000)
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageUtenti = data.slice(startIndex, endIndex)
    return currentPageUtenti
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  async resetPassword (id, name, surname) {
    const response = await swaller.question(`Vuoi resettare la password di ${name} ${surname}?`)
    if (response) {
      const password = 'password'
      const res = await Api.patch(`/users/${id}`, { password, isFirstLogin: true })
      if (res.ok) {
        swaller.success('La nuova password è: "password"')
      } else {
        swaller.error('Non è stato possibile resettare la password')
      }
    }
  }

  filter (search) {
    const { data } = this.state
    search === ''
      ? this.setState({ users: data })
      : this.setState({ users: data.filter(item => item.nome.toLowerCase().includes(search.toLowerCase()) || item.cognome.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase()) || item.nazione.toLowerCase().includes(search.toLowerCase())) })
  }

  render () {
    const { currentPage, itemPerPage, loading, users, show, infoValue, data } = this.state
    const totalPages = Math.ceil(users.length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value)} />
            <Button type='primary' outline='true' onClick={() => this.props.navigate('/utenti/nuovo')}><FaIcons.FaPlusCircle />Nuovo utente</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table style={{ width: '100%' }}>
                <Thead>
                  {mockupColonneUtenti.map((colonna, i) =>
                    <Tcol key={i}>
                      <b>{colonna}</b>
                    </Tcol>
                  )}
                </Thead>
                <Tbody>
                  {this.showData().map((user, i) =>
                    <Trow key={i}>
                      <Tcol>{user.nome} {user.cognome}</Tcol>
                      <Tcol>{user.email}</Tcol>
                      <Tcol>{user.telefono}</Tcol>
                      <Tcol>{user.abilitato ? 'SI' : 'NO'}</Tcol>
                      <Tcol>
                        <FaIcons.FaEye size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ show: 'visible', infoValue: user })} />
                      </Tcol>
                      <Tcol>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/utenti/modifica', { state: user, replace: false })} />
                      </Tcol>
                      <Tcol>
                        <FaIcons.FaKey size={24} style={{ color: palette.warning, cursor: 'pointer' }} onClick={() => this.resetPassword(user._id, user.nome, user.cognome)} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessun utente trovato</i>}
        </ContentStyle.Content>
        <Modal visible={show}>
          <ModalIcon onClick={() => this.setState({ show: 'hidden' })}><FaIcons.FaTimes size={48} /></ModalIcon>
          <ModalTitle>Informazioni</ModalTitle>
          <ModalContent>
            {mockupUtenti.map((utente) =>
              <ModalLabel key={utente.key}>
                <b>{utente.label.toUpperCase()}:</b> {infoValue[utente.key]}
              </ModalLabel>
            )}
          </ModalContent>
        </Modal>
      </Loader>
    )
  }
}
