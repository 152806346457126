import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import { palette } from '../../Library/Tools/Utilities'
import { progettiEditInterno, progettiEditEsterno } from './TemplateProgetti'

export default class NuovoProgetto extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cliente: '',
      settore: '',
      progetti: [],
      clienti: [],
      settori: [],
      elencoClienti: [],
      elencoSettori: [],
      progettiEditInterno,
      progettiEditEsterno,
      tipo: '0', // 1: Esterno, 0: Interno
      titolo: ''
    }
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      const clienti = await Api.get('/users', { azienda, role: 'cliente' })
      const settori = await Api.get('/settori', { azienda })
      if (clienti.ok && settori.ok) {
        this.setState({ elencoClienti: clienti.data, elencoSettori: settori.data })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Errore durante il recupero dei dati')
    }
  }

  async salvaProgetto () {
    const { titolo, tipo, clienti, settori } = this.state
    const res = await Api.post('/progetti', {
      titolo,
      tipo,
      clienti,
      settori
    })
    if (res.ok) {
      swaller.success('Progetto salvata correttamente!')
      setTimeout(() => this.props.navigate('/progetti'), 2000)
    } else {
      swaller.error('Errore durante il savataggio del progetto, prego riprovare!')
    }
  }

  getSettori (_id) {
    const { elencoSettori } = this.state
    const foundItem = elencoSettori.find(item => item._id === _id)
    return foundItem ? foundItem.settore : '...'
  }

  getCliente (_id) {
    const { elencoClienti } = this.state
    const foundCliente = elencoClienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  aggiungiSettore (settore) {
    const { settori } = this.state
    if (settore === '') {
      swaller.info('Non si può aggiungere il settore di default', 1500)
    } else if (!settori.find(settoreItem => settoreItem === settore)) {
      this.setState({ settori: [...settori, settore] })
    }
  }

  aggiungiCliente (_id) {
    const { clienti } = this.state
    if (_id === '') {
      swaller.info('Non si può aggiungere il settore di default', 1500)
    } else if (!clienti.find(clienteItem => clienteItem === _id)) {
      this.setState({ clienti: [...clienti, _id] })
    }
  }

  rimuoviSettore (element) {
    const { settori } = this.state
    const nuoviSettori = settori.filter(e => e !== element)
    this.setState({ settori: nuoviSettori })
  }

  rimuoviCliente (element) {
    const { clienti } = this.state
    const nuoviClienti = clienti.filter(e => e !== element)
    this.setState({ clienti: nuoviClienti })
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/progetti')
    }
  }

  render () {
    const { tipo, cliente, clienti, elencoClienti, settore, settori, elencoSettori } = this.state

    return (
      <>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.salvaProgetto()}>Aggiungi Progetto</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content direction='column' center='flex-start' gap='150px'>
          <ContentStyle.Row width='auto' gap='20px'>
            <ContentStyle.Label>Tipologia progetto: </ContentStyle.Label>
            <ContentStyle.Select value={tipo} onChange={(e) => this.setState({ tipo: e.target.value })}>
              <option value='0'>Interno</option>
              <option value='1'>Esterno</option>
            </ContentStyle.Select>
          </ContentStyle.Row>
          <ContentStyle.Row center='space-around'>
            <ContentStyle.RowItem gap='20px'>
              <ContentStyle.Label>Titolo: </ContentStyle.Label>
              <ContentStyle.Text
                style={{ alignItems: 'flex-start' }}
                placeholder='titolo'
                value={this.state.titolo}
                onChange={(e) => this.setState({ titolo: e.target.value })}
              />
            </ContentStyle.RowItem>
          </ContentStyle.Row>
          {tipo === '1' &&
            <ContentStyle.Row center='space-around'>
              <ContentStyle.RowItem gap='20px'>
                <ContentStyle.Label>Clienti: </ContentStyle.Label>
                <ContentStyle.Select value={cliente} onChange={(e) => this.aggiungiCliente(e.target.value)}>
                  <option value=''>...</option>
                  {elencoClienti.map((item, index) => (
                    !clienti.find(clienteItem => clienteItem === item._id)
                      ? (
                        <option key={index} value={item._id}>
                          {item.isPrivate === '1' ? `${item.nome} ${item.cognome}` : item.intestazione}
                        </option>)
                      : null
                  ))}
                </ContentStyle.Select>
              </ContentStyle.RowItem>
              <ContentStyle.RowItem gap='20px'>
                {clienti.length > 0 && clienti.map((item, index) => (
                  <div key={index}>
                    <i>{this.getCliente(item)}</i>
                    <FaIcons.FaTrash
                      size={24}
                      style={{ color: palette.danger, cursor: 'pointer', marginLeft: '10px' }}
                      onClick={() => this.rimuoviCliente(item)}
                    />
                  </div>
                ))}
                {clienti.length === 0 && <i>Nessun cliente selezionato</i>}
              </ContentStyle.RowItem>
            </ContentStyle.Row>}
          <ContentStyle.Row center='space-around'>
            <ContentStyle.RowItem gap='20px'>
              <ContentStyle.Label>Settori: </ContentStyle.Label>
              <ContentStyle.Select value={settore} onChange={(e) => this.aggiungiSettore(e.target.value)}>
                <option value=''>...</option>
                {elencoSettori.map((item, index) => (
                  !settori.find(settoreItem => settoreItem === item._id)
                    ? <option key={index} value={item._id}>{item.settore}</option>
                    : null
                ))}
              </ContentStyle.Select>
            </ContentStyle.RowItem>
            <ContentStyle.RowItem gap='20px'>
              {settori.length > 0 &&
                <>
                  {settori.map((item, index) => (
                    <div key={index}>
                      <i>{this.getSettori(item)}</i>
                      <FaIcons.FaTrash
                        size={24}
                        style={{ color: palette.danger, cursor: 'pointer', marginLeft: '10px' }}
                        onClick={() => this.rimuoviSettore(item)}
                      />
                    </div>
                  ))}
                </>}
              {settori.length === 0 && <i>Nessun settore selezionato</i>}
            </ContentStyle.RowItem>
          </ContentStyle.Row>
        </ContentStyle.Content>
      </>
    )
  }
}
