const mockupAzienda = [
  { key: 'intestazione', label: 'Intestazione', type: 'text' },
  { key: 'pIva', label: 'Partita Iva', type: 'text' },
  { key: 'pec', label: 'Pec', type: 'text' },
  { key: 'codiceUnivoco', label: 'Codice univoco', type: 'text' },
  { key: 'email', label: 'Email', type: 'text' },
  { key: 'telefono', label: 'Numero di telefono', type: 'text' },
  { key: 'contabilita', label: 'Contabilità', type: 'text' },
  { key: 'referente', label: 'Referente', type: 'text' },
  { key: 'indirizzo', label: 'Indirizzo', type: 'text' },
  { key: 'citta', label: 'Città', type: 'text' },
  { key: 'cap', label: 'CAP', type: 'text' },
  { key: 'nazione', label: 'Nazione', type: 'text' }
]

const columnAziende = [
  {
    id: 'Intestazione',
    label: 'Intestazione'
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'telefono',
    label: 'Telefono'
  },
  {
    id: 'nazione',
    label: 'Nazione'
  },
  {
    id: 'abilitato',
    label: 'Abilitata'
  },
  {
    id: 'visualizza',
    label: 'Visualizza'
  },
  {
    id: 'modifica',
    label: 'Modifica'
  }
]

export { mockupAzienda, columnAziende }
