import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow, Modal, ModalIcon, ModalTitle, ModalContent, ModalLabel } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import swaller from '../../Library/Tools/Swaller'
import { Api } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import { mockupAzienda, columnAziende } from './MockupAzienda'

export default class Aziende extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      aziende: [],
      data: [],
      show: 'hidden',
      infoValue: {},
      abilitato: false
    }
  }

  async componentDidMount () {
    const res = await Api.get('/users', { role: 'azienda' })
    res.ok
      ? this.setState({ aziende: res.data, data: res.data, loading: false })
      : swaller.error('Non è stato possibile recuperare le aziende')
  }

  filter (search) {
    const { aziende } = this.state
    search === ''
      ? this.setState({ data: aziende })
      : this.setState({ data: aziende.filter(item => item.intestazione.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase()) || item.nazione.toLowerCase().includes(search.toLowerCase())) })
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageAziende = data.slice(startIndex, endIndex)
    return currentPageAziende
  }

  async changeStatusAzienda (id, status) {
    try {
      const question = await swaller.question('Modificare stato dell\'azienda?')
      if (question) {
        await Api.patch(`/users/${id}`, { abilitato: !status })
        swaller.success('Azienda modificata con successo')
        this.setState({
          aziende: this.state.aziende.map((azienda) => azienda._id === id
            ? { ...azienda, abilitato: !status }
            : azienda)
        })
      } else {
        swaller.info('Stato azienda non modificato')
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante la modifica dell\'azienda')
    }
  }

  render () {
    const { data, aziende, show, infoValue, itemPerPage, currentPage } = this.state
    const totalPages = Math.ceil(aziende.length / itemPerPage)

    return (
      <>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value)} />
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/aziende/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuova Azienda</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnAziende.map((colonna, i) =>
                    <Tcol key={i}><b>{colonna.label}</b></Tcol>
                  )}
                </Thead>
                <Tbody>
                  {aziende.map((azienda, i) =>
                    <Trow key={i}>
                      <Tcol>{azienda.intestazione}</Tcol>
                      <Tcol>{azienda.email}</Tcol>
                      <Tcol>{azienda.telefono}</Tcol>
                      <Tcol>{azienda.nazione}</Tcol>
                      <Tcol>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {azienda.abilitato
                            ? <Button type='success' outline='true' width='60px' onClick={() => this.changeStatusAzienda(azienda._id, azienda.abilitato)}><FaIcons.FaCheck /></Button>
                            : <Button type='danger' outline='true' width='60px' onClick={() => this.changeStatusAzienda(azienda._id, azienda.abilitato)}><FaIcons.FaTimes /></Button>}
                        </div>
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEye size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ show: 'visible', infoValue: azienda })} />
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/aziende/modifica', { state: azienda, replace: false })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Non ci sono aziende</i>}
        </ContentStyle.Content>
        <Modal visible={show}>
          <ModalIcon onClick={() => this.setState({ show: 'hidden' })}><FaIcons.FaTimes size={48} /></ModalIcon>
          <ModalTitle>Informazioni</ModalTitle>
          <ModalContent>
            {mockupAzienda.map((azienda) =>
              <ModalLabel key={azienda.key}>
                <b>{azienda.label.toUpperCase()}:</b> {infoValue[azienda.key]}
              </ModalLabel>
            )}
          </ModalContent>
        </Modal>
      </>
    )
  }
}
