import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Tcol, Tbody, Trow, Table, Thead, BoxInfo, BoxContent, BoxRow } from '../../Components'
import { Button, CloseButton } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import Loader from '../../Library/Tools/Loader'
import { columnAggiuntaCarrello, columnAggiuntaServizi, columnResoconto, columnResocontoCarrello } from './MockupResoconti'
import { palette } from '../../Library/Tools/Utilities'
import { BoxAggiuntaMateriale } from '../../Components/ModalInfo'

export default class ModificaResoconto extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      loading: true,
      _id: '',
      referenza: '',
      numero: 0,
      titolo: '',
      cliente: '',
      settore: '',
      tecnico: '',
      tecniciSelezionati: [],
      carrelloMateriale: [],
      carrelloServizi: [],
      azienda: '',
      tecnici: [],
      dataInizio: '',
      dataFine: '',
      descrizione: '',
      stato: '',
      servizi: [],
      articoli: [],
      showBoxAggiuntaMateriale: false,
      showBoxAggiuntaServizi: false,
      showInfoBox: false,
      infoValue: {},
      tipo: '',
      fornitori: [],
      produttori: [],
      settori: [],
      dropdownOpen: false
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const { ...data } = this.props.location.state
    const articoli = await Api.get('/articoli', { azienda })
    const servizi = await Api.get('/servizi', { azienda })
    const produttori = await Api.get('/produttori', { azienda })
    const settori = await Api.get('/settori', { azienda })
    const fornitori = await Api.get('/fornitori', { azienda })
    const tecnici = await Api.get('/users', { azienda, role: 'utente' })
    produttori.ok && settori.ok && fornitori.ok && articoli.ok && tecnici.ok && servizi.ok
      ? this.setState({ data, articoli: articoli.data, servizi: servizi.data, articoliData: articoli.data, produttori: produttori.data, settori: settori.data, fornitori: fornitori.data, tecnici: tecnici.data, loading: false })
      : swaller.error('Non è stato possibile recuperare i dati')
  }

  async modificaResoconto () {
    try {
      const { _id } = this.state.data
      const { numero, titolo, tipo, cliente, tecniciSelezionati, dataInizio, dataFine, descrizione, stato, carrelloMateriale, carrelloServizi } = this.state
      const resResoconto = await Api.patch(`/resoconti/${_id}`, {
        titolo: tipo === 'Preventivo' ? numero : titolo,
        tipo,
        cliente,
        tecnico: tecniciSelezionati,
        stato,
        dataInizio,
        dataFine,
        descrizione,
        carrelloMateriale,
        carrelloServizi,
        modificatoDa: LocalStorage.get('user')._id,
        oraModifica: new Date().toISOString()
      })
      if (resResoconto.ok) {
        swaller.success('Resoconto modificato correttamente!')
        setTimeout(() => this.props.navigate('/resoconti'), 2000)
      } else {
        swaller.error('Errore durante la modifica del resoconto, prego riprovare!')
      }
    } catch (error) {
      swaller.error('Errore durante la modifica del resoconto. Riprovare')
    }
  }

  getTecnico (_id) {
    const { tecnici } = this.state
    const tecnico = tecnici.find(tecnico => tecnico._id === _id)
    return tecnico ? `${tecnico.nome} ${tecnico.cognome}` : ''
  }

  toggleTecnicoSelezionato (tecnicoId) {
    const { tecniciSelezionati } = this.state
    const index = tecniciSelezionati.indexOf(tecnicoId)
    if (index === -1) {
      this.setState({ tecniciSelezionati: [...tecniciSelezionati, tecnicoId] })
    } else {
      const updatedTecniciSelezionati = [...tecniciSelezionati]
      updatedTecniciSelezionati.splice(index, 1)
      this.setState({ tecniciSelezionati: updatedTecniciSelezionati })
    }
  }

  getProduttore (_id) {
    const { produttori } = this.state
    const foundProduttore = produttori.find(produttore => _id === produttore._id)
    return foundProduttore ? `${foundProduttore.produttore}` : ''
  }

  getSettore (_id) {
    const { settori } = this.state
    const foundSettore = settori.find(settore => _id === settore._id)
    return foundSettore ? foundSettore.settore : ''
  }

  getFornitore (_id) {
    const { fornitori } = this.state
    const foundFornitore = fornitori.find(fornitore => _id === fornitore._id)
    return foundFornitore ? `${foundFornitore.fornitore}` : ''
  }

  addToCart (item, cartKey) {
    const { [cartKey]: cart, carrelloMateriale } = this.state
    if (cart.some((cartItem) => cartItem._id === item._id)) {
      swaller.warning('Articolo già aggiunto')
    } else {
      if (carrelloMateriale.some((materiale) => materiale._id === item._id)) {
        swaller.warning('Articolo già presente nella tabella del materiale extra')
      } else {
        item.qta = 0
        this.setState({ [cartKey]: [...cart, item] })
      }
    }
  }

  async removeFromCart (index, cartKey) {
    const { [cartKey]: cart } = this.state
    const res = await swaller.question('Sicuro di voler eliminare ?')
    if (res) {
      cart.splice(index, 1)
      this.setState({ [cartKey]: [...cart] })
    }
  }

  handleQuantity (index, qta, tipo) {
    const { carrelloMateriale, carrelloServizi } = this.state
    let carrello = ''
    if (tipo === 'materiale') {
      carrello = carrelloMateriale
    } else {
      carrello = carrelloServizi
    }
    carrello[index].qta = qta > 0 ? Number(qta) : 0
    this.setState({ [tipo === 'materiale' ? 'carrelloMateriale' : 'carrelloServizi']: carrello })
  }

  format (value) {
    return value.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/resoconti')
    }
  }

  render () {
    const { loading, descrizione, tecnici, tecniciSelezionati, showInfoBox, infoValue, articoli, carrelloMateriale, carrelloServizi, servizi, showBoxAggiuntaMateriale, showBoxAggiuntaServizi, tipo, dataInizio, dataFine, dropdownOpen } = this.state

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' gap='10px'>
            <Button type='success' outline='true' onClick={async () => await this.modificaResoconto()}><FaIcons.FaCheckCircle />Aggiorna resoconto</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content overflow='scroll' border='none' boxshadow='none' background='transparent'>
          <ContentStyle.InputAllPage>
            <ContentStyle.Title>Resoconto: </ContentStyle.Title>
            <ContentStyle.Row>
              <ContentStyle.Box>
                <ContentStyle.Label>Tecnici Selezionati: {tecniciSelezionati.length}</ContentStyle.Label>
                <ContentStyle.Position>
                  <ContentStyle.SelectionBox onClick={() => this.setState({ dropdownOpen: !dropdownOpen })}>Seleziona Tecnici</ContentStyle.SelectionBox>
                  {dropdownOpen && (
                    <ContentStyle.SelectionList>
                      {tecnici.map((item, i) => (
                        <div key={i} style={{ padding: '8px', cursor: 'pointer' }} onClick={() => this.toggleTecnicoSelezionato(item._id)}>
                          <input type='checkbox' checked={tecniciSelezionati.includes(item._id)} />
                          {`${item.nome} ${item.cognome}`}
                        </div>
                      ))}
                    </ContentStyle.SelectionList>
                  )}
                </ContentStyle.Position>
                <ContentStyle.Label>Data inizio attività: </ContentStyle.Label>
                <input type='datetime-local' value={dataInizio} onChange={e => this.setState({ dataInizio: e.target.value })} />
                <ContentStyle.Label>Data fine attività: </ContentStyle.Label>
                <input type='datetime-local' value={dataFine} onChange={e => this.setState({ dataFine: e.target.value })} />
              </ContentStyle.Box>
            </ContentStyle.Row>
            <hr />
            <ContentStyle.Row>
              <ContentStyle.Box style={{ width: '100%' }}>
                <ContentStyle.Label>Descrizione: </ContentStyle.Label>
                <ContentStyle.Textarea placeholder='descrizione' value={descrizione} onChange={(e) => this.setState({ descrizione: e.target.value })} />
              </ContentStyle.Box>
            </ContentStyle.Row>
            <hr />
            <ContentStyle.Row>
              <ContentStyle.Box>
                <ContentStyle.Label>Materiale extra: </ContentStyle.Label>
                <Button type='info' outline='true' onClick={() => this.setState({ showBoxAggiuntaMateriale: true })}><FaIcons.FaPlusCircle />Aggiungi materiale</Button>
              </ContentStyle.Box>
            </ContentStyle.Row>
            <ContentStyle.Table>
              {carrelloMateriale.length > 0 &&
                <Table>
                  <Thead>
                    <Trow>
                      {columnResocontoCarrello.map((colonne, i) =>
                        <Tcol key={i}>
                          <b>{colonne.label}</b>
                        </Tcol>
                      )}
                    </Trow>
                  </Thead>
                  <Tbody>
                    {carrelloMateriale.map((item, i) =>
                      <Trow key={i}>
                        <Tcol>{this.getSettore(item.settore)}</Tcol>
                        <Tcol>{item.referenza}</Tcol>
                        <Tcol>{tipo === 'Preventivo' ? `${item.numero}` : `${item.titolo}`}</Tcol>
                        <Tcol>{this.getFornitore(item.fornitore)}</Tcol>
                        <Tcol>{this.getProduttore(item.produttore)}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <input style={{ width: 50 }} type='number' value={item.qta} onChange={(e) => this.handleQuantity(i, e.target.value, 'materiale')} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: item })} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.removeFromCart(i, 'carrelloMateriale')} />
                        </Tcol>
                      </Trow>
                    )}
                  </Tbody>
                </Table>}
              {carrelloMateriale.length === 0 && <i style={{ margin: 'auto' }}>Nessun prodotto aggiunto</i>}
            </ContentStyle.Table>
            <hr />
            <ContentStyle.Row>
              <ContentStyle.Box>
                <ContentStyle.Label>Servizi extra: </ContentStyle.Label>
                {servizi.length > 0 && <Button type='info' outline='true' onClick={() => this.setState({ showBoxAggiuntaServizi: true })}><FaIcons.FaPlusCircle />Aggiungi servizi</Button>}
              </ContentStyle.Box>
            </ContentStyle.Row>
            <ContentStyle.Table>
              {carrelloServizi.length > 0 &&
                <Table>
                  <Thead>
                    <Trow>
                      {columnResoconto.map((colonne, i) =>
                        <Tcol key={i}>
                          <b>{colonne.label}</b>
                        </Tcol>
                      )}
                    </Trow>
                  </Thead>
                  <Tbody>
                    {carrelloServizi.map((item, i) =>
                      <Trow key={i}>
                        <Tcol>{item.titolo}</Tcol>
                        <Tcol>{item.descrizione}</Tcol>
                        <Tcol>{this.format(item.costoOrario)}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <input style={{ width: 50 }} type='number' value={item.qta} onChange={(e) => this.handleQuantity(i, e.target.value, 'servizi')} />
                        </Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaTrash size={24} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.removeFromCart(i, 'carrelloServizi')} />
                        </Tcol>
                      </Trow>
                    )}
                  </Tbody>
                </Table>}
              {carrelloServizi.length === 0 && <i style={{ margin: 'auto' }}>Nessun servizio aggiunto</i>}
            </ContentStyle.Table>
          </ContentStyle.InputAllPage>
          <BoxInfo showInfoBox={showInfoBox}>
            <BoxContent>
              <Toolbar>
                <CloseButton>
                  <FaIcons.FaWindowClose size={36} style={{ color: palette.danger, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: false, infoValue: {} })} />
                </CloseButton>
              </Toolbar>
              <ContentStyle.Title>Descrizione:</ContentStyle.Title>
              <hr />
              <BoxRow>Referenza: {infoValue.referenza}</BoxRow>
              <BoxRow>Titolo: {infoValue.titolo}</BoxRow>
              <BoxRow>Quantità stock: {infoValue.qtaStock}</BoxRow>
              <BoxRow>Quantità minima stock: {infoValue.qtaMinStock}</BoxRow>
              <ContentStyle.Row style={{ display: 'flex', alignItems: 'center', gap: 10 }}>Descrizione: <ContentStyle.Textarea disabled value={infoValue.descrizione} /></ContentStyle.Row>
              <BoxRow>Immagine: {infoValue.immagine}</BoxRow>
              <BoxRow>Ultima modifica: {new Date(infoValue.dataOra).toLocaleString('it-IT')}</BoxRow>
            </BoxContent>
          </BoxInfo>
          <BoxAggiuntaMateriale show={showBoxAggiuntaMateriale}>
            <Toolbar paddingleft='30px' paddingright='30px'>
              <ToolbarItem>
                <h3>Selezionare gli articoli</h3>
                <ContentStyle.Search type='text' style={{ marginLeft: 10 }} placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'referenza')} />
              </ToolbarItem>
              <ToolbarItem isend='true'>
                <FaIcons.FaCheckSquare size={36} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.setState({ showBoxAggiuntaMateriale: false, search: '' })} />
              </ToolbarItem>
            </Toolbar>
            <br />
            <ContentStyle.Table>
              <Table>
                <Thead>
                  <Trow>
                    {columnAggiuntaCarrello.map((colonne, i) =>
                      <Tcol key={i}>
                        <b>{colonne.label}</b>
                      </Tcol>
                    )}
                  </Trow>
                </Thead>
                <Tbody>
                  {articoli.map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{item.referenza}</Tcol>
                      <Tcol>{item.titolo}</Tcol>
                      <Tcol>{this.getFornitore(item.fornitore)}</Tcol>
                      <Tcol>{this.getProduttore(item.produttore)}</Tcol>
                      <Tcol>{this.getSettore(item.settore)}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        {carrelloMateriale.filter((e) => e._id === item._id).length > 0
                          ? <FaIcons.FaCheckCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addToCart(item, 'carrelloMateriale')} />
                          : <FaIcons.FaPlusCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addToCart(item, 'carrelloMateriale')} />}
                      </Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaInfo size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.setState({ showInfoBox: true, infoValue: item })} />
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
            </ContentStyle.Table>
          </BoxAggiuntaMateriale>
          <BoxAggiuntaMateriale show={showBoxAggiuntaServizi}>
            <Toolbar paddingleft='30px' paddingright='30px'>
              <ToolbarItem>
                <h3>Selezionare gli articoli</h3>
                <ContentStyle.Search type='text' style={{ marginLeft: 10 }} placeholder='🔍 Ricerca...' onChange={(e) => this.filter(e.target.value, 'referenza')} />
              </ToolbarItem>
              <ToolbarItem isend='true'>
                <FaIcons.FaCheckSquare size={36} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.setState({ showBoxAggiuntaServizi: false, search: '' })} />
              </ToolbarItem>
            </Toolbar>
            <br />
            <ContentStyle.Table>
              <Table>
                <Thead>
                  <Trow>
                    {columnAggiuntaServizi.map((colonne, i) =>
                      <Tcol key={i}>
                        <b>{colonne.label}</b>
                      </Tcol>
                    )}
                  </Trow>
                </Thead>
                <Tbody>
                  {servizi.map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{item.titolo}</Tcol>
                      <Tcol>{item.descrizione}</Tcol>
                      <Tcol>{this.format(item.costoOrario)}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        {carrelloServizi.filter((e) => e._id === item._id).length > 0
                          ? <FaIcons.FaCheckCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addToCart(item, 'carrelloServizi')} />
                          : <FaIcons.FaPlusCircle size={24} style={{ color: palette.success, cursor: 'pointer' }} onClick={() => this.addToCart(item, 'carrelloServizi')} />}
                      </Tcol>
                    </Trow>
                  )}
                </Tbody>
              </Table>
            </ContentStyle.Table>
          </BoxAggiuntaMateriale>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
