import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button, Button2, IconWrapper } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { Api, LocalStorage } from '../../Services'
import swaller from '../../Library/Tools/Swaller'
import { palette } from '../../Library/Tools/Utilities'
import { columnProgetti, progettiEditInterno, progettiEditEsterno } from './TemplateProgetti'

export default class Progetti extends Component {
  constructor (props) {
    super(props)
    this.state = {
      itemPerPage: 6,
      currentPage: 0,
      cliente: '',
      settore: '',
      resoconto: '',
      data: [],
      progetti: [],
      clienti: [],
      settori: [],
      elencoClienti: [],
      progettiEditInterno,
      progettiEditEsterno,
      tipo: '0', // 1: Esterno, 0: Interno
      titolo: ''
    }
  }

  async componentDidMount () {
    try {
      const { azienda } = LocalStorage.get('user')
      const clienti = await Api.get('/users', { azienda, role: 'cliente' })
      const settori = await Api.get('/settori', { azienda })
      const progetti = await Api.get('/progetti')
      const resoconti = await Api.get('/resoconti')
      if (clienti.ok && settori.ok && progetti.ok) {
        this.setState({ clienti: clienti.data, settori: settori.data, progetti: progetti.data, data: progetti.data, resoconti: resoconti.data })
      } else {
        swaller.error('Non è stato possibile recuperare i dati')
      }
    } catch (error) {
      swaller.error('Errore durante il recupero dei dati')
    }
  }

  showData () {
    const { data, itemPerPage, currentPage } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    const currentPageProgetti = data.slice(startIndex, endIndex)
    return currentPageProgetti
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  getCliente (_ids) {
    const { clienti } = this.state
    const elencoClientiTrovati = []
    _ids.forEach(_id => {
      const foundCliente = clienti.find(cliente => _id === cliente._id)
      if (foundCliente) {
        foundCliente.isPrivate === '0'
          ? elencoClientiTrovati.push(foundCliente.intestazione)
          : elencoClientiTrovati.push(`${foundCliente.nome} ${foundCliente.cognome}`)
      }
    })
    return elencoClientiTrovati.join(' / ')
  }

  getSettore (_ids) {
    const { settori } = this.state
    const elencoSettoriTrovati = []
    _ids.forEach(_id => {
      const foundSettore = settori.find(settore => _id === settore._id)
      if (foundSettore) {
        elencoSettoriTrovati.push(foundSettore.settore)
      }
    })
    return elencoSettoriTrovati.join(' / ')
  }

  render () {
    const { progetti, data, itemPerPage, currentPage } = this.state
    const totalPages = Math.ceil(progetti.length / itemPerPage)

    return (
      <>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button2 type='primary' outline='true' onClick={() => this.props.navigate('/progetti/nuovo')}><IconWrapper><FaIcons.FaPlusCircle /></IconWrapper><span>Nuovo progetto</span> </Button2>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnProgetti.map((colonne) =>
                    <Tcol key={colonne.id}>
                      <b>{colonne.label}</b>
                    </Tcol>
                  )}
                </Thead>
                <Tbody>
                  {this.showData().map((progetto) => {
                    return (
                      <Trow key={progetto._id}>
                        <Tcol>{progetto.titolo}</Tcol>
                        <Tcol>{progetto.tipo}</Tcol>
                        <Tcol>{progetto.clienti ? this.getCliente(progetto.clienti) : 'Nessun cliente presente'}</Tcol>
                        <Tcol>{progetto.settori.length > 0 ? this.getSettore(progetto.settori) : 'Nessun settore presente'}</Tcol>
                        <Tcol style={{ textAlign: 'center' }}>
                          <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/progetti/modifica', { state: progetto, replace: false })} />
                        </Tcol>
                      </Trow>
                    )
                  })}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button minwidth='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button minwidth='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Non ci sono progetti disponibili</i>}
        </ContentStyle.Content>
      </>
    )
  }
}
