import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb } from '../../Components'
import * as FaIcons from 'react-icons/fa'
import { Button } from '../../Library/Components'
import swaller from '../../Library/Tools/Swaller'
import { Api } from '../../Services'
import Loader from '../../Library/Tools/Loader'

export default class ModificaCanoni extends Component {
  constructor (props) {
    super(props)
    this.state = {
      azienda: '',
      loading: true,
      clienti: [],
      cliente: '',
      importo: 0,
      descrizione: '',
      scadenza: '',
      canone: ''
    }
  }

  async componentDidMount () {
    try {
      const { ...dati } = this.props.location.state
      const { azienda } = dati
      const clienti = await Api.get('/users', { azienda, role: 'cliente' })
      clienti.ok
        ? this.setState({ azienda, clienti: clienti.data, loading: false, ...this.props.location.state })
        : swaller.error('Non è stato possibile recuperare i dati. Riprovare più tardi')
    } catch (error) {
      swaller.error('Non è stato possibile recuperare i dati. Riprovare più tardi')
    }
  }

  async modificaCanone () {
    const { loading, clienti, ...stato } = this.state
    const { _id } = stato
    try {
      swaller.info('Modifica in corso...')
      const res = await Api.patch(`/canoni/${_id}`, stato)
      if (res.ok) {
        swaller.success('Canone salvato correttamente!')
        setTimeout(() => this.props.navigate('/canoni'), 2000)
      } else {
        swaller.error('Errore durante la creazione del canone, prego riprovare!')
      }
    } catch (error) {
      swaller.error('Si è verificato un errore durante il salvataggio del canone.')
    }
  }

  async backPage () {
    const res = await swaller.question('Sicuro di voler uscire?')
    if (res) {
      this.props.navigate('/canoni')
    }
  }

  render () {
    const { loading, clienti, importo, scadenza, descrizione } = this.state
    const currentDate = new Date().toISOString().split('T')[0]

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.backPage()}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true'>
            <Button type='success' outline='true' onClick={() => this.modificaCanone()}><FaIcons.FaPlus />Modifica Canone</Button>
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content direction='column'>
          <ContentStyle.Title>Fornire le indicazioni seguenti: </ContentStyle.Title>
          <ContentStyle.Form height='60vh'>
            <ContentStyle.Row center='center'>
              <ContentStyle.Label>Clienti: </ContentStyle.Label>
              <ContentStyle.Select onChange={(e) => this.setState({ cliente: e.target.value })}>
                {clienti.length > 0 && clienti.map((cliente) => (
                  <option value={cliente._id} key={cliente._id}>
                    {cliente.isPrivate === '0' ? cliente.intestazione : `${cliente.nome} ${cliente.cognome}`}
                  </option>
                ))}
              </ContentStyle.Select>
            </ContentStyle.Row>
            <ContentStyle.Row center='center'>
              <ContentStyle.Label>Canone: </ContentStyle.Label>
              <ContentStyle.Select onChange={(e) => this.setState({ canone: e.target.value })}>
                <option value=''>Canone</option>
                <option value='mensile'>Mensile</option>
                <option value='annuale'>Annuale</option>
              </ContentStyle.Select>
            </ContentStyle.Row>
            <ContentStyle.Row center='center' gap='20px'>
              <ContentStyle.Label>Importo: </ContentStyle.Label>
              <ContentStyle.Text type='number' placeholder='Importo in €' min='0' value={importo} onChange={(e) => this.setState({ importo: e.target.value })} />
            </ContentStyle.Row>
            <ContentStyle.Row center='center' gap='20px'>
              <ContentStyle.Label>Scadenza: </ContentStyle.Label>
              <ContentStyle.Text type='date' value={scadenza} min={currentDate} onChange={(e) => this.setState({ scadenza: e.target.value })} />
            </ContentStyle.Row>
            <ContentStyle.Row center='center' gap='20px'>
              <ContentStyle.Label>Descrizione: </ContentStyle.Label>
              <ContentStyle.Textarea width='80%' value={descrizione} onChange={(e) => this.setState({ descrizione: e.target.value })} />
            </ContentStyle.Row>
          </ContentStyle.Form>
        </ContentStyle.Content>
      </Loader>
    )
  }
}
