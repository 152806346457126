import React, { Component } from 'react'
import { Toolbar, ToolbarItem, ContentStyle, Breadcrumb, Table, Thead, Tbody, Tcol, Trow } from '../../Components'
import { Button } from '../../Library/Components'
import * as FaIcons from 'react-icons/fa'
import { columnScatole } from '../TitoliColonne'
import swaller from '../../Library/Tools/Swaller'
import { Api, LocalStorage } from '../../Services'
import { palette } from '../../Library/Tools/Utilities'
import Loader from '../../Library/Tools/Loader'

export default class Scatole extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      itemPerPage: 6,
      loading: true,
      scatole: [],
      data: [],
      azienda: '',
      clienti: [],
      tecnici: [],
      ricerca: ''
    }
  }

  async componentDidMount () {
    const { azienda } = LocalStorage.get('user')
    const clienti = await Api.get('/users', { azienda, role: 'cliente' })
    const tecnici = await Api.get('/users', { azienda, role: 'utente' })
    const scatole = await Api.get('/scatole', { azienda })
    if (clienti.ok && tecnici.ok && scatole.ok) {
      this.setState({ azienda, tecnici: tecnici.data, clienti: clienti.data, scatole: scatole.data, data: scatole.data, loading: false })
    } else {
      swaller.error('Non è stato possibile recuperare i dati')
    }
  }

  showData () {
    const { data, itemPerPage, currentPage, ricerca } = this.state
    const startIndex = currentPage * itemPerPage
    const endIndex = startIndex + itemPerPage
    let currentPageScatole
    ricerca === ''
      ? currentPageScatole = data.slice(startIndex, endIndex)
      : currentPageScatole = data.filter(item => item.nScatola.toString() === ricerca).slice(startIndex, endIndex)
    return currentPageScatole
  }

  showNextPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  showPrevPage () {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  getTecnico (_id) {
    const { tecnici } = this.state
    const tecnico = tecnici.find(tecnico => tecnico._id === _id)
    return tecnico ? `${tecnico.nome} ${tecnico.cognome}` : ''
  }

  getCliente (_id) {
    const { clienti } = this.state
    const foundCliente = clienti.find(cliente => _id === cliente._id)
    return foundCliente ? (foundCliente.isPrivate === '0' ? foundCliente.intestazione : `${foundCliente.nome} ${foundCliente.cognome}`) : ''
  }

  searchName (text) {
    const { scatole } = this.state
    this.setState({ ricerca: text, currentPage: 0 })
    text === ''
      ? this.setState({ data: scatole })
      : this.setState({ data: scatole.filter((scatola) => scatola.nScatola.toString().includes(text)) })
  }

  render () {
    const { currentPage, itemPerPage, loading, scatole, data, ricerca } = this.state

    let totalPages
    ricerca === ''
      ? totalPages = Math.ceil(scatole.length / itemPerPage)
      : totalPages = Math.ceil(this.showData().length / itemPerPage)

    return (
      <Loader loading={loading}>
        <Toolbar>
          <ToolbarItem>
            <Breadcrumb.Container>
              <Breadcrumb.Crumb>
                <Button type='primary' outline='true' onClick={() => this.props.navigate('/home')}><FaIcons.FaArrowCircleLeft /> Back</Button>
              </Breadcrumb.Crumb>
            </Breadcrumb.Container>
          </ToolbarItem>
          <ToolbarItem isend='true' style={{ width: '100%' }}>
            <ContentStyle.Search type='text' placeholder='🔍 Ricerca...' onChange={(e) => this.searchName(e.target.value)} />
          </ToolbarItem>
        </Toolbar>
        <ContentStyle.Content>
          {this.showData().length > 0 &&
            <ContentStyle.Table>
              <Table>
                <Thead>
                  {columnScatole.map((colonne, i) =>
                    <Tcol key={i}><b>{colonne.label}</b></Tcol>)}
                </Thead>
                <Tbody>
                  {scatole && scatole.length > 0 && this.showData().map((item, i) =>
                    <Trow key={i}>
                      <Tcol>{item.nScatola}</Tcol>
                      <Tcol>{item.cliente !== '' ? this.getCliente(item.cliente) : this.getTecnico(item.tecnico)}</Tcol>
                      <Tcol style={{ textAlign: 'center' }}>
                        <FaIcons.FaEdit size={24} style={{ color: palette.info, cursor: 'pointer' }} onClick={() => this.props.navigate('/scatole/modifica', { state: item, replace: false })} />
                      </Tcol>
                    </Trow>)}
                </Tbody>
              </Table>
              <ContentStyle.PaginationContainer>
                <Button width='60px' disabled={currentPage === 0} onClick={() => this.showPrevPage()}><FaIcons.FaArrowLeft /></Button>
                <ContentStyle.PageNumberContainer>{currentPage + 1}</ContentStyle.PageNumberContainer>
                <Button width='60px' disabled={currentPage === totalPages - 1 || totalPages - 1 < 0} onClick={() => this.showNextPage()}><FaIcons.FaArrowRight /></Button>
              </ContentStyle.PaginationContainer>
            </ContentStyle.Table>}
          {data.length === 0 && <i>Nessuna scatola presente</i>}
        </ContentStyle.Content>
      </Loader>
    )
  }
}
