import styled from 'styled-components'
import { palette, light, dark } from '../Tools/Utilities'

/**
 * @author Tecnel MC
 * @name Button
 * @description API for button
 * @example
 * <Button type='primary' outline>Button</Button>
*/

export const Button = styled.button`
  min-width: ${({ width }) => width || '180px'};
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  border-radius: 15px;
  padding: 10px;
  background: ${({ type, outline }) => outline ? 'transparent' : palette[type]};
  border: ${({ type }) => `1px solid ${palette[type]}`};
  color: ${({ type, outline }) => outline ? palette[type] : type === 'warning' ? dark : 'black'};
  font-size: ${({ fontSize }) => fontSize || '24px'};
  box-shadow: -50px -50px 50px 0 white, 50px 50px 80px 0 #AEAEC0;
  box-shadow: inset 0 -10px 10px 0 rgba(174, 174, 192, 0.25), inset 0 10px 10px 0 #fff;
  position: relative;

  &:hover {
    cursor: pointer;
    transform: scale(1.07);
    background: ${({ type, outline }) => outline && palette[type]};
    color: ${({ type, outline }) => outline && (type === 'warning' ? dark : light)};
    box-shadow: -50px -50px 50px 0 white, 50px 50px 80px 0 #AEAEC0;
    box-shadow: ${({ type, outline }) => `inset 0 -10px 10px 0 ${palette[type]}`};
  }

  &:disabled {
    visibility: hidden;
  }
`

export const Button2 = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  align-items: center;
  background: ${({ type, outline }) => outline ? 'transparent' : palette[type]};
  border: ${({ type }) => `1px solid ${palette[type]}`};
  color: ${({ type, outline }) => outline ? palette[type] : type === 'warning' ? dark : 'black'};
  font-size: ${({ fontSize }) => fontSize || '24px'};
  box-shadow: -50px -50px 50px 0 white, 50px 50px 80px 0 #AEAEC0;
  box-shadow: inset 0 -10px 10px 0 rgba(174, 174, 192, 0.25), inset 0 10px 10px 0 #fff;
  position: relative;
  padding-left: 15px;
  border-radius: 15px;
  transition: all 0.3s ease;

  &:hover {
    width: ${({ width }) => width || '250px'};
    padding: 10px 20px;
    cursor: pointer;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    transition: opacity 0.3s ease;
    opacity: 0;
  }

  &:hover span {
    opacity: 1;
  }
`
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 24px;
  height: 24px;
`

export const CloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 1.5rem;
  z-index: 1;
  color: #1E1E1E;
  cursor: pointer;
`
